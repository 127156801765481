import { CalculatorOutlined, InfoCircleOutlined, SettingOutlined } from '@ant-design/icons'
import { Alert, Button, Checkbox, Divider, Form, Input, InputNumber, Popover, Select, Switch, Tabs, Typography, notification } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Description, calculateBeslutsstod, editBeslutsstod, getMunicipalityData, loadBeslutsstodById, saveBeslutsstod, useDownloadPdf } from '../../api/beslutsstod'
import { useMe } from '../../api/permissions'
import { useApi } from '../../api/useApi'
import FlagListHeader from '../../components/header'
import { getmunicipality, setMunicipality } from '../../store/municipalitySlice'
import EditableCalculationSettings from './components/editableCalculationSettings'
import FormSettings from './components/editableFormSettings'
import EditableOther from './components/editableOther'
import EditableTimepoints from './components/editableTimepoints'
import TimelineTooltip from './components/timelineTooltips'
import EditTimepointsTasks from './editTimepoint'
import Other from './other'
import SearchBeslutsstod from './searchBeslutsstod'
import ShowResults from './showResults'
import TimepointsTasks from './timepointsTasks'
import jsonFormatter from './utils/jsonFormatter'
import jsonLoader, { JSONLoadError } from './utils/jsonLoader'
import { validatePersonnummer } from './utils/validatePersonnummer'
import { MunicipalityData } from './types/municipalityData'
const api = useApi()

type Props = {}

interface Data {
  totalExDouble: number
  hoursPerWeekPerTimepoint: Record<string, unknown>
  hoursPerWeekTimepointTasks: number
  hoursPerMonthTimepointTasks: number
  hoursPerDayTimepointTasks: number
  hoursPerMonthExtraTasks: number
  hoursPerMonthTimePointsTasksDouble: number
  totalHoursPerMonth: number
  [key: string]: number | Record<string, unknown>
}

const { TabPane } = Tabs

const Beslutsstod: React.FC<Props> = (props) => {
  const { data: me } = useMe()

  const { id } = useParams<{ id?: string }>()
  const { mutateAsync: downloadPdf, isLoading: isPdfLoading } = useDownloadPdf()

  const [form] = Form.useForm()
  const [tab, setTab] = useState('1')
  const [editMode, setEditMode] = useState(false)
  const [editDescription, setEditDescription] = useState('')
  const [result, setResult] = useState<any>(null)
  const [loadedResult, setLoadedResult] = useState<any>(null)
  const [checkedTimepoints, setCheckedTimepoints] = useState<string[]>([])
  const [pnr, setPnr] = useState('')
  const [pid, setPid] = useState('')
  const [note, setNote] = useState('')
  const [draft, setDraft] = useState(false)
  const [weeksOff, setWeeksOff] = useState(0)
  // Note for loaded evaluation
  const [loadedNote, setLoadedNote] = useState('')
  const [descriptions, setDescriptions] = useState<Description[]>([])
  const [loadingErrors, setLoadingErrors] = useState<JSONLoadError[] | null>(null)
  const [submittingErrors, setSubmittingErrors] = useState<SubmitError[] | null>(null)
  const dispatch = useDispatch()
  const municipalityData = useSelector(getmunicipality)

  const [notificationInstance, contextHolder] = notification.useNotification()

  const onChange = (value: CheckboxValueType[]) => {
    const sorted = (value as string[]).sort((a, b) => {
      return (municipalityData?.input.timepoints.indexOf(a) ?? 0) - (municipalityData?.input.timepoints.indexOf(b) ?? 0)
    })

    setCheckedTimepoints(sorted)
  }

  const timepointOptions = (timepoints: string[]) => {
    return timepoints.map((timepoint: string) => {
      return { label: timepoint, value: timepoint }
    })
  }

  interface SubmitError {
    message: ReactNode
    reason: 'frequency' | 'level'
  }
  const getErroneousTasks = (values: FormValues, municipalityData: MunicipalityData) => {
    const getError = ({ timepoint, taskName, frequency, level }: { timepoint?: string; taskName: string; frequency?: number; level?: number }, municipalityData: MunicipalityData) => {
      if (frequency !== undefined && level !== undefined) return null
      if (frequency === undefined && level === undefined) return null
      const timepointString = timepoint ? (
        <>
          <b>{timepoint} -</b>
        </>
      ) : (
        ''
      )

      if (frequency === undefined) {
        // There's a special case where it's ok to omit frequency. When there's a matching standardTime and no existing frequencies
        if (
          level &&
          municipalityData.input.extraTasks.find((task) => task.name === taskName)?.standardTimes[level] !== undefined &&
          municipalityData.input.extraTasks.find((task) => task.name === taskName)?.frequencies?.length === 0
        ) {
          return null
        }

        return {
          message: (
            <>
              Frekvens för insatsen {timepointString} <b>{taskName}</b> saknas
            </>
          ),
          reason: 'frequency',
        } as const
      }
      if (level === undefined)
        return {
          message: (
            <>
              Nivå för insatsen {timepointString} <b>{taskName}</b> saknas
            </>
          ),
          reason: 'level',
        } as const

      return null
    }

    const timepointEntries = values ? (Object.entries(values).filter(([key, value]) => ['shared', 'save', 'WeeksOff', 'other'].indexOf(key) === -1) as [string, any][]) : []

    const timepointTaskEntries = timepointEntries.flatMap(([timepoint, tasks]) => {
      return tasks.timepoints
        ? Object.entries(tasks.timepoints).map(([taskName, task]) => {
            return { timepoint, taskName, frequency: (task as any)?.Besök, level: (task as any)?.Nivå }
          })
        : []
    })

    const otherEntries = values.other
      ? (Object.entries(values.other) as [string, any][]).map(([taskName, task]) => {
          return { taskName, frequency: (task as any)?.Frekvens, level: (task as any)?.Nivå }
        })
      : []

    const errors = [...timepointTaskEntries, ...otherEntries].map((entry) => getError(entry, municipalityData)).filter((error) => error !== null)
    // TS doesn't know that the filter above removes nulls
    return errors as NonNullable<(typeof errors)[number]>[]
  }

  interface FormValues {
    shared?: undefined | boolean
    WeeksOff?: undefined | number
    // Should not exist in the form values
    save: undefined
    other: Record<string, { Frekvens: number; Nivå: number }>
    // Timepoints
    [key: string]:
      | { timepoints: Record<string, { Besök: number; Nivå: number }> }
      // To satisfy ts
      | boolean
      | number
      | undefined
      | Record<string, { Frekvens: number; Nivå: number }>
  }

  const onSave = async () => {
    if (!municipalityData) return
    try {
      const values = form.getFieldsValue()
      const formatedValues = jsonFormatter(values, weeksOff, municipalityData)

      if (!pid || !pnr) {
        notificationInstance.error({
          message: 'Vänligen skriv en ett personnummer och ett process id och försök igen',
        })
        return null
      }
      const validatedPersonnummer = validatePersonnummer(pnr)
      if (validatedPersonnummer === null) {
        notificationInstance.error({
          message: 'Personnumret är inte giltigt',
        })
        return null
      }
      await saveBeslutsstod(api, formatedValues, pid, validatedPersonnummer, draft, note)
      notificationInstance.success({
        message: 'Beräkningen har sparats',
      })
    } catch (error) {
      notificationInstance.error({
        message: 'Beräkningen kunde inte sparas',
      })
    }
  }

  const onPdfClick = async () => {
    if (!municipalityData) return
    const values = form.getFieldsValue()
    const formatedValues = jsonFormatter(values, weeksOff, municipalityData)
    try {
      const pdfData = await downloadPdf(formatedValues)
      const blob = new Blob([pdfData as any], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'beslutsstöd.pdf'
      a.click()
    } catch (error) {
      notification.error({
        message: 'Pdf kunde inte laddas ned',
      })
    }
  }

  const onFinish = async (values: FormValues) => {
    if (!municipalityData) return
    setSubmittingErrors(null)
    const erroneousTasks = getErroneousTasks(values, municipalityData)
    if (erroneousTasks.length > 0) setSubmittingErrors(erroneousTasks)

    const formatedValues = jsonFormatter(values, weeksOff, municipalityData)
    try {
      const response = await calculateBeslutsstod(api, formatedValues)
      setResult(response)
    } catch (error) {
      notificationInstance.error({
        message: 'Beräkningen kunde inte genomföras',
      })
    }
  }

  const changeFromButtonClick = () => {
    setEditMode(!editMode)
  }

  const saveEdit = async () => {
    try {
      await editBeslutsstod(api, municipalityData, editDescription)
      notificationInstance.success({
        message: 'Ändringarna har sparats',
      })
    } catch (error) {
      notificationInstance.error({
        message: 'Ändringarna kunde inte sparas',
      })
    }
  }

  const onPnrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPnr(event.target.value)
  }
  const onPidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPid(event.target.value)
  }
  const onNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value)
  }

  useEffect(() => {
    const fetchData = async () => {
      const { config, descriptions } = await getMunicipalityData(api)
      let editableData = JSON.parse(JSON.stringify(config))
      setDescriptions(descriptions)
      dispatch(setMunicipality({ municipalityData: editableData }))
      if (id !== undefined) {
        try {
          const response = await loadBeslutsstodById(api, id)

          const inputState = JSON.parse(response.input_state)

          if (response.note) setLoadedNote(response.note)
          const { result: converted, errors } = jsonLoader(inputState.state, config)
          const checkedTimepoints = Object.keys(inputState.state.timepointTasks).filter((key) => converted[key])
          setCheckedTimepoints(checkedTimepoints)

          form.setFieldsValue(converted)

          setLoadedResult(inputState.calculatedTime)
          if (errors.length > 0) {
            notificationInstance.warning({
              message: 'Beräkningsstöd har laddats in med problem',
              description: 'Rätta till nedanstående problem och spara om beräkningen för att beräkningen ska bli korrekt',
            })
            setLoadingErrors(errors)
          } else {
            notificationInstance.success({
              message: 'Beräkningsstöd har laddats in',
            })
          }
        } catch {
          notificationInstance.error({
            message: 'Beräkningsstöd kunde inte laddas in',
          })
        }
      }
    }
    fetchData()
  }, [])
  const renderSwitch = (tab: string) => {
    if (!me) return null
    switch (tab) {
      case '1':
        return (
          <>
            {loadingErrors && loadingErrors.length > 0 && (
              <div className="mb-4">
                <Alert
                  message="Beräkningsstöd har laddats in med problem"
                  description={
                    <>
                      <ul>
                        {loadingErrors.map((error, index) => (
                          <li key={index}>- {error.message}</li>
                        ))}
                      </ul>
                      <p>Rätta till dessa fel och spara om beräkningen för att beräkningen ska bli korrekt</p>
                      <p>
                        OBS: Nuvarande beräkningsresultat är sparat från nedsparning av beräkning och är inte påverkad, däremot kommer beräkning vara inkorrekt om ny beräkning sker utan att felen är
                        lösta
                      </p>
                    </>
                  }
                  type="warning"
                  closable
                  showIcon
                />
              </div>
            )}
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '20%' }}
                placeholder="Välj tidpunkter"
                value={checkedTimepoints}
                onChange={onChange}
                options={timepointOptions(municipalityData?.input.timepoints ?? [])}
              />
              {editMode && municipalityData && <EditableTimepoints />}
              {me.permissions.includes('edit_beslutsstöd') && (
                <Switch
                  checkedChildren={<SettingOutlined />}
                  unCheckedChildren={<CalculatorOutlined />}
                  checked={editMode}
                  onChange={changeFromButtonClick}
                  title={editMode ? 'Ändra till beräkna' : 'Ändra till redigera'}
                />
              )}
              <Popover overlayClassName="decision-popover" title="Ändringshistorik" content={<TimelineTooltip descriptions={descriptions} />}>
                <InfoCircleOutlined />
              </Popover>
            </div>
            <Form form={form} name={'dynamic-form'} onFinish={onFinish}>
              {!editMode && (
                <>
                  <TimepointsTasks timepoints={checkedTimepoints} loadingErrors={loadingErrors ?? undefined} />
                  <Other loadingErrors={loadingErrors ?? undefined} />
                </>
              )}
              {editMode && (
                <>
                  <EditTimepointsTasks timepoints={checkedTimepoints} />
                  <EditableOther />
                  <Typography.Title
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBlock: '40px',
                    }}
                    level={3}
                  >
                    Övriga Inställningar
                  </Typography.Title>
                  <FormSettings />
                  <EditableCalculationSettings />
                  <TextArea
                    value={editDescription}
                    style={{ width: 500 }}
                    onChange={(e) => setEditDescription(e.target.value)}
                    placeholder="Förklara dina ändringar"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                  <Button onClick={saveEdit} type="primary" style={{ marginLeft: '4px' }}>
                    Spara ändringar
                  </Button>
                  <Divider />
                </>
              )}
              {!editMode && (
                <div className="decision-calculate-wrapper">
                  <div style={{}}>
                    {municipalityData && municipalityData.input.formSettings.showShared !== false ? (
                      <Form.Item name={['shared']} valuePropName="checked">
                        <Checkbox>
                          {municipalityData.input.formSettings.nameShared}{' '}
                          <Popover overlayClassName="decision-popover" title={municipalityData.input.formSettings.nameShared} content={municipalityData.input.formSettings.descriptionShared}>
                            <InfoCircleOutlined />
                          </Popover>
                        </Checkbox>
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                    {municipalityData && municipalityData.input.formSettings.showWeeksOff !== false ? (
                      <Form.Item name={['WeeksOff']}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          <InputNumber type="number" onChange={(value) => setWeeksOff(value ?? 0)} addonAfter="veckor" min={0} max={4} defaultValue={0} style={{ maxWidth: '150px' }} />
                          {municipalityData.input.formSettings.nameWeeksOff}
                          <Popover overlayClassName="decision-popover" title={municipalityData.input.formSettings.nameWeeksOff} content={municipalityData.input.formSettings.descriptionWeeksOff}>
                            <InfoCircleOutlined />
                          </Popover>
                        </div>
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Form.Item>
                    <div className="mt-10 flex flex-col items-center gap-2">
                      <Button type="primary" htmlType="submit" className="w-[150px]">
                        Beräkna
                      </Button>
                      {submittingErrors && (
                        <div>
                          <Alert
                            className="w-[365px]"
                            message="Ofullständig inmatning"
                            description={
                              <>
                                <ul>
                                  {submittingErrors.map((error, index) => (
                                    <li key={index}>- {error.message}</li>
                                  ))}
                                </ul>
                              </>
                            }
                            type="warning"
                            closable
                            showIcon
                          />
                        </div>
                      )}
                      {!me.permissions.includes('save_beslutsstöd') && (
                        <Button type="primary" htmlType="button" className="w-[150px]" onClick={onPdfClick} loading={isPdfLoading}>
                          Ladda ned pdf
                        </Button>
                      )}
                    </div>
                  </Form.Item>

                  {me.permissions.includes('save_beslutsstöd') && (
                    <Form.List name={'save'}>
                      {() => (
                        <>
                          <div style={{ display: 'grid', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <Form.Item name={['Personnummer']}>
                                <Input onChange={onPnrChange} placeholder="Personnummer" />
                              </Form.Item>
                              <Form.Item name={['Process id']}>
                                <Input onChange={onPidChange} placeholder="Process id" />
                              </Form.Item>
                            </div>
                            <Form.Item name={['Note']}>
                              <Input.TextArea onChange={onNoteChange} placeholder="Kommentar" rows={4} style={{ resize: 'none' }} />
                            </Form.Item>
                          </div>

                          <div className="decision-calculate-save">
                            <Form.Item name={['Draft']} valuePropName="checked">
                              <Checkbox>Spara som utkast</Checkbox>
                            </Form.Item>
                            <Form.Item>
                              <Button type="primary" htmlType="button" className="w-[150px]" onClick={onSave}>
                                Spara
                              </Button>
                            </Form.Item>
                          </div>
                        </>
                      )}
                    </Form.List>
                  )}
                </div>
              )}

              <div className="min-h-[150px]">
                {(loadedResult || result) && municipalityData?.input.calculationOutputSettings.unit && (
                  <div className="mt-2">
                    <ShowResults result={result ?? loadedResult} unit={municipalityData?.input.calculationOutputSettings.unit} />
                  </div>
                )}
              </div>
            </Form>
          </>
        )

      case '2':
        return <SearchBeslutsstod />

      default:
        return null
    }
  }

  return (
    <>
      {contextHolder}
      <FlagListHeader title="" heading="Beräkningsstöd för tid i hemtjänsten" description={null} button={false} />

      <Tabs onTabClick={(key: string) => setTab(key)}>
        <TabPane tab={'Beräkna'} key={'1'} />
        {me?.permissions.includes('save_beslutsstöd') && <TabPane tab={'Sök'} key={'2'} />}
      </Tabs>

      {renderSwitch(tab)}
    </>
  )
}

export default Beslutsstod
