import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppState } from './store'
import { ExtraTask, MunicipalityData } from '../features/beslutsstod/types/municipalityData'

interface Municipality {
  municipalityData: MunicipalityData | null
}

const initialState: Municipality = {
  municipalityData: null,
}

interface PreviouslySupportedFrequency {
  field: keyof ExtraTask
  timesPerWeek: number
  name: string
}

const previouslySupportedFrequencies: PreviouslySupportedFrequency[] = [
  { field: 'sevenTimesAWeek', timesPerWeek: 7, name: '7 dagar i veckan' },
  { field: 'sixTimesAWeek', timesPerWeek: 6, name: '6 dagar i veckan' },
  { field: 'fiveTimesAWeek', timesPerWeek: 5, name: '5 dagar i veckan' },
  { field: 'fourTimesAWeek', timesPerWeek: 4, name: '4 dagar i veckan' },
  { field: 'threeTimesAWeek', timesPerWeek: 3, name: '3 dagar i veckan' },
  { field: 'twoTimesAWeek', timesPerWeek: 2, name: '2 dagar i veckan' },
  { field: 'oneTimesAWeek', timesPerWeek: 1, name: '1 dag i veckan' },
  { field: 'secondWeek', timesPerWeek: 0.5, name: 'Varannan vecka' },
  { field: 'thirdWeek', timesPerWeek: 0.333, name: 'Var tredje vecka' },
  { field: 'fourthWeek', timesPerWeek: 0.25, name: 'Var fjärde vecka' },
]

const defaultFrequencies = [
  { timesPerWeek: 7, name: '7 dagar i veckan' },
  { timesPerWeek: 6, name: '6 dagar i veckan' },
  { timesPerWeek: 5, name: '5 dagar i veckan' },
  { timesPerWeek: 4, name: '4 dagar i veckan' },
  { timesPerWeek: 3, name: '3 dagar i veckan' },
  { timesPerWeek: 2, name: '2 dagar i veckan' },
  { timesPerWeek: 1, name: '1 dag i veckan' },
  { timesPerWeek: 0.5, name: 'Varannan vecka' },
  { timesPerWeek: 0.333, name: 'Var tredje vecka' },
  { timesPerWeek: 0.25, name: 'Var fjärde vecka' },
]

const defaultFormSettings = {
  showWeeksOff: true,
  showShared: true,
  nameWeeksOff: 'Växelvård',
  nameShared: 'Dela med make/maka',
  descriptionWeeksOff: 'Ange antal veckor per månad som brukaren har växelvård. Beräkning av tid tar hänsyn till antalet veckor som anges.​',
  descriptionShared: 'Delas insatserna bostadens skötsel, städ, tvätt, inköp och tillredning av måltider med make/maka? Beräkning av tid tar hänsyn till detta val.​',
  showDescription: true,
}

const defaultCalculationOutputSettings = {
  unit: 'hours_and_minutes',
  weeksPerMonth: 4,
} as const

const municipalitySlice = createSlice({
  name: 'municipality',
  initialState,
  reducers: {
    setMunicipality(state, action: PayloadAction<Municipality>) {
      state.municipalityData = action.payload.municipalityData
      if (!state.municipalityData) return

      // The following code is for backwards compatibility old formats of the municipality configs

      // Make sure all timepointTasks have frequencies
      state.municipalityData.input.timepointTasks.forEach((task) => {
        if (!task.frequencies) task.frequencies = defaultFrequencies
      })

      // extraTasks:
      // In the case that we have no frequencies, we set values corresponding to the boolean values found in the task
      state.municipalityData.input.extraTasks.forEach((task) => {
        if (task.frequencies) return
        task.frequencies = []
        previouslySupportedFrequencies.forEach((frequency) => {
          const { field, ...newFrequency } = frequency
          if (task[field]) {
            task.frequencies?.push(newFrequency)
          }
        })
      })

      // Default 'calcAs' to 'extraTask' for all extraTasks
      state.municipalityData.input.extraTasks.forEach((task) => {
        if (!task.calcAs) task.calcAs = 'extraTask'
      })

      // If the formSettings are not set, we set them to the default values
      state.municipalityData.input.formSettings = {
        ...defaultFormSettings,
        ...state.municipalityData.input.formSettings,
      }

      // If the calculationOutputSettings are not set, we set them to the default values
      state.municipalityData.input.calculationOutputSettings = {
        ...defaultCalculationOutputSettings,
        ...state.municipalityData.input.calculationOutputSettings,
      }

      // Sometimes react makes the timesPerWeek values readonly so we use try/catch to avoid errors
      try {
        // Make sure all frequencies in all tasks are rounded to 3 decimals
        state.municipalityData.input.timepointTasks.forEach((task) => {
          task.frequencies?.forEach((frequency) => {
            frequency.timesPerWeek = Math.round(frequency.timesPerWeek * 1000) / 1000
          })
        })
        state.municipalityData.input.extraTasks.forEach((task) => {
          task.frequencies?.forEach((frequency) => {
            frequency.timesPerWeek = Math.round(frequency.timesPerWeek * 1000) / 1000
          })
        })
      } catch (e) {}

      console.log(state.municipalityData?.input)
    },
  },
})

export const getmunicipality = (state: AppState) => state.municipalityReducer.municipalityData
export const { setMunicipality } = municipalitySlice.actions
export default municipalitySlice.reducer
