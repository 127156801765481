import { Button, Card, Typography } from 'antd'
import { useState } from 'react'
import { getmunicipality } from '../../../store/municipalitySlice'
import { useSelector } from 'react-redux'
import ShowResults from '../showResults'
import { createTimeString } from '../utils/createTimeString'
import { Evaluation } from '../../../api/beslutsstod'
import moment from 'moment'

interface InfoCardProps {
  evaluation: Evaluation
  archive: () => Promise<void>
}

export const InfoCard = ({ evaluation, archive }: InfoCardProps) => {
  const municipalityData = useSelector(getmunicipality)
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const inputState = JSON.parse(evaluation.input_state)

  if (!municipalityData?.input.calculationOutputSettings.unit) return null

  const totalExDouble = inputState.calculatedTime.totalExDouble ?? inputState.calculatedTime.totalHoursPerMonth - inputState.calculatedTime.hoursPerMonthTimePointsTasksDouble
  return (
    <div
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 'min(100%, 800px)',
        paddingBottom: '30px',
        textAlign: 'left',
        cursor: 'pointer',
      }}
    >
      <Card hoverable onClick={handleExpandClick}>
        <Card.Meta title={`Process ID: ${evaluation.process_id} ${evaluation.draft ? 'UTKAST' : ''}`} />
        <div style={{ padding: '10px' }}>
          {expanded ? (
            <>
              {evaluation.created && <Typography.Paragraph type="secondary">Skapad: {moment(evaluation.created).format('YYYY-MM-DD HH:mm')}</Typography.Paragraph>}
              <Typography>
                <ShowResults type="secondary" result={inputState.calculatedTime} unit={municipalityData.input.calculationOutputSettings.unit} />
              </Typography>
              {inputState.state?.checkboxes &&
                Object.keys(inputState.state.checkboxes).map((key, i) => (
                  <Typography.Paragraph type="secondary" key={i}>
                    {key}: {inputState.state.checkboxes[key] ? 'Ja' : 'Nej'}
                  </Typography.Paragraph>
                ))}
              {evaluation.note && (
                <Typography.Paragraph type="secondary">
                  <b>Anteckning:</b> {evaluation.note}
                </Typography.Paragraph>
              )}
            </>
          ) : (
            <>
              {evaluation.created && <Typography.Text type="secondary">Skapad: {moment(evaluation.created).format('YYYY-MM-DD HH:mm')}</Typography.Text>}
              <br />
              <Typography.Text type="secondary">Total tid exklusive dubbelbemanning: {createTimeString(totalExDouble, municipalityData.input.calculationOutputSettings.unit)}</Typography.Text>
              <br />
              <Typography.Text type="secondary">
                Total tid inklusive dubbelbemanning: {createTimeString(inputState.calculatedTime.totalHoursPerMonth, municipalityData.input.calculationOutputSettings.unit)}
              </Typography.Text>
              {evaluation.note && (
                <p>
                  <Typography.Text type="secondary">
                    <b>Anteckning:</b> {evaluation.note}
                  </Typography.Text>
                </p>
              )}
            </>
          )}
          <div style={{ marginTop: 10 }}>
            <Button type="primary" style={{ marginRight: 5 }}>
              <a href={`/app/beslutsstod/${evaluation.id}`}>Kopiera tidigare underlag</a>
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                archive()
              }}
              type="primary"
            >
              Makulera
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}
