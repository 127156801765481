import { IVerksamhet } from '../arendeFordelning'
import type { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { footnote } from '.'
import { Tooltip } from 'antd'
import shortenText from '../arendeFordelning/components/tableUtils'

export interface IMyCasesData2 {
  personnummer: string
  namn: string
  ansvarig_person: string
  dagverksamhet: IVerksamhet[] | null
  trygghetslarm: IVerksamhet[] | null
  korttidsboende: IVerksamhet[] | null
  hemtjänst: IVerksamhet[] | null
  omfattning_månad: number | null // I timmar
  dubbelbemanning: boolean | null
  natthjälp: boolean | null
  ny_process: string[] | null // Strängar med datum
  nästa_kvalitetsfrågor: string | null // Datum
  utförare_hemtjänst: string[]
  utförare_säbo: string[]
  utförare_korttidsboende: string[]
  utförare_dagverksamhet: string[]
  utförare_trygghetslarm: string[]
  hushåll: {
    medlem: string
    aktledare: boolean
  }[] // Strängar med personnummer
  kontaktperson_tillfällen: number
  boendestöd: boolean
  hemtjänst_boende: boolean
  natt: boolean
  ännu_ej_aktiv_ht: boolean
  ännu_ej_aktiv_tl: boolean
  ännu_ej_aktiv_dv: boolean
  ännu_ej_aktiv_kb: boolean
  säbo_fv: string
  säbo_till: string
}

export const createMinaArendenTableConfig = (data: IMyCasesData2[], selected: string[], filters: any) => {
  const columns: ColumnsType<IMyCasesData2> = [
    {
      title: 'Namn',
      dataIndex: '',
      key: 'namn',
      width: 250,
      fixed: 'left',
      showSorterTooltip: { title: 'Brukarens namn' },
      filterMode: 'menu',
      filteredValue: filters.namn,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.namn || 'Inget värde'
            })
          )
        )
          .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.namn && b.namn) return -1
        if (!b.namn) return 1
        return a.namn.localeCompare(b.namn)
      },
      render: (_, obj) => (
        <Link
          to={{
            pathname: `/app/brukare/id=${obj.personnummer}&namn= ${obj.namn}`,
          }}
        >
          <Tooltip title={obj.namn}>
            <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
              {shortenText(obj.namn)}
            </span>
          </Tooltip>
        </Link>
      ),
      onFilter: (value, record) => record.namn === value,
      filterSearch: true,
    },
    {
      title: 'Personnummer',
      dataIndex: '',
      key: 'personnummer',
      width: 175,
      filteredValue: filters.personnummer,
      showSorterTooltip: { title: 'Brukarens personnummer' },
      filterMode: 'menu',
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.personnummer || 'Inget värde'
            })
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => (a.personnummer || '').localeCompare(b.personnummer || ''),
      render: (_, obj) => (
        <>
          <Tooltip title={obj.personnummer}>
            <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
              {shortenText(obj.personnummer, 15)}
            </span>
          </Tooltip>
        </>
      ),
      onFilter: (value, record) => record.personnummer === value,
      filterSearch: true,
    },
    {
      title: 'Öppen process',
      dataIndex: '',
      key: 'öppen-process',
      width: 180,
      showSorterTooltip: { title: 'Datum för initiering. Beslut eller beställning saknas' },
      filterMode: 'menu',
      filteredValue: filters['öppen-process'],
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (d.ny_process && d.ny_process.length > 0 && d.ny_process[0] !== undefined) {
                return d.ny_process[0]
              } else {
                return 'Inget värde'
              }
            })
          )
        )
          .sort((a, b) => {
            if (a === 'Inget värde') return -1
            if (b === 'Inget värde') return 1
            return a.localeCompare(b)
          })
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.ny_process || a.ny_process?.length === 0) {
          return 1
        } else if (!b.ny_process || b.ny_process?.length === 0) {
          return -1
        } else {
          return new Date(a.ny_process[0]).valueOf() - new Date(b.ny_process[0]).valueOf() > 0 ? 1 : -1
        }
      },
      render: (_, obj) => {
        if (obj.ny_process && obj.ny_process.length > 0) {
          return obj.ny_process[0]
        } else {
          return ''
        }
      },
      onFilter: (value, record) => {
        if (record.ny_process && record.ny_process.length > 0 && record.ny_process[0] !== undefined) {
          return record.ny_process[0] === value
        } else if (value === 'Inget värde') {
          return true
        } else {
          return false
        }
      },
      filterSearch: true,
    },
    {
      title: 'Hemtjänst',
      dataIndex: '',
      key: 'hemtjänst',
      width: 150,
      showSorterTooltip: { title: 'Datum för när beställda insatser upphör att gälla' },
      filterMode: 'menu',
      filteredValue: filters.hemtjänst,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (d.hemtjänst && d.hemtjänst.length > 0 && d.hemtjänst[0].till !== undefined) {
                return d.hemtjänst[0].till
              } else {
                return 'Inget värde'
              }
            })
          )
        )
          .sort((a, b) => {
            if (a === 'Inget värde') return -1
            if (b === 'Inget värde') return 1
            return a.localeCompare(b)
          })
          .map((name) => {
            return {
              value: name,
              text: name === 'Inget värde' ? name : moment(name).format('YYYY-MM-DD'),
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.hemtjänst || a.hemtjänst?.length === 0) {
          return 1
        } else if (!b.hemtjänst || b.hemtjänst?.length === 0) {
          return -1
        } else {
          return new Date(a.hemtjänst[0].till).valueOf() - new Date(b.hemtjänst[0].till).valueOf() > 0 ? 1 : -1
        }
      },
      render: (_, obj) => {
        if (obj.hemtjänst && obj.hemtjänst.length > 0 && obj.hemtjänst[0].till !== undefined) {
          const today = new Date()
          const momentDay = moment(today)
          const footnotes: footnote[] = []
          if (obj.hemtjänst[0].förlängning) {
            footnotes.push({
              number: '1',
              content: 'Beslutet är av typen förlängning',
            })
          }
          if (moment(obj.hemtjänst[0].till).diff(obj.hemtjänst[0].från, 'months') < 3 && obj.hemtjänst[0].till !== undefined && obj.hemtjänst[0].från !== undefined) {
            footnotes.push({
              number: '3',
              content: 'Beslutet är kortare än tre månader',
            })
          }
          if (obj.hemtjänst.length > 1) {
            footnotes.push({
              number: '4',
              content: 'Flera beslut finns',
            })
          }
          if (obj.natthjälp) {
            footnotes.push({
              number: '5',
              content: 'Nattinsatser förekommer',
            })
          }
          if (obj.boendestöd) {
            footnotes.push({
              number: '7',
              content: 'Boendestöd och/eller kontaktperson',
            })
          }
          if (obj.hemtjänst_boende) {
            footnotes.push({
              number: '6',
              content: 'Hemtjänst boende',
            })
          }
          if (obj.kontaktperson_tillfällen > 0) {
            footnotes.push({
              number: '7',
              content: 'Boendestöd och/eller kontaktperson',
            })
          }
          return (
            <>
              {moment(obj.hemtjänst[0].till).format('YYYY-MM-DD')}
              {footnotes.map((elem) => {
                return (
                  <Tooltip title={elem.content}>
                    <span className="footnote">{elem.number}</span>
                  </Tooltip>
                )
              })}
            </>
          )
        } else {
          return ''
        }
      },
      onFilter: (value, record) => {
        if (record.hemtjänst && record.hemtjänst.length > 0 && record.hemtjänst[0].till !== undefined) {
          return record.hemtjänst[0].till === value
        } else {
          return value === 'Inget värde'
        }
      },
      filterSearch: true,
    },
    {
      title: 'Hemtjänst tid',
      dataIndex: '',
      key: 'hemtjänst-tid',
      width: 170,
      showSorterTooltip: { title: 'Antal beställda timmar hemtjänst per månad' },
      filteredValue: filters['hemtjänst-tid'],
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.omfattning_månad?.toFixed(1) || 'Inget värde'
            })
          )
        )
          .sort(function (a, b) {
            return Number(a) - Number(b)
          })
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') {
          if (a.omfattning_månad && b.omfattning_månad) {
            return a.omfattning_månad - b.omfattning_månad
          } else if (a.omfattning_månad) {
            return -1
          } else if (b.omfattning_månad) {
            return 1
          } else {
            return 0
          }
        } else {
          if (!a.omfattning_månad && !b.omfattning_månad) {
            return -Infinity
          } else if (!a.omfattning_månad) {
            return -1
          } else if (!b.omfattning_månad) {
            return 1
          } else {
            return a.omfattning_månad - b.omfattning_månad
          }
        }
      },
      render: (_, obj) => {
        const footnotes: footnote[] = []
        if (obj.dubbelbemanning) {
          footnotes.push({
            number: '2',
            content: 'Dubbelbemanning',
          })
        }
        return (
          <>
            {obj.omfattning_månad === 0 && obj.kontaktperson_tillfällen > 0 ? <></> : obj.omfattning_månad?.toFixed(1) || ''}
            {footnotes.map((elem) => {
              return (
                <Tooltip title={elem.content}>
                  <span className="footnote">{elem.number}</span>
                </Tooltip>
              )
            })}
          </>
        )
      },
      onFilter: (value, record) => {
        if (record.omfattning_månad) {
          return record.omfattning_månad.toFixed(1) === value
        } else {
          return value === 'Inget värde'
        }
      },
      filterSearch: true,
    },
    {
      title: 'Trygghetslarm',
      dataIndex: '',
      key: 'trygghetslarm',
      width: 165,
      showSorterTooltip: { title: 'Datum för när beställda insatser upphör att gälla' },
      filteredValue: filters.trygghetslarm,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (d.trygghetslarm && d.trygghetslarm.length > 0 && d.trygghetslarm[0].till !== undefined) {
                return moment(d.trygghetslarm[0].till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(d.trygghetslarm[0].till).format('YYYY-MM-DD') : 'Tillsvidare'
              } else {
                return 'Inget värde'
              }
            })
          )
        )
          .sort((a, b) => {
            if (a === 'Tillsvidare' && b === 'Inget värde') return 1
            if (a === 'Inget värde' && b === 'Tillsvidare') return -1
            if (a === 'Tillsvidare' || a === 'Inget värde') return -1
            if (b === 'Tillsvidare' || b === 'Inget värde') return 1
            return a.localeCompare(b)
          })
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.trygghetslarm || a.trygghetslarm?.length === 0) {
          return 1
        } else if (!b.trygghetslarm || b.trygghetslarm?.length === 0) {
          return -1
        } else {
          return new Date(a.trygghetslarm[0].till).valueOf() - new Date(b.trygghetslarm[0].till).valueOf() > 0 ? 1 : -1
        }
      },
      render: (_, obj) => {
        if (obj.trygghetslarm && obj.trygghetslarm.length > 0) {
          const today = new Date()
          const momentDay = moment(today)
          const footnotes: footnote[] = []
          if (obj.trygghetslarm[0].förlängning) {
            footnotes.push({
              number: '1',
              content: 'Beslutet är av typen förlängning',
            })
          }
          if (moment(obj.trygghetslarm[0].till).diff(obj.trygghetslarm[0].från, 'months') <= 3 && obj.trygghetslarm[0].från !== undefined && obj.trygghetslarm[0].till !== undefined) {
            footnotes.push({
              number: '3',
              content: 'Beslutet är kortare än tre månader',
            })
          }
          if (obj.trygghetslarm.length > 1) {
            footnotes.push({
              number: '4',
              content: 'Flera beslut finns',
            })
          }

          return (
            <>
              {moment(obj.trygghetslarm[0].till).diff(moment(Date.now()).add(5, 'y'), 'years') < 10 ? moment(obj.trygghetslarm[0].till).format('YYYY-MM-DD') : 'Tillsvidare'}
              {footnotes.map((elem) => {
                return (
                  <Tooltip title={elem.content}>
                    <span className="footnote">{elem.number}</span>
                  </Tooltip>
                )
              })}
            </>
          )
        } else {
          return ''
        }
      },
      onFilter: (value, record) => {
        if (record.trygghetslarm && record.trygghetslarm.length > 0 && record.trygghetslarm[0].till !== undefined) {
          if (value === 'Tillsvidare') {
            return moment(record.trygghetslarm[0].till).diff(moment(Date.now()).add(5, 'y'), 'years') >= 10
          }
          return moment(record.trygghetslarm[0].till).format('YYYY-MM-DD') === value
        } else {
          return value === 'Inget värde'
        }
      },
      filterSearch: true,
    },
    {
      title: 'Dagverksamhet',
      dataIndex: '',
      key: 'dagverksamhet',
      width: 175,
      showSorterTooltip: { title: 'Datum för när beställda insatser upphör att gälla' },
      filterMode: 'menu',
      filteredValue: filters.dagverksamhet,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (d.dagverksamhet && d.dagverksamhet.length > 0 && d.dagverksamhet[0].till !== undefined) {
                return d.dagverksamhet[0].till
              } else {
                return 'Inget värde'
              }
            })
          )
        )
          .sort((a, b) => {
            if (a === 'Inget värde') return -1
            if (b === 'Inget värde') return 1
            return a.localeCompare(b)
          })
          .map((name) => {
            return {
              value: name,
              text: name === 'Inget värde' ? name : moment(name).format('YYYY-MM-DD'),
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.dagverksamhet || a.dagverksamhet?.length === 0) {
          return 1
        } else if (!b.dagverksamhet || b.dagverksamhet?.length === 0) {
          return -1
        } else {
          return new Date(a.dagverksamhet[0].till).valueOf() - new Date(b.dagverksamhet[0].till).valueOf() > 0 ? 1 : -1
        }
      },
      render: (_, obj) => {
        if (obj.dagverksamhet && obj.dagverksamhet.length > 0) {
          const today = new Date()
          const momentDay = moment(today)
          const footnotes: footnote[] = []
          if (obj.dagverksamhet[0].förlängning) {
            footnotes.push({
              number: '1',
              content: 'Beslutet är av typen förlängning',
            })
          }
          if (moment(obj.dagverksamhet[0].till).diff(obj.dagverksamhet[0].från, 'months') <= 3 && obj.dagverksamhet[0].från !== undefined && obj.dagverksamhet[0].till !== undefined) {
            footnotes.push({
              number: '3',
              content: 'Beslutet är kortare än tre månader',
            })
          }
          if (obj.dagverksamhet.length > 1) {
            footnotes.push({
              number: '4',
              content: 'Flera beslut finns',
            })
          }

          return (
            <>
              {moment(obj.dagverksamhet[0].till).format('YYYY-MM-DD')}
              {footnotes.map((elem, index) => {
                return (
                  <Tooltip title={elem.content} key={'elem-' + index}>
                    <span className="footnote">{elem.number}</span>
                  </Tooltip>
                )
              })}
            </>
          )
        } else {
          return ''
        }
      },
      onFilter: (value, record) => {
        if (record.dagverksamhet && record.dagverksamhet.length > 0 && record.dagverksamhet[0].till !== undefined) {
          return record.dagverksamhet[0].till === value
        } else {
          return value === 'Inget värde'
        }
      },
      filterSearch: true,
    },
    {
      title: 'Korttids/Växelvård',
      dataIndex: '',
      width: 200,
      key: 'korttidsboende',
      showSorterTooltip: { title: 'Datum för när beställda insatser upphör att gälla' },
      filterMode: 'menu',
      filteredValue: filters.korttidsboende,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (d.korttidsboende && d.korttidsboende.length > 0 && d.korttidsboende[0].till !== undefined) {
                return d.korttidsboende[0].till
              } else {
                return 'Inget värde'
              }
            })
          )
        )
          .sort((a, b) => {
            if (a === 'Inget värde') return -1
            if (b === 'Inget värde') return 1
            return a.localeCompare(b)
          })
          .map((name) => {
            return {
              value: name,
              text: name === 'Inget värde' ? name : moment(name).format('YYYY-MM-DD'),
            }
          }),
      ],
      sorter: (a, b) => {
        if (!a.korttidsboende || a.korttidsboende?.length === 0) {
          return 1
        } else if (!b.korttidsboende || b.korttidsboende?.length === 0) {
          return -1
        } else {
          return new Date(a.korttidsboende[0].till).valueOf() - new Date(b.korttidsboende[0].till).valueOf() > 0 ? 1 : -1
        }
      },
      render: (_, obj) => {
        if (obj.korttidsboende && obj.korttidsboende.length > 0 && obj.korttidsboende[0].från !== undefined && obj.korttidsboende[0].till !== undefined) {
          const today = new Date()
          const momentDay = moment(today)
          const footnotes: footnote[] = []
          if (obj.korttidsboende[0].förlängning) {
            footnotes.push({
              number: '1',
              content: 'Beslutet är av typen förlängning',
            })
          }

          if (obj.korttidsboende.length > 1) {
            footnotes.push({
              number: '4',
              content: 'Flera beslut finns',
            })
          }

          return (
            <>
              {moment(obj.korttidsboende[0].till).format('YYYY-MM-DD')}
              {footnotes.map((elem) => {
                return (
                  <Tooltip title={elem.content}>
                    <span className="footnote">{elem.number}</span>
                  </Tooltip>
                )
              })}
            </>
          )
        } else {
          return ''
        }
      },
      onFilter: (value, record) => {
        if (record.korttidsboende && record.korttidsboende.length > 0 && record.korttidsboende[0].till !== undefined) {
          return record.korttidsboende[0].till === value
        } else {
          return value === 'Inget värde'
        }
      },
      filterSearch: true,
    },
    {
      title: 'Uppföljning säbo',
      dataIndex: '',
      key: 'uppföljning-säbo',
      width: 200,
      showSorterTooltip: { title: 'Datum för när brukarens upplevelse ska följas upp' },
      filterMode: 'menu',
      filteredValue: filters['uppföljning-säbo'],
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              if (moment(d.säbo_fv).isBefore(moment('1980-01-01'))) return 'Ej verkställd'
              return d.nästa_kvalitetsfrågor || 'Inget värde'
            })
          )
        )
          .sort((a, b) => {
            if (a === 'Inget värde' || a === 'Ej verkställd') return -1
            if (b === 'Inget värde' || b === 'Ej verkställd') return 1
            return a.localeCompare(b)
          })
          .map((name) => {
            return {
              value: name,
              text: name === 'Inget värde' || name === 'Ej verkställd' ? name : moment(name).format('YYYY-MM-DD'),
            }
          }),
      ],
      sorter: (a, b) => {
        // A doesnt have säbo, b goes first
        if (!a.säbo_fv) return 1
        // Either a has säbo or both dont have säbo, a goes first
        else if (!b.säbo_fv) return -1

        // If is not verkställd, it goes last
        if (moment(a.säbo_fv).isBefore(moment('1980-01-01'))) return 1
        if (moment(b.säbo_fv).isBefore(moment('1980-01-01'))) return -1
        // A doesnt have next date, b goes first
        else if (!a.nästa_kvalitetsfrågor) return -1
        // Either a has next date or both dont have next date, a goes first
        else if (!b.nästa_kvalitetsfrågor) return 1
        else return new Date(a.nästa_kvalitetsfrågor).valueOf() - new Date(b.nästa_kvalitetsfrågor).valueOf()
      },
      render: (_, obj) => {
        const footnotes: footnote[] = []
        if (moment(obj.säbo_till).isBefore(moment('2049-01-01'))) {
          footnotes.push({
            number: '8',
            content: 'Tidsbegränsat beslut',
          })
        }
        if (moment(obj.säbo_fv).isBefore(moment('1980-01-01'))) {
          return (
            <>
              Ej verkställd
              {footnotes.map((elem) => {
                return (
                  <Tooltip title={elem.content}>
                    <span className="footnote">{elem.number}</span>
                  </Tooltip>
                )
              })}
            </>
          )
        }
        return (
          <>
            {obj.nästa_kvalitetsfrågor ? moment(obj.nästa_kvalitetsfrågor).format('YYYY-MM-DD') : ''}
            {footnotes.map((elem) => {
              return (
                <Tooltip title={elem.content}>
                  <span className="footnote">{elem.number}</span>
                </Tooltip>
              )
            })}
          </>
        )
      },
      onFilter: (value, record) => {
        if (record.säbo_fv && moment(record.säbo_fv).isBefore(moment('1980-01-01'))) return value === 'Ej verkställd'
        if (!record.nästa_kvalitetsfrågor) return value === 'Inget värde'
        else return record.nästa_kvalitetsfrågor === value
      },
      filterSearch: true,
    },
    {
      title: 'Utförare',
      dataIndex: '',
      width: 250,
      key: 'utförare',
      showSorterTooltip: { title: 'Utförarens namn' },
      filterMode: 'menu',
      filteredValue: filters.utförare,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data
              .map((d) => {
                const res = []
                d.utförare_hemtjänst?.length > 0 && res.push(...d.utförare_hemtjänst)
                d.utförare_säbo.length > 0 && res.push(...d.utförare_säbo)
                d.utförare_dagverksamhet.length > 0 && res.push(...d.utförare_dagverksamhet)
                d.utförare_korttidsboende.length > 0 && res.push(...d.utförare_korttidsboende)
                d.utförare_trygghetslarm.length > 0 && res.push(...d.utförare_trygghetslarm)
                return res
                //return d.utförare_hemtjänst || d.utförare_säbo || d.utförare_dagverksamhet || d.utförare_korttidsboende || d.utförare_trygghetslarm || []
              })
              .flat()
          )
        )
          .sort()
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b) => {
        let utförare_hemtjänst_a, utförare_hemtjänst_b
        if (a.utförare_hemtjänst && a.utförare_hemtjänst.length > 0) utförare_hemtjänst_a = a.utförare_hemtjänst[0]
        if (b.utförare_hemtjänst && b.utförare_hemtjänst.length > 0) utförare_hemtjänst_b = b.utförare_hemtjänst[0]

        let utförare_säbo_a, utförare_säbo_b
        if (a.utförare_säbo && a.utförare_säbo.length > 0) utförare_säbo_a = a.utförare_säbo[0]
        if (b.utförare_säbo && b.utförare_säbo.length > 0) utförare_säbo_b = b.utförare_säbo[0]

        let utförare_dagverksamhet_a, utförare_dagverksamhet_b
        if (a.utförare_dagverksamhet && a.utförare_dagverksamhet.length > 0) utförare_dagverksamhet_a = a.utförare_dagverksamhet[0]
        if (b.utförare_dagverksamhet && b.utförare_dagverksamhet.length > 0) utförare_dagverksamhet_b = b.utförare_dagverksamhet[0]

        let utförare_korttidsboende_a, utförare_korttidsboende_b
        if (a.utförare_korttidsboende && a.utförare_korttidsboende.length > 0) utförare_korttidsboende_a = a.utförare_korttidsboende[0]
        if (b.utförare_korttidsboende && b.utförare_korttidsboende.length > 0) utförare_korttidsboende_b = b.utförare_korttidsboende[0]

        let utförare_trygghetslarm_a, utförare_trygghetslarm_b
        if (a.utförare_trygghetslarm && a.utförare_trygghetslarm.length > 0) utförare_trygghetslarm_a = a.utförare_trygghetslarm[0]
        if (b.utförare_trygghetslarm && b.utförare_trygghetslarm.length > 0) utförare_trygghetslarm_b = b.utförare_trygghetslarm[0]

        return (utförare_hemtjänst_a || utförare_säbo_a || utförare_dagverksamhet_a || utförare_korttidsboende_a || utförare_trygghetslarm_a || '').localeCompare(
          utförare_hemtjänst_b || utförare_säbo_b || utförare_dagverksamhet_b || utförare_korttidsboende_b || utförare_trygghetslarm_b || ''
        )
      },
      render: (_, obj) => {
        let utförare_hemtjänst
        if (obj.utförare_hemtjänst && obj.utförare_hemtjänst.length > 0) utförare_hemtjänst = obj.utförare_hemtjänst[0]

        let utförare_säbo
        if (obj.utförare_säbo && obj.utförare_säbo.length > 0) utförare_säbo = obj.utförare_säbo[0]

        let utförare_dagverksamhet
        if (obj.utförare_dagverksamhet && obj.utförare_dagverksamhet.length > 0) utförare_dagverksamhet = obj.utförare_dagverksamhet[0]

        let utförare_korttidsboende
        if (obj.utförare_korttidsboende && obj.utförare_korttidsboende.length > 0) utförare_korttidsboende = obj.utförare_korttidsboende[0]

        let utförare_trygghetslarm
        if (obj.utförare_trygghetslarm && obj.utförare_trygghetslarm.length > 0) utförare_trygghetslarm = obj.utförare_trygghetslarm[0]

        if (utförare_hemtjänst) {
          return (
            <Tooltip title={utförare_hemtjänst}>
              <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
                {shortenText(utförare_hemtjänst)}
              </span>
            </Tooltip>
          )
        } else if (utförare_säbo) {
          return (
            <Tooltip title={utförare_säbo}>
              <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
                {shortenText(utförare_säbo)}
              </span>
            </Tooltip>
          )
        } else if (utförare_dagverksamhet) {
          return (
            <Tooltip title={utförare_dagverksamhet}>
              <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
                {shortenText(utförare_dagverksamhet)}
              </span>
            </Tooltip>
          )
        } else if (utförare_korttidsboende) {
          return (
            <Tooltip title={utförare_korttidsboende}>
              <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
                {shortenText(utförare_korttidsboende)}
              </span>
            </Tooltip>
          )
        } else if (utförare_trygghetslarm) {
          return (
            <Tooltip title={utförare_trygghetslarm}>
              <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
                {shortenText(utförare_trygghetslarm)}
              </span>
            </Tooltip>
          )
        } else {
          return ''
        }
      },
      onFilter: (value, record) => {
        let utförare_hemtjänst
        if (record.utförare_hemtjänst && record.utförare_hemtjänst.length > 0) utförare_hemtjänst = record.utförare_hemtjänst[0]

        let utförare_säbo
        if (record.utförare_säbo && record.utförare_säbo.length > 0) utförare_säbo = record.utförare_säbo[0]

        let utförare_dagverksamhet
        if (record.utförare_dagverksamhet && record.utförare_dagverksamhet.length > 0) utförare_dagverksamhet = record.utförare_dagverksamhet[0]

        let utförare_korttidsboende
        if (record.utförare_korttidsboende && record.utförare_korttidsboende.length > 0) utförare_korttidsboende = record.utförare_korttidsboende[0]

        let utförare_trygghetslarm
        if (record.utförare_trygghetslarm && record.utförare_trygghetslarm.length > 0) utförare_trygghetslarm = record.utförare_trygghetslarm[0]

        return (utförare_hemtjänst || utförare_säbo || utförare_dagverksamhet || utförare_korttidsboende || utförare_trygghetslarm || '') === value
      },
      filterSearch: true,
    },
    {
      title: 'Delar hushåll',
      dataIndex: '',
      key: 'hushåll',
      width: 250,
      showSorterTooltip: { title: 'Person med insatser i samma hushåll som brukaren' },
      filterMode: 'menu',
      filteredValue: filters.hushåll,
      filters: [
        { value: 'Välj alla', text: 'Välj alla' },
        ...Array.from(
          new Set(
            data.map((d) => {
              return d.hushåll.length > 0 ? d.hushåll[0].medlem : 'Inget värde'
            })
          )
        )
          .sort((a, b) => (a || '').localeCompare(b || '', undefined, { numeric: true }))
          .map((name) => {
            return {
              value: name,
              text: name,
            }
          }),
      ],
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') {
          if (a.hushåll.length === 0 && b.hushåll.length === 0) return -Infinity
          if (b.hushåll.length > 0) return -1
          if (a.hushåll.length > 0) return 1
          return (a.hushåll[0].medlem || '').localeCompare(b.hushåll[0].medlem)
        }
        if (a.hushåll.length === 0 && b.hushåll.length > 0) return -1
        if (b.hushåll.length === 0) return 1
        return (a.hushåll[0].medlem || '').localeCompare(b.hushåll[0].medlem)
      },
      render: (_, obj) => (
        <>
          {obj.hushåll.map((item) => (
            <div className="flex">
              <Tooltip title={item.medlem}>
                <span style={{ display: 'flex', justifyContent: 'flex-start', top: '0' }} className="footnote-normal-text">
                  {shortenText(item.medlem)}
                </span>
              </Tooltip>
            </div>
          ))}
        </>
      ),
      onFilter: (value, record) => {
        if (record.hushåll.length === 0) return value === 'Inget värde'
        return record.hushåll.map((a) => a.medlem).sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]))[0] === value
      },
      filterSearch: true,
    },
  ]
  return columns.filter((column) => !selected.includes(column.title as string))
}
