/* tslint:disable */
/* eslint-disable */
/**
 * express-typescript-boilerplate
 * Boilerplate for express with typescript, including logging, ORM  and database connection
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddAuthenticationMethodRequest
 */
export interface AddAuthenticationMethodRequest {
    /**
     * 
     * @type {string}
     * @memberof AddAuthenticationMethodRequest
     */
    'authenticationMethod': string;
}
/**
 * 
 * @export
 * @interface AddFlagsRequest
 */
export interface AddFlagsRequest {
    /**
     * 
     * @type {number}
     * @memberof AddFlagsRequest
     */
    'addedBy': number;
    /**
     * 
     * @type {string}
     * @memberof AddFlagsRequest
     */
    'comment': string;
    /**
     * 
     * @type {Array<IFlagData>}
     * @memberof AddFlagsRequest
     */
    'flags': Array<IFlagData>;
    /**
     * 
     * @type {number}
     * @memberof AddFlagsRequest
     */
    'c_case': number;
}
/**
 * 
 * @export
 * @interface AddModuleToRoleRequest
 */
export interface AddModuleToRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof AddModuleToRoleRequest
     */
    'moduleId': number;
    /**
     * 
     * @type {number}
     * @memberof AddModuleToRoleRequest
     */
    'roleId': number;
}
/**
 * 
 * @export
 * @interface AddPermissionOverrideRequest
 */
export interface AddPermissionOverrideRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPermissionOverrideRequest
     */
    'action': AddPermissionOverrideRequestActionEnum;
    /**
     * 
     * @type {number}
     * @memberof AddPermissionOverrideRequest
     */
    'permissionId': number;
    /**
     * 
     * @type {number}
     * @memberof AddPermissionOverrideRequest
     */
    'roleId': number;
}

export const AddPermissionOverrideRequestActionEnum = {
    Add: 'add',
    Remove: 'remove'
} as const;

export type AddPermissionOverrideRequestActionEnum = typeof AddPermissionOverrideRequestActionEnum[keyof typeof AddPermissionOverrideRequestActionEnum];

/**
 * 
 * @export
 * @interface AddRole200Response
 */
export interface AddRole200Response {
    /**
     * 
     * @type {number}
     * @memberof AddRole200Response
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface AntalBrukareBeviljad200ResponseInner
 */
export interface AntalBrukareBeviljad200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof AntalBrukareBeviljad200ResponseInner
     */
    'ammount': number;
    /**
     * 
     * @type {string}
     * @memberof AntalBrukareBeviljad200ResponseInner
     */
    'month': string;
}
/**
 * 
 * @export
 * @interface AuthenticateRequest
 */
export interface AuthenticateRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface CreateGuestUser200Response
 */
export interface CreateGuestUser200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateGuestUser200Response
     */
    'personNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGuestUser200Response
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGuestUser200Response
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface CreateGuestUserRequest
 */
export interface CreateGuestUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateGuestUserRequest
     */
    'personNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGuestUserRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGuestUserRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface EditGuestUserRequest
 */
export interface EditGuestUserRequest {
    /**
     * 
     * @type {string}
     * @memberof EditGuestUserRequest
     */
    'personNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditGuestUserRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface ExtendedTag
 */
export interface ExtendedTag {
    /**
     * 
     * @type {Array<ExtendedTagMunicipalitiesInner>}
     * @memberof ExtendedTag
     */
    'municipalities': Array<ExtendedTagMunicipalitiesInner>;
    /**
     * 
     * @type {string}
     * @memberof ExtendedTag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedTag
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ExtendedTagMunicipalitiesInner
 */
export interface ExtendedTagMunicipalitiesInner {
    /**
     * 
     * @type {string}
     * @memberof ExtendedTagMunicipalitiesInner
     */
    'koladaId': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedTagMunicipalitiesInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedTagMunicipalitiesInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface F2All200Response
 */
export interface F2All200Response {
    /**
     * 
     * @type {F2All200ResponseResults}
     * @memberof F2All200Response
     */
    'results': F2All200ResponseResults;
}
/**
 * 
 * @export
 * @interface F2All200ResponseResults
 */
export interface F2All200ResponseResults {
    /**
     * 
     * @type {Array<IQueryResult>}
     * @memberof F2All200ResponseResults
     */
    'brukare': Array<IQueryResult>;
}
/**
 * 
 * @export
 * @interface F2Brukare200Response
 */
export interface F2Brukare200Response {
    /**
     * 
     * @type {F2Brukare200ResponseResults}
     * @memberof F2Brukare200Response
     */
    'results': F2Brukare200ResponseResults;
}
/**
 * 
 * @export
 * @interface F2Brukare200ResponseResults
 */
export interface F2Brukare200ResponseResults {
    /**
     * 
     * @type {any}
     * @memberof F2Brukare200ResponseResults
     */
    'brukare': any;
}
/**
 * 
 * @export
 * @interface F7Medarbetare200Response
 */
export interface F7Medarbetare200Response {
    /**
     * 
     * @type {F7Medarbetare200ResponseResults}
     * @memberof F7Medarbetare200Response
     */
    'results': F7Medarbetare200ResponseResults;
}
/**
 * 
 * @export
 * @interface F7Medarbetare200ResponseResults
 */
export interface F7Medarbetare200ResponseResults {
    /**
     * 
     * @type {any}
     * @memberof F7Medarbetare200ResponseResults
     */
    'medarbetare': any;
}
/**
 * 
 * @export
 * @interface FlagResult
 */
export interface FlagResult {
    /**
     * 
     * @type {string}
     * @memberof FlagResult
     */
    'flagName': string;
    /**
     * 
     * @type {number}
     * @memberof FlagResult
     */
    'numberOfFlags': number;
    /**
     * 
     * @type {boolean}
     * @memberof FlagResult
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface GetAllBrukare200ResponseInner
 */
export interface GetAllBrukare200ResponseInner {
    /**
     * 
     * @type {Array<GetAllBrukare200ResponseInnerUtfRareInner>}
     * @memberof GetAllBrukare200ResponseInner
     */
    'utförare': Array<GetAllBrukare200ResponseInnerUtfRareInner>;
}
/**
 * 
 * @export
 * @interface GetAllBrukare200ResponseInnerUtfRareInner
 */
export interface GetAllBrukare200ResponseInnerUtfRareInner {
    /**
     * 
     * @type {number}
     * @memberof GetAllBrukare200ResponseInnerUtfRareInner
     */
    'utförare_id': number;
    /**
     * 
     * @type {any}
     * @memberof GetAllBrukare200ResponseInnerUtfRareInner
     */
    'utförare_namn': any;
}
/**
 * 
 * @export
 * @interface GetAllUtforare200ResponseInner
 */
export interface GetAllUtforare200ResponseInner {
    /**
     * 
     * @type {Array<GetAllUtforare200ResponseInnerAllOfMedarbetareInner>}
     * @memberof GetAllUtforare200ResponseInner
     */
    'medarbetare': Array<GetAllUtforare200ResponseInnerAllOfMedarbetareInner>;
    /**
     * 
     * @type {Array<GetAllUtforare200ResponseInnerAllOfBrukareInner>}
     * @memberof GetAllUtforare200ResponseInner
     */
    'brukare': Array<GetAllUtforare200ResponseInnerAllOfBrukareInner>;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInner
     */
    'namn': string;
    /**
     * 
     * @type {number}
     * @memberof GetAllUtforare200ResponseInner
     */
    'brukare_count': number;
}
/**
 * 
 * @export
 * @interface GetAllUtforare200ResponseInnerAllOfBrukareInner
 */
export interface GetAllUtforare200ResponseInnerAllOfBrukareInner {
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'namn': string;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'personnummer': string;
    /**
     * 
     * @type {number}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'flagga': number;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'flaggnamn': string;
    /**
     * 
     * @type {number}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'nivå': number;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'till': string;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'från': string;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof GetAllUtforare200ResponseInnerAllOfBrukareInner
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface GetAllUtforare200ResponseInnerAllOfMedarbetareInner
 */
export interface GetAllUtforare200ResponseInnerAllOfMedarbetareInner {
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfMedarbetareInner
     */
    'namn': string;
    /**
     * 
     * @type {number}
     * @memberof GetAllUtforare200ResponseInnerAllOfMedarbetareInner
     */
    'flagga': number;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfMedarbetareInner
     */
    'flaggnamn': string;
    /**
     * 
     * @type {number}
     * @memberof GetAllUtforare200ResponseInnerAllOfMedarbetareInner
     */
    'nivå': number;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfMedarbetareInner
     */
    'till': string;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfMedarbetareInner
     */
    'från': string;
    /**
     * 
     * @type {string}
     * @memberof GetAllUtforare200ResponseInnerAllOfMedarbetareInner
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof GetAllUtforare200ResponseInnerAllOfMedarbetareInner
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface GetAverageSpecific200ResponseInner
 */
export interface GetAverageSpecific200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetAverageSpecific200ResponseInner
     */
    'level_avg': number;
    /**
     * 
     * @type {number}
     * @memberof GetAverageSpecific200ResponseInner
     */
    'avg': number;
    /**
     * 
     * @type {string}
     * @memberof GetAverageSpecific200ResponseInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GetAverageSpecific200ResponseInner
     */
    'number': number;
}
/**
 * 
 * @export
 * @interface GetCapacityInflowOutflowTrendValues200Response
 */
export interface GetCapacityInflowOutflowTrendValues200Response {
    /**
     * 
     * @type {Array<GetCapacityInflowOutflowTrendValues200ResponseBaselineValuesInner>}
     * @memberof GetCapacityInflowOutflowTrendValues200Response
     */
    'baselineValues': Array<GetCapacityInflowOutflowTrendValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetCapacityInflowOutflowTrendValues200ResponseBaselineValuesInner
 */
export interface GetCapacityInflowOutflowTrendValues200ResponseBaselineValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetCapacityInflowOutflowTrendValues200ResponseBaselineValuesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCapacityInflowOutflowTrendValues200ResponseBaselineValuesInner
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCapacityInflowOutflowTrendValues200ResponseBaselineValuesInner
     */
    'inflow'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCapacityInflowOutflowTrendValues200ResponseBaselineValuesInner
     */
    'outflow'?: number;
}
/**
 * 
 * @export
 * @interface GetCostQualityMixValues200Response
 */
export interface GetCostQualityMixValues200Response {
    /**
     * 
     * @type {Array<GetCostQualityMixValues200ResponseComparisonValuesInner>}
     * @memberof GetCostQualityMixValues200Response
     */
    'comparisonValues': Array<GetCostQualityMixValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {GetCostQualityMixValues200ResponseComparisonValuesInner}
     * @memberof GetCostQualityMixValues200Response
     */
    'baselineValues': GetCostQualityMixValues200ResponseComparisonValuesInner;
}
/**
 * 
 * @export
 * @interface GetCostQualityMixValues200ResponseComparisonValuesInner
 */
export interface GetCostQualityMixValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {number}
     * @memberof GetCostQualityMixValues200ResponseComparisonValuesInner
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCostQualityMixValues200ResponseComparisonValuesInner
     */
    'x'?: number;
}
/**
 * 
 * @export
 * @interface GetCostValues200Response
 */
export interface GetCostValues200Response {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200Response
     */
    'currentGrouping': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetCostValues200Response
     */
    'groupings': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200Response
     */
    'currentDataSource': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetCostValues200Response
     */
    'dataSources': Array<any>;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariation}
     * @memberof GetCostValues200Response
     */
    'currentVariation': GetCostValues200ResponseCurrentVariation;
    /**
     * 
     * @type {Array<GetCostValues200ResponseCurrentVariation>}
     * @memberof GetCostValues200Response
     */
    'variations': Array<GetCostValues200ResponseCurrentVariation>;
    /**
     * 
     * @type {GetCostValues200ResponseComparisonValues}
     * @memberof GetCostValues200Response
     */
    'comparisonValues': GetCostValues200ResponseComparisonValues;
    /**
     * 
     * @type {GetCostValues200ResponseComparisonValues}
     * @memberof GetCostValues200Response
     */
    'baselineValues': GetCostValues200ResponseComparisonValues;
}
/**
 * 
 * @export
 * @interface GetCostValues200ResponseComparisonValues
 */
export interface GetCostValues200ResponseComparisonValues {
    /**
     * 
     * @type {GetCostValues200ResponseComparisonValuesRest}
     * @memberof GetCostValues200ResponseComparisonValues
     */
    'rest'?: GetCostValues200ResponseComparisonValuesRest;
    /**
     * 
     * @type {GetCostValues200ResponseComparisonValuesRest}
     * @memberof GetCostValues200ResponseComparisonValues
     */
    'hemtjänst'?: GetCostValues200ResponseComparisonValuesRest;
    /**
     * 
     * @type {GetCostValues200ResponseComparisonValuesRest}
     * @memberof GetCostValues200ResponseComparisonValues
     */
    'säbo'?: GetCostValues200ResponseComparisonValuesRest;
}
/**
 * 
 * @export
 * @interface GetCostValues200ResponseComparisonValuesRest
 */
export interface GetCostValues200ResponseComparisonValuesRest {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseComparisonValuesRest
     */
    'ageGroup'?: GetCostValues200ResponseComparisonValuesRestAgeGroupEnum;
    /**
     * 
     * @type {number}
     * @memberof GetCostValues200ResponseComparisonValuesRest
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCostValues200ResponseComparisonValuesRest
     */
    'value'?: number;
}

export const GetCostValues200ResponseComparisonValuesRestAgeGroupEnum = {
    _65: '65+'
} as const;

export type GetCostValues200ResponseComparisonValuesRestAgeGroupEnum = typeof GetCostValues200ResponseComparisonValuesRestAgeGroupEnum[keyof typeof GetCostValues200ResponseComparisonValuesRestAgeGroupEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariation
 */
export interface GetCostValues200ResponseCurrentVariation {
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariationComparison}
     * @memberof GetCostValues200ResponseCurrentVariation
     */
    'comparison'?: GetCostValues200ResponseCurrentVariationComparison;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariationComparisonDatasource}
     * @memberof GetCostValues200ResponseCurrentVariation
     */
    'baseline'?: GetCostValues200ResponseCurrentVariationComparisonDatasource;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariation
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparison
 */
export interface GetCostValues200ResponseCurrentVariationComparison {
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariationComparisonGrouping}
     * @memberof GetCostValues200ResponseCurrentVariationComparison
     */
    'grouping'?: GetCostValues200ResponseCurrentVariationComparisonGrouping;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariationComparisonDatasource}
     * @memberof GetCostValues200ResponseCurrentVariationComparison
     */
    'datasource'?: GetCostValues200ResponseCurrentVariationComparisonDatasource;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparison
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonDatasource
 */
export interface GetCostValues200ResponseCurrentVariationComparisonDatasource {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasource
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasource
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonDatasourceVariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonDatasourceVariantEnum = {
    Socialstyrelsen: 'socialstyrelsen'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonDatasourceVariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceVariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceVariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf
 */
export interface GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOfVariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOfVariantEnum = {
    Kolada: 'kolada'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOfVariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOfVariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOfVariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1
 */
export interface GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1 {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1VariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1VariantEnum = {
    Scb: 'scb'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1VariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1VariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf1VariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2
 */
export interface GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2 {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2VariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2VariantEnum = {
    Statdb: 'statdb'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2VariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2VariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf2VariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3
 */
export interface GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3 {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3VariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3VariantEnum = {
    Socialstyrelsen: 'socialstyrelsen'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3VariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3VariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonDatasourceAnyOf3VariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonGrouping
 */
export interface GetCostValues200ResponseCurrentVariationComparisonGrouping {
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGrouping
     */
    'aggregation'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGrouping
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGrouping
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonGroupingVariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonGroupingVariantEnum = {
    Municipality: 'municipality'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonGroupingVariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonGroupingVariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonGroupingVariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf
 */
export interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf {
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf
     */
    'aggregation'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfVariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfVariantEnum = {
    Tag: 'tag'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfVariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfVariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfVariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1
 */
export interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1 {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1VariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1VariantEnum = {
    Municipality: 'municipality'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1VariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1VariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOf1VariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation
 */
export interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationVariantEnum;
    /**
     * 
     * @type {number}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregation
     */
    'quantifier'?: number;
}

export const GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationVariantEnum = {
    Distribution: 'distribution'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationVariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationVariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationVariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf
 */
export interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOfVariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOfVariantEnum = {
    Median: 'median'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOfVariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOfVariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOfVariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1
 */
export interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1 {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1VariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1VariantEnum = {
    Mean: 'mean'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1VariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1VariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf1VariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2
 */
export interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2 {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2
     */
    'quantifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2VariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2VariantEnum = {
    Percentile: 'percentile'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2VariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2VariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf2VariantEnum];

/**
 * 
 * @export
 * @interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3
 */
export interface GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3 {
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3
     */
    'variant'?: GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3VariantEnum;
}

export const GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3VariantEnum = {
    Distribution: 'distribution'
} as const;

export type GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3VariantEnum = typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3VariantEnum[keyof typeof GetCostValues200ResponseCurrentVariationComparisonGroupingAnyOfAggregationAnyOf3VariantEnum];

/**
 * 
 * @export
 * @interface GetGuestUsers200ResponseInner
 */
export interface GetGuestUsers200ResponseInner {
    /**
     * 
     * @type {boolean}
     * @memberof GetGuestUsers200ResponseInner
     */
    'hasUser': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGuestUsers200ResponseInner
     */
    'personNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetGuestUsers200ResponseInner
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof GetGuestUsers200ResponseInner
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface GetHomeCareHoursTrendValues200ResponseInner
 */
export interface GetHomeCareHoursTrendValues200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareHoursTrendValues200ResponseInner
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareHoursTrendValues200ResponseInner
     */
    'orderedHours': number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareHoursTrendValues200ResponseInner
     */
    'performedHours': number;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursAgeGroupValues200Response
 */
export interface GetHomeCareOrderedHoursAgeGroupValues200Response {
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetHomeCareOrderedHoursAgeGroupValues200Response
     */
    'comparisonValues': Array<GetHomeCareOrderedHoursAgeGroupValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner>}
     * @memberof GetHomeCareOrderedHoursAgeGroupValues200Response
     */
    'baselineValues': Array<GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner
 */
export interface GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner
     */
    'ageRange': string;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursAgeGroupValues200ResponseComparisonValuesInner
 */
export interface GetHomeCareOrderedHoursAgeGroupValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareOrderedHoursAgeGroupValues200ResponseComparisonValuesInner
     */
    'ageRange': string;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursAgeGroupValues200ResponseComparisonValuesInner
     */
    'mean': number;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursIntervalComparisonValues200Response
 */
export interface GetHomeCareOrderedHoursIntervalComparisonValues200Response {
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner>}
     * @memberof GetHomeCareOrderedHoursIntervalComparisonValues200Response
     */
    'comparisonValues': Array<GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner>;
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner>}
     * @memberof GetHomeCareOrderedHoursIntervalComparisonValues200Response
     */
    'baselineValues': Array<GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursIntervalValues200Response
 */
export interface GetHomeCareOrderedHoursIntervalValues200Response {
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursIntervalValues200ResponseComparisonValuesInner>}
     * @memberof GetHomeCareOrderedHoursIntervalValues200Response
     */
    'comparisonValues': Array<GetHomeCareOrderedHoursIntervalValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner>}
     * @memberof GetHomeCareOrderedHoursIntervalValues200Response
     */
    'baselineValues': Array<GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner
 */
export interface GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner
     */
    'interval': string;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursIntervalValues200ResponseBaselineValuesInner
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursIntervalValues200ResponseComparisonValuesInner
 */
export interface GetHomeCareOrderedHoursIntervalValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareOrderedHoursIntervalValues200ResponseComparisonValuesInner
     */
    'interval': string;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursIntervalValues200ResponseComparisonValuesInner
     */
    'mean': number;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursPerPersonValues200Response
 */
export interface GetHomeCareOrderedHoursPerPersonValues200Response {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200Response
     */
    'currentGrouping': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200Response
     */
    'groupings': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200Response
     */
    'currentDataSource': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200Response
     */
    'dataSources': Array<any>;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariation}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200Response
     */
    'currentVariation': GetCostValues200ResponseCurrentVariation;
    /**
     * 
     * @type {Array<GetCostValues200ResponseCurrentVariation>}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200Response
     */
    'variations': Array<GetCostValues200ResponseCurrentVariation>;
    /**
     * 
     * @type {GetHomeCareOrderedHoursPerPersonValues200ResponseComparisonValues}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200Response
     */
    'comparisonValues': GetHomeCareOrderedHoursPerPersonValues200ResponseComparisonValues;
    /**
     * 
     * @type {GetHomeCareOrderedHoursValues200ResponseBaselineValues}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200Response
     */
    'baselineValues': GetHomeCareOrderedHoursValues200ResponseBaselineValues;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursPerPersonValues200ResponseComparisonValues
 */
export interface GetHomeCareOrderedHoursPerPersonValues200ResponseComparisonValues {
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200ResponseComparisonValues
     */
    'quartile3'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200ResponseComparisonValues
     */
    'median'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursPerPersonValues200ResponseComparisonValues
     */
    'quartile1'?: number;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursTrendMixedValues200Response
 */
export interface GetHomeCareOrderedHoursTrendMixedValues200Response {
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner>}
     * @memberof GetHomeCareOrderedHoursTrendMixedValues200Response
     */
    'comparisonValues': Array<GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursTrendMixedValues200ResponseBaselineValuesInner>}
     * @memberof GetHomeCareOrderedHoursTrendMixedValues200Response
     */
    'baselineValues': Array<GetHomeCareOrderedHoursTrendMixedValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursTrendMixedValues200ResponseBaselineValuesInner
 */
export interface GetHomeCareOrderedHoursTrendMixedValues200ResponseBaselineValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareOrderedHoursTrendMixedValues200ResponseBaselineValuesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursTrendMixedValues200ResponseBaselineValuesInner
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner
 */
export interface GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner
     */
    'quartile3'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner
     */
    'quartile1'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner
     */
    'median'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareOrderedHoursTrendMixedValues200ResponseComparisonValuesInner
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursValues200Response
 */
export interface GetHomeCareOrderedHoursValues200Response {
    /**
     * 
     * @type {GetHomeCareOrderedHoursValues200ResponseComparisonValues}
     * @memberof GetHomeCareOrderedHoursValues200Response
     */
    'comparisonValues': GetHomeCareOrderedHoursValues200ResponseComparisonValues;
    /**
     * 
     * @type {GetHomeCareOrderedHoursValues200ResponseBaselineValues}
     * @memberof GetHomeCareOrderedHoursValues200Response
     */
    'baselineValues': GetHomeCareOrderedHoursValues200ResponseBaselineValues;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursValues200ResponseBaselineValues
 */
export interface GetHomeCareOrderedHoursValues200ResponseBaselineValues {
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursValues200ResponseBaselineValues
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface GetHomeCareOrderedHoursValues200ResponseComparisonValues
 */
export interface GetHomeCareOrderedHoursValues200ResponseComparisonValues {
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursValues200ResponseComparisonValues
     */
    'quartile3': number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursValues200ResponseComparisonValues
     */
    'quartile1': number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareOrderedHoursValues200ResponseComparisonValues
     */
    'median': number;
}
/**
 * 
 * @export
 * @interface GetHomeCarePerformedHoursValues200Response
 */
export interface GetHomeCarePerformedHoursValues200Response {
    /**
     * 
     * @type {GetHomeCarePerformedHoursValues200ResponseComparisonValues}
     * @memberof GetHomeCarePerformedHoursValues200Response
     */
    'comparisonValues': GetHomeCarePerformedHoursValues200ResponseComparisonValues;
    /**
     * 
     * @type {GetHomeCareOrderedHoursValues200ResponseBaselineValues}
     * @memberof GetHomeCarePerformedHoursValues200Response
     */
    'baselineValues': GetHomeCareOrderedHoursValues200ResponseBaselineValues;
}
/**
 * 
 * @export
 * @interface GetHomeCarePerformedHoursValues200ResponseComparisonValues
 */
export interface GetHomeCarePerformedHoursValues200ResponseComparisonValues {
    /**
     * 
     * @type {number}
     * @memberof GetHomeCarePerformedHoursValues200ResponseComparisonValues
     */
    'quartile3'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCarePerformedHoursValues200ResponseComparisonValues
     */
    'quartile1'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCarePerformedHoursValues200ResponseComparisonValues
     */
    'median'?: number;
}
/**
 * 
 * @export
 * @interface GetHomeCareProportionAgeGroupValues200Response
 */
export interface GetHomeCareProportionAgeGroupValues200Response {
    /**
     * 
     * @type {Array<GetHomeCareProportionAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetHomeCareProportionAgeGroupValues200Response
     */
    'comparisonValues': Array<GetHomeCareProportionAgeGroupValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner>}
     * @memberof GetHomeCareProportionAgeGroupValues200Response
     */
    'baselineValues': Array<GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetHomeCareProportionAgeGroupValues200ResponseComparisonValuesInner
 */
export interface GetHomeCareProportionAgeGroupValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareProportionAgeGroupValues200ResponseComparisonValuesInner
     */
    'ageRange': string;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareProportionAgeGroupValues200ResponseComparisonValuesInner
     */
    'quartile3': number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareProportionAgeGroupValues200ResponseComparisonValuesInner
     */
    'quartile1': number;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareProportionAgeGroupValues200ResponseComparisonValuesInner
     */
    'median': number;
}
/**
 * 
 * @export
 * @interface GetHomeCareVolumeAgeGroupTrendValues200ResponseInner
 */
export interface GetHomeCareVolumeAgeGroupTrendValues200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareVolumeAgeGroupTrendValues200ResponseInner
     */
    'ageRange': string;
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursTrendMixedValues200ResponseBaselineValuesInner>}
     * @memberof GetHomeCareVolumeAgeGroupTrendValues200ResponseInner
     */
    'values': Array<GetHomeCareOrderedHoursTrendMixedValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetHomeCareVolumeAgeGroupValues200Response
 */
export interface GetHomeCareVolumeAgeGroupValues200Response {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareVolumeAgeGroupValues200Response
     */
    'currentGrouping': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetHomeCareVolumeAgeGroupValues200Response
     */
    'groupings': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareVolumeAgeGroupValues200Response
     */
    'currentDataSource': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetHomeCareVolumeAgeGroupValues200Response
     */
    'dataSources': Array<any>;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariation}
     * @memberof GetHomeCareVolumeAgeGroupValues200Response
     */
    'currentVariation': GetCostValues200ResponseCurrentVariation;
    /**
     * 
     * @type {Array<GetCostValues200ResponseCurrentVariation>}
     * @memberof GetHomeCareVolumeAgeGroupValues200Response
     */
    'variations': Array<GetCostValues200ResponseCurrentVariation>;
    /**
     * 
     * @type {Array<GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetHomeCareVolumeAgeGroupValues200Response
     */
    'comparisonValues': Array<GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetHomeCareVolumeAgeGroupValues200Response
     */
    'baselineValues': Array<GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner>;
}
/**
 * 
 * @export
 * @interface GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner
 */
export interface GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner
     */
    'ageRange'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface GetInflowAgeGroupValues200Response
 */
export interface GetInflowAgeGroupValues200Response {
    /**
     * 
     * @type {Array<GetInflowAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetInflowAgeGroupValues200Response
     */
    'comparisonValues': Array<GetInflowAgeGroupValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner>}
     * @memberof GetInflowAgeGroupValues200Response
     */
    'baselineValues': Array<GetHomeCareOrderedHoursAgeGroupValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetInflowAgeGroupValues200ResponseComparisonValuesInner
 */
export interface GetInflowAgeGroupValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInflowAgeGroupValues200ResponseComparisonValuesInner
     */
    'ageRange': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetInflowAgeGroupValues200ResponseComparisonValuesInner
     */
    'mean': Array<number>;
}
/**
 * 
 * @export
 * @interface GetInflowProportionAgeGroupTrendValues200ResponseInner
 */
export interface GetInflowProportionAgeGroupTrendValues200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetInflowProportionAgeGroupTrendValues200ResponseInner
     */
    'ageRange': string;
    /**
     * 
     * @type {Array<GetInflowProportionAgeGroupTrendValues200ResponseInnerValuesInner>}
     * @memberof GetInflowProportionAgeGroupTrendValues200ResponseInner
     */
    'values': Array<GetInflowProportionAgeGroupTrendValues200ResponseInnerValuesInner>;
}
/**
 * 
 * @export
 * @interface GetInflowProportionAgeGroupTrendValues200ResponseInnerValuesInner
 */
export interface GetInflowProportionAgeGroupTrendValues200ResponseInnerValuesInner {
    /**
     * 
     * @type {number}
     * @memberof GetInflowProportionAgeGroupTrendValues200ResponseInnerValuesInner
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetInflowProportionAgeGroupTrendValues200ResponseInnerValuesInner
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface GetLifespans200Response
 */
export interface GetLifespans200Response {
    /**
     * 
     * @type {any}
     * @memberof GetLifespans200Response
     */
    'refresh_token': any;
    /**
     * 
     * @type {any}
     * @memberof GetLifespans200Response
     */
    'access_token': any;
}
/**
 * 
 * @export
 * @interface GetMaxAuthDuration200Response
 */
export interface GetMaxAuthDuration200Response {
    /**
     * 
     * @type {number}
     * @memberof GetMaxAuthDuration200Response
     */
    'durationS': number;
}
/**
 * 
 * @export
 * @interface GetMonthlyVolume2Other200ResponseInner
 */
export interface GetMonthlyVolume2Other200ResponseInner {
    /**
     * 
     * @type {Array<GetMonthlyVolume2Other200ResponseInnerDataInner>}
     * @memberof GetMonthlyVolume2Other200ResponseInner
     */
    'data': Array<GetMonthlyVolume2Other200ResponseInnerDataInner>;
    /**
     * 
     * @type {number}
     * @memberof GetMonthlyVolume2Other200ResponseInner
     */
    'beräknad_omfattning_månad': number;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyVolume2Other200ResponseInner
     */
    'till': string;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyVolume2Other200ResponseInner
     */
    'från': string;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyVolume2Other200ResponseInner
     */
    'brukare_personnummer': string;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyVolume2Other200ResponseInner
     */
    'brukare_namn': string;
    /**
     * 
     * @type {number}
     * @memberof GetMonthlyVolume2Other200ResponseInner
     */
    'beslutsid': number;
}
/**
 * 
 * @export
 * @interface GetMonthlyVolume2Other200ResponseInnerDataInner
 */
export interface GetMonthlyVolume2Other200ResponseInnerDataInner {
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyVolume2Other200ResponseInnerDataInner
     */
    'till_exakt': string;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyVolume2Other200ResponseInnerDataInner
     */
    'från_exakt': string;
    /**
     * 
     * @type {number}
     * @memberof GetMonthlyVolume2Other200ResponseInnerDataInner
     */
    'beräknad_omfattning_exakt': number;
    /**
     * 
     * @type {number}
     * @memberof GetMonthlyVolume2Other200ResponseInnerDataInner
     */
    'utförd_tid': number;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyVolume2Other200ResponseInnerDataInner
     */
    'ym': string;
}
/**
 * 
 * @export
 * @interface GetMunicipalities200ResponseInner
 */
export interface GetMunicipalities200ResponseInner {
    /**
     * 
     * @type {any}
     * @memberof GetMunicipalities200ResponseInner
     */
    'koladaId': any;
    /**
     * 
     * @type {any}
     * @memberof GetMunicipalities200ResponseInner
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof GetMunicipalities200ResponseInner
     */
    'id': any;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaProportionValues200Response
 */
export interface GetNursingDementiaProportionValues200Response {
    /**
     * 
     * @type {GetNursingDementiaProportionValues200ResponseComparisonValues}
     * @memberof GetNursingDementiaProportionValues200Response
     */
    'comparisonValues': GetNursingDementiaProportionValues200ResponseComparisonValues;
    /**
     * 
     * @type {GetNursingDementiaProportionValues200ResponseBaselineValues}
     * @memberof GetNursingDementiaProportionValues200Response
     */
    'baselineValues': GetNursingDementiaProportionValues200ResponseBaselineValues;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaProportionValues200ResponseBaselineValues
 */
export interface GetNursingDementiaProportionValues200ResponseBaselineValues {
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaProportionValues200ResponseBaselineValues
     */
    'omvårdnad'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaProportionValues200ResponseBaselineValues
     */
    'demens'?: number;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaProportionValues200ResponseComparisonValues
 */
export interface GetNursingDementiaProportionValues200ResponseComparisonValues {
    /**
     * 
     * @type {GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3}
     * @memberof GetNursingDementiaProportionValues200ResponseComparisonValues
     */
    'quartile3': GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3;
    /**
     * 
     * @type {GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3}
     * @memberof GetNursingDementiaProportionValues200ResponseComparisonValues
     */
    'median': GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3;
    /**
     * 
     * @type {GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3}
     * @memberof GetNursingDementiaProportionValues200ResponseComparisonValues
     */
    'quartile1': GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3
 */
export interface GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3 {
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3
     */
    'omvårdnad': number;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaProportionValues200ResponseComparisonValuesQuartile3
     */
    'demens': number;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaTotalStayLengthValues200Response
 */
export interface GetNursingDementiaTotalStayLengthValues200Response {
    /**
     * 
     * @type {GetNursingDementiaTotalStayLengthValues200ResponseComparisonValues}
     * @memberof GetNursingDementiaTotalStayLengthValues200Response
     */
    'comparisonValues': GetNursingDementiaTotalStayLengthValues200ResponseComparisonValues;
    /**
     * 
     * @type {GetNursingDementiaTotalStayLengthValues200ResponseBaselineValues}
     * @memberof GetNursingDementiaTotalStayLengthValues200Response
     */
    'baselineValues': GetNursingDementiaTotalStayLengthValues200ResponseBaselineValues;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaTotalStayLengthValues200ResponseBaselineValues
 */
export interface GetNursingDementiaTotalStayLengthValues200ResponseBaselineValues {
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseBaselineValues
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseBaselineValues
     */
    'dementia'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseBaselineValues
     */
    'nursing'?: number;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaTotalStayLengthValues200ResponseComparisonValues
 */
export interface GetNursingDementiaTotalStayLengthValues200ResponseComparisonValues {
    /**
     * 
     * @type {GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseComparisonValues
     */
    'quartile3': GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3;
    /**
     * 
     * @type {GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseComparisonValues
     */
    'quartile1': GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3;
    /**
     * 
     * @type {GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseComparisonValues
     */
    'median': GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3
 */
export interface GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3 {
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3
     */
    'dementia': number;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalStayLengthValues200ResponseComparisonValuesQuartile3
     */
    'nursing': number;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaTotalTrendValues200Response
 */
export interface GetNursingDementiaTotalTrendValues200Response {
    /**
     * 
     * @type {Array<GetNursingDementiaTotalTrendValues200ResponseBaselineValuesInner>}
     * @memberof GetNursingDementiaTotalTrendValues200Response
     */
    'baselineValues': Array<GetNursingDementiaTotalTrendValues200ResponseBaselineValuesInner>;
}
/**
 * 
 * @export
 * @interface GetNursingDementiaTotalTrendValues200ResponseBaselineValuesInner
 */
export interface GetNursingDementiaTotalTrendValues200ResponseBaselineValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetNursingDementiaTotalTrendValues200ResponseBaselineValuesInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalTrendValues200ResponseBaselineValuesInner
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalTrendValues200ResponseBaselineValuesInner
     */
    'omvårdnad'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetNursingDementiaTotalTrendValues200ResponseBaselineValuesInner
     */
    'demens'?: number;
}
/**
 * 
 * @export
 * @interface GetResidencyCapacityAgeGroupTrendValues200Response
 */
export interface GetResidencyCapacityAgeGroupTrendValues200Response {
    /**
     * 
     * @type {string}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200Response
     */
    'currentGrouping': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200Response
     */
    'groupings': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200Response
     */
    'currentDataSource': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200Response
     */
    'dataSources': Array<any>;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariation}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200Response
     */
    'currentVariation': GetCostValues200ResponseCurrentVariation;
    /**
     * 
     * @type {Array<GetCostValues200ResponseCurrentVariation>}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200Response
     */
    'variations': Array<GetCostValues200ResponseCurrentVariation>;
    /**
     * 
     * @type {Array<GetResidencyCapacityAgeGroupTrendValues200ResponseComparisonValuesInner>}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200Response
     */
    'comparisonValues': Array<GetResidencyCapacityAgeGroupTrendValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetResidencyCapacityAgeGroupTrendValues200ResponseComparisonValuesInner>}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200Response
     */
    'baselineValues': Array<GetResidencyCapacityAgeGroupTrendValues200ResponseComparisonValuesInner>;
}
/**
 * 
 * @export
 * @interface GetResidencyCapacityAgeGroupTrendValues200ResponseComparisonValuesInner
 */
export interface GetResidencyCapacityAgeGroupTrendValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {Array<GetInflowProportionAgeGroupTrendValues200ResponseInnerValuesInner>}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200ResponseComparisonValuesInner
     */
    'values'?: Array<GetInflowProportionAgeGroupTrendValues200ResponseInnerValuesInner>;
    /**
     * 
     * @type {string}
     * @memberof GetResidencyCapacityAgeGroupTrendValues200ResponseComparisonValuesInner
     */
    'ageRange'?: string;
}
/**
 * 
 * @export
 * @interface GetRoles200ResponseInner
 */
export interface GetRoles200ResponseInner {
    /**
     * 
     * @type {Array<GetRoles200ResponseInnerAssignableRolesInner>}
     * @memberof GetRoles200ResponseInner
     */
    'assignableRoles': Array<GetRoles200ResponseInnerAssignableRolesInner>;
    /**
     * 
     * @type {Array<GetRoles200ResponseInnerAssignableRolesInner>}
     * @memberof GetRoles200ResponseInner
     */
    'permissions': Array<GetRoles200ResponseInnerAssignableRolesInner>;
    /**
     * 
     * @type {Array<GetRoles200ResponseInnerOverridesInner>}
     * @memberof GetRoles200ResponseInner
     */
    'overrides': Array<GetRoles200ResponseInnerOverridesInner>;
    /**
     * 
     * @type {Array<GetRoles200ResponseInnerAssignableRolesInner>}
     * @memberof GetRoles200ResponseInner
     */
    'modules': Array<GetRoles200ResponseInnerAssignableRolesInner>;
    /**
     * 
     * @type {string}
     * @memberof GetRoles200ResponseInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof GetRoles200ResponseInner
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface GetRoles200ResponseInnerAssignableRolesInner
 */
export interface GetRoles200ResponseInnerAssignableRolesInner {
    /**
     * 
     * @type {string}
     * @memberof GetRoles200ResponseInnerAssignableRolesInner
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRoles200ResponseInnerAssignableRolesInner
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface GetRoles200ResponseInnerOverridesInner
 */
export interface GetRoles200ResponseInnerOverridesInner {
    /**
     * 
     * @type {string}
     * @memberof GetRoles200ResponseInnerOverridesInner
     */
    'action'?: GetRoles200ResponseInnerOverridesInnerActionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetRoles200ResponseInnerOverridesInner
     */
    'permissionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRoles200ResponseInnerOverridesInner
     */
    'permissionId'?: number;
}

export const GetRoles200ResponseInnerOverridesInnerActionEnum = {
    Add: 'add',
    Remove: 'remove'
} as const;

export type GetRoles200ResponseInnerOverridesInnerActionEnum = typeof GetRoles200ResponseInnerOverridesInnerActionEnum[keyof typeof GetRoles200ResponseInnerOverridesInnerActionEnum];

/**
 * 
 * @export
 * @interface GetStayLengthTrendValues200ResponseInner
 */
export interface GetStayLengthTrendValues200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetStayLengthTrendValues200ResponseInner
     */
    'median'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStayLengthTrendValues200ResponseInner
     */
    'mean'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetStayLengthTrendValues200ResponseInner
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface GetSupportedBenchmarks200Response
 */
export interface GetSupportedBenchmarks200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'volumeQualityMix': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'nursingDementiaTotalTrend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareOrderedHoursTrendMixed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'volumeProportionAgeGroup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'nursingDementiaTotalStayLength': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareOrderedHoursProportionInterval': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'volumeInsatsMix': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'nursingDementiaProportion': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareOrderedHoursIntervalComparison': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'volumeAgeGroup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'inflowProportionAgeGroupTrend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareOrderedHoursInterval': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'volume': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'inflowAgeGroup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareOrderedHoursAgeGroup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'dementiaStayLengthTrend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'nursingStayLengthTrend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'totalStayLengthTrend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareVolumeAgeGroupTrend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareOrderedHours': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'residencyProportionAgeGroup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareVolumeAgeGroup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareHoursTrend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'residencyCapacityAgeGroupTrend': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCareProportionAgeGroup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'costQualityMix': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'residencyCapacityAgeGroup': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCarePerformedHoursTrendMixed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'cost': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'population': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'homeCarePerformedHours': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportedBenchmarks200Response
     */
    'capacityInflowOutflowTrend': boolean;
}
/**
 * 
 * @export
 * @interface GetTextForFlag10200Response
 */
export interface GetTextForFlag10200Response {
    /**
     * 
     * @type {any}
     * @memberof GetTextForFlag10200Response
     */
    'info': any;
    /**
     * 
     * @type {any}
     * @memberof GetTextForFlag10200Response
     */
    'description': any;
}
/**
 * 
 * @export
 * @interface GetTextForFlag17200Response
 */
export interface GetTextForFlag17200Response {
    /**
     * 
     * @type {object}
     * @memberof GetTextForFlag17200Response
     */
    'info': object;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag17200Response
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag17200ResponseAnyOf
 */
export interface GetTextForFlag17200ResponseAnyOf {
    /**
     * 
     * @type {any}
     * @memberof GetTextForFlag17200ResponseAnyOf
     */
    'info': any;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag17200ResponseAnyOf
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag18200Response
 */
export interface GetTextForFlag18200Response {
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof GetTextForFlag18200Response
     */
    'tableData': Array<Array<string>>;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag18200Response
     */
    'info': string;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag18200Response
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag2200Response
 */
export interface GetTextForFlag2200Response {
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag2200Response
     */
    'info': string;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag2200Response
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag7200Response
 */
export interface GetTextForFlag7200Response {
    /**
     * 
     * @type {object}
     * @memberof GetTextForFlag7200Response
     */
    'brukare': object;
    /**
     * 
     * @type {object}
     * @memberof GetTextForFlag7200Response
     */
    'info': object;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag7200Response
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag7200ResponseAnyOf
 */
export interface GetTextForFlag7200ResponseAnyOf {
    /**
     * 
     * @type {Array<GetTextForFlag7200ResponseAnyOfBrukareInner>}
     * @memberof GetTextForFlag7200ResponseAnyOf
     */
    'brukare': Array<GetTextForFlag7200ResponseAnyOfBrukareInner>;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag7200ResponseAnyOf
     */
    'info': string;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag7200ResponseAnyOf
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag7200ResponseAnyOf1
 */
export interface GetTextForFlag7200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof GetTextForFlag7200ResponseAnyOf1
     */
    'brukare'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetTextForFlag7200ResponseAnyOf1
     */
    'info': any;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag7200ResponseAnyOf1
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag7200ResponseAnyOfBrukareInner
 */
export interface GetTextForFlag7200ResponseAnyOfBrukareInner {
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag7200ResponseAnyOfBrukareInner
     */
    'efternamn': string;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag7200ResponseAnyOfBrukareInner
     */
    'förnamn': string;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag7200ResponseAnyOfBrukareInner
     */
    'personnummer': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag8200Response
 */
export interface GetTextForFlag8200Response {
    /**
     * 
     * @type {object}
     * @memberof GetTextForFlag8200Response
     */
    'personal': object;
    /**
     * 
     * @type {object}
     * @memberof GetTextForFlag8200Response
     */
    'info': object;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag8200Response
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag8200ResponseAnyOf
 */
export interface GetTextForFlag8200ResponseAnyOf {
    /**
     * 
     * @type {Array<GetTextForFlag8200ResponseAnyOfPersonalInner>}
     * @memberof GetTextForFlag8200ResponseAnyOf
     */
    'personal': Array<GetTextForFlag8200ResponseAnyOfPersonalInner>;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag8200ResponseAnyOf
     */
    'info': string;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag8200ResponseAnyOf
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag8200ResponseAnyOf1
 */
export interface GetTextForFlag8200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof GetTextForFlag8200ResponseAnyOf1
     */
    'personal'?: any;
    /**
     * 
     * @type {any}
     * @memberof GetTextForFlag8200ResponseAnyOf1
     */
    'info': any;
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag8200ResponseAnyOf1
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GetTextForFlag8200ResponseAnyOfPersonalInner
 */
export interface GetTextForFlag8200ResponseAnyOfPersonalInner {
    /**
     * 
     * @type {string}
     * @memberof GetTextForFlag8200ResponseAnyOfPersonalInner
     */
    'namn': string;
    /**
     * 
     * @type {number}
     * @memberof GetTextForFlag8200ResponseAnyOfPersonalInner
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface GetVolumeAgeGroupValues200Response
 */
export interface GetVolumeAgeGroupValues200Response {
    /**
     * 
     * @type {string}
     * @memberof GetVolumeAgeGroupValues200Response
     */
    'currentGrouping': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetVolumeAgeGroupValues200Response
     */
    'groupings': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetVolumeAgeGroupValues200Response
     */
    'currentDataSource': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetVolumeAgeGroupValues200Response
     */
    'dataSources': Array<any>;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariation}
     * @memberof GetVolumeAgeGroupValues200Response
     */
    'currentVariation': GetCostValues200ResponseCurrentVariation;
    /**
     * 
     * @type {Array<GetCostValues200ResponseCurrentVariation>}
     * @memberof GetVolumeAgeGroupValues200Response
     */
    'variations': Array<GetCostValues200ResponseCurrentVariation>;
    /**
     * 
     * @type {Array<GetVolumeAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetVolumeAgeGroupValues200Response
     */
    'comparisonValues': Array<GetVolumeAgeGroupValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetVolumeAgeGroupValues200Response
     */
    'baselineValues': Array<GetHomeCareVolumeAgeGroupValues200ResponseComparisonValuesInner>;
}
/**
 * 
 * @export
 * @interface GetVolumeAgeGroupValues200ResponseComparisonValuesInner
 */
export interface GetVolumeAgeGroupValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetVolumeAgeGroupValues200ResponseComparisonValuesInner
     */
    'ageRange'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVolumeAgeGroupValues200ResponseComparisonValuesInner
     */
    'quartile3'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVolumeAgeGroupValues200ResponseComparisonValuesInner
     */
    'quartile1'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVolumeAgeGroupValues200ResponseComparisonValuesInner
     */
    'median'?: number;
}
/**
 * 
 * @export
 * @interface GetVolumeProportionAgeGroupValues200Response
 */
export interface GetVolumeProportionAgeGroupValues200Response {
    /**
     * 
     * @type {string}
     * @memberof GetVolumeProportionAgeGroupValues200Response
     */
    'currentGrouping': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetVolumeProportionAgeGroupValues200Response
     */
    'groupings': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetVolumeProportionAgeGroupValues200Response
     */
    'currentDataSource': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetVolumeProportionAgeGroupValues200Response
     */
    'dataSources': Array<any>;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariation}
     * @memberof GetVolumeProportionAgeGroupValues200Response
     */
    'currentVariation': GetCostValues200ResponseCurrentVariation;
    /**
     * 
     * @type {Array<GetCostValues200ResponseCurrentVariation>}
     * @memberof GetVolumeProportionAgeGroupValues200Response
     */
    'variations': Array<GetCostValues200ResponseCurrentVariation>;
    /**
     * 
     * @type {Array<GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetVolumeProportionAgeGroupValues200Response
     */
    'comparisonValues': Array<GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner>;
    /**
     * 
     * @type {Array<GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner>}
     * @memberof GetVolumeProportionAgeGroupValues200Response
     */
    'baselineValues': Array<GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner>;
}
/**
 * 
 * @export
 * @interface GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner
 */
export interface GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner {
    /**
     * 
     * @type {string}
     * @memberof GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner
     */
    'ageRange'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner
     */
    'hemtjänst'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVolumeProportionAgeGroupValues200ResponseComparisonValuesInner
     */
    'säbo'?: number;
}
/**
 * 
 * @export
 * @interface GetVolumeValues200Response
 */
export interface GetVolumeValues200Response {
    /**
     * 
     * @type {string}
     * @memberof GetVolumeValues200Response
     */
    'currentGrouping': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetVolumeValues200Response
     */
    'groupings': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof GetVolumeValues200Response
     */
    'currentDataSource': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetVolumeValues200Response
     */
    'dataSources': Array<any>;
    /**
     * 
     * @type {GetCostValues200ResponseCurrentVariation}
     * @memberof GetVolumeValues200Response
     */
    'currentVariation': GetCostValues200ResponseCurrentVariation;
    /**
     * 
     * @type {Array<GetCostValues200ResponseCurrentVariation>}
     * @memberof GetVolumeValues200Response
     */
    'variations': Array<GetCostValues200ResponseCurrentVariation>;
    /**
     * 
     * @type {GetVolumeValues200ResponseComparisonValues}
     * @memberof GetVolumeValues200Response
     */
    'comparisonValues': GetVolumeValues200ResponseComparisonValues;
    /**
     * 
     * @type {GetVolumeValues200ResponseComparisonValues}
     * @memberof GetVolumeValues200Response
     */
    'baselineValues': GetVolumeValues200ResponseComparisonValues;
}
/**
 * 
 * @export
 * @interface GetVolumeValues200ResponseComparisonValues
 */
export interface GetVolumeValues200ResponseComparisonValues {
    /**
     * 
     * @type {GetHomeCareOrderedHoursValues200ResponseBaselineValues}
     * @memberof GetVolumeValues200ResponseComparisonValues
     */
    'hemtjänst'?: GetHomeCareOrderedHoursValues200ResponseBaselineValues;
    /**
     * 
     * @type {GetHomeCareOrderedHoursValues200ResponseBaselineValues}
     * @memberof GetVolumeValues200ResponseComparisonValues
     */
    'säbo'?: GetHomeCareOrderedHoursValues200ResponseBaselineValues;
}
/**
 * 
 * @export
 * @interface Guest200Response
 */
export interface Guest200Response {
    /**
     * 
     * @type {any}
     * @memberof Guest200Response
     */
    'redirect_to': any;
}
/**
 * 
 * @export
 * @interface GuestRequest
 */
export interface GuestRequest {
    /**
     * 
     * @type {string}
     * @memberof GuestRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof GuestRequest
     */
    'login_challenge': string;
    /**
     * 
     * @type {string}
     * @memberof GuestRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface IAvdelning
 */
export interface IAvdelning {
    /**
     * 
     * @type {number}
     * @memberof IAvdelning
     */
    'avdelning_id': number;
    /**
     * 
     * @type {string}
     * @memberof IAvdelning
     */
    'avdelning_namn': string;
}
/**
 * 
 * @export
 * @interface IBaseFlagQueryResponse
 */
export interface IBaseFlagQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof IBaseFlagQueryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof IBaseFlagQueryResponse
     */
    'flagga': number;
    /**
     * 
     * @type {string}
     * @memberof IBaseFlagQueryResponse
     */
    'från': string;
    /**
     * 
     * @type {string}
     * @memberof IBaseFlagQueryResponse
     */
    'till': string;
    /**
     * 
     * @type {number}
     * @memberof IBaseFlagQueryResponse
     */
    'nivå': number;
}
/**
 * 
 * @export
 * @interface IBeslutObject
 */
export interface IBeslutObject {
    /**
     * 
     * @type {string}
     * @memberof IBeslutObject
     */
    'giltigt_till': string;
    /**
     * 
     * @type {string}
     * @memberof IBeslutObject
     */
    'giltigt_från': string;
    /**
     * 
     * @type {number}
     * @memberof IBeslutObject
     */
    'beslut_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof IBeslutObject
     */
    'förlängning': boolean;
}
/**
 * 
 * @export
 * @interface IBrukareQueryResult
 */
export interface IBrukareQueryResult {
    /**
     * 
     * @type {F2All200ResponseResults}
     * @memberof IBrukareQueryResult
     */
    'results': F2All200ResponseResults;
}
/**
 * 
 * @export
 * @interface ICaseData
 */
export interface ICaseData {
    /**
     * 
     * @type {number}
     * @memberof ICaseData
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof ICaseData
     */
    'visible': boolean;
    /**
     * 
     * @type {IHandlaggare}
     * @memberof ICaseData
     */
    'ägare': IHandlaggare;
    /**
     * 
     * @type {Array<IHandlaggare>}
     * @memberof ICaseData
     */
    'handläggare': Array<IHandlaggare>;
    /**
     * 
     * @type {Array<IFullFlagData>}
     * @memberof ICaseData
     */
    'flags': Array<IFullFlagData>;
    /**
     * 
     * @type {Array<IHandelse>}
     * @memberof ICaseData
     */
    'händelser': Array<IHandelse>;
    /**
     * 
     * @type {string}
     * @memberof ICaseData
     */
    'senastUppdaterad': string;
    /**
     * 
     * @type {string}
     * @memberof ICaseData
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ICaseData
     */
    'status': string;
    /**
     * 
     * @type {Array<ITeam>}
     * @memberof ICaseData
     */
    'teams': Array<ITeam>;
    /**
     * 
     * @type {Array<IAvdelning>}
     * @memberof ICaseData
     */
    'avdelningar': Array<IAvdelning>;
}
/**
 * 
 * @export
 * @interface ICommentData
 */
export interface ICommentData {
    /**
     * 
     * @type {number}
     * @memberof ICommentData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ICommentData
     */
    'createdBy': number;
    /**
     * 
     * @type {string}
     * @memberof ICommentData
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface ICreateCaseData
 */
export interface ICreateCaseData {
    /**
     * 
     * @type {string}
     * @memberof ICreateCaseData
     */
    'id_token': string;
    /**
     * 
     * @type {boolean}
     * @memberof ICreateCaseData
     */
    'visible': boolean;
    /**
     * 
     * @type {Array<IFlagData>}
     * @memberof ICreateCaseData
     */
    'flags': Array<IFlagData>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ICreateCaseData
     */
    'handläggare': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ICreateCaseData
     */
    'team': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ICreateCaseData
     */
    'avdelning': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ICreateCaseData
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface ICreateHandlaggareData
 */
export interface ICreateHandlaggareData {
    /**
     * 
     * @type {string}
     * @memberof ICreateHandlaggareData
     */
    'namn': string;
    /**
     * 
     * @type {string}
     * @memberof ICreateHandlaggareData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ICreateHandlaggareData
     */
    'personnummer': string;
    /**
     * 
     * @type {number}
     * @memberof ICreateHandlaggareData
     */
    'team': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ICreateHandlaggareData
     */
    'scope': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ICreateHandlaggareData
     */
    'role': string;
    /**
     * 
     * @type {boolean}
     * @memberof ICreateHandlaggareData
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ICreateHandlaggareData
     */
    'in_structure': boolean;
}
/**
 * 
 * @export
 * @interface IFlagData
 */
export interface IFlagData {
    /**
     * 
     * @type {number}
     * @memberof IFlagData
     */
    'flagId': number;
    /**
     * 
     * @type {number}
     * @memberof IFlagData
     */
    'flagType': number;
}
/**
 * 
 * @export
 * @interface IFlagResult
 */
export interface IFlagResult {
    /**
     * 
     * @type {string}
     * @memberof IFlagResult
     */
    'medarbetare': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IFlagResult
     */
    'utförare': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IFlagResult
     */
    'från': string;
    /**
     * 
     * @type {string}
     * @memberof IFlagResult
     */
    'till': string;
    /**
     * 
     * @type {Array<ISubFlag>}
     * @memberof IFlagResult
     */
    'flags': Array<ISubFlag>;
}
/**
 * 
 * @export
 * @interface IFormattedData
 */
export interface IFormattedData {
    /**
     * 
     * @type {string}
     * @memberof IFormattedData
     */
    'personnummer': string;
    /**
     * 
     * @type {string}
     * @memberof IFormattedData
     */
    'namn': string;
    /**
     * 
     * @type {string}
     * @memberof IFormattedData
     */
    'ansvarig_person': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IFormattedData
     */
    'utförare_dagverksamhet': Array<string>;
    /**
     * 
     * @type {Array<IBeslutObject>}
     * @memberof IFormattedData
     */
    'dagverksamhet': Array<IBeslutObject>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IFormattedData
     */
    'utförare_trygghetslarm': Array<string>;
    /**
     * 
     * @type {Array<IBeslutObject>}
     * @memberof IFormattedData
     */
    'trygghetslarm': Array<IBeslutObject>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IFormattedData
     */
    'utförare_korttidsboende': Array<string>;
    /**
     * 
     * @type {Array<IBeslutObject>}
     * @memberof IFormattedData
     */
    'korttidsboende': Array<IBeslutObject>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IFormattedData
     */
    'utförare_hemtjänst': Array<string>;
    /**
     * 
     * @type {Array<IBeslutObject>}
     * @memberof IFormattedData
     */
    'hemtjänst': Array<IBeslutObject>;
    /**
     * 
     * @type {number}
     * @memberof IFormattedData
     */
    'omfattning_månad': number;
    /**
     * 
     * @type {boolean}
     * @memberof IFormattedData
     */
    'dubbelbemanning': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IFormattedData
     */
    'natthjälp': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IFormattedData
     */
    'utförare_säbo': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IFormattedData
     */
    'nästa_kvalitetsfrågor': string;
    /**
     * 
     * @type {string}
     * @memberof IFormattedData
     */
    'säbo_fv': string;
    /**
     * 
     * @type {string}
     * @memberof IFormattedData
     */
    'säbo_till': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IFormattedData
     */
    'ny_process': Array<string>;
    /**
     * 
     * @type {Array<IFormattedDataMedlemmarInner>}
     * @memberof IFormattedData
     */
    'medlemmar': Array<IFormattedDataMedlemmarInner>;
    /**
     * 
     * @type {number}
     * @memberof IFormattedData
     */
    'kontaktperson_tillfällen': number;
    /**
     * 
     * @type {boolean}
     * @memberof IFormattedData
     */
    'boendestöd': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IFormattedData
     */
    'hemtjänst_boende': boolean;
}
/**
 * 
 * @export
 * @interface IFormattedDataMedlemmarInner
 */
export interface IFormattedDataMedlemmarInner {
    /**
     * 
     * @type {string}
     * @memberof IFormattedDataMedlemmarInner
     */
    'medlem': string;
    /**
     * 
     * @type {boolean}
     * @memberof IFormattedDataMedlemmarInner
     */
    'aktledare': boolean;
}
/**
 * 
 * @export
 * @interface IFullFlagData
 */
export interface IFullFlagData {
    /**
     * 
     * @type {number}
     * @memberof IFullFlagData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof IFullFlagData
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof IFullFlagData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IFullFlagData
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof IFullFlagData
     */
    'från': string;
    /**
     * 
     * @type {string}
     * @memberof IFullFlagData
     */
    'till': string;
    /**
     * 
     * @type {number}
     * @memberof IFullFlagData
     */
    'nivå': number;
    /**
     * 
     * @type {string}
     * @memberof IFullFlagData
     */
    'target_type': string;
    /**
     * 
     * @type {number}
     * @memberof IFullFlagData
     */
    'target_id': number;
}
/**
 * 
 * @export
 * @interface IHandelse
 */
export interface IHandelse {
    /**
     * 
     * @type {number}
     * @memberof IHandelse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IHandelse
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof IHandelse
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof IHandelse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof IHandelse
     */
    'comment': string;
    /**
     * 
     * @type {IHandlaggare}
     * @memberof IHandelse
     */
    'createdBy': IHandlaggare;
}
/**
 * 
 * @export
 * @interface IHandlaggare
 */
export interface IHandlaggare {
    /**
     * 
     * @type {number}
     * @memberof IHandlaggare
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IHandlaggare
     */
    'namn': string;
}
/**
 * 
 * @export
 * @interface IHandlaggareWithPermissions
 */
export interface IHandlaggareWithPermissions {
    /**
     * 
     * @type {number}
     * @memberof IHandlaggareWithPermissions
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IHandlaggareWithPermissions
     */
    'personnummer': string;
    /**
     * 
     * @type {string}
     * @memberof IHandlaggareWithPermissions
     */
    'namn': string;
    /**
     * 
     * @type {string}
     * @memberof IHandlaggareWithPermissions
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof IHandlaggareWithPermissions
     */
    'team': number;
    /**
     * 
     * @type {number}
     * @memberof IHandlaggareWithPermissions
     */
    'role_id': number;
    /**
     * 
     * @type {string}
     * @memberof IHandlaggareWithPermissions
     */
    'role_name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IHandlaggareWithPermissions
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IHandlaggareWithPermissions
     */
    'modules': Array<string>;
    /**
     * 
     * @type {Array<IPermissionAction>}
     * @memberof IHandlaggareWithPermissions
     */
    'permission_actions': Array<IPermissionAction>;
    /**
     * 
     * @type {Array<IModuleAction>}
     * @memberof IHandlaggareWithPermissions
     */
    'module_actions': Array<IModuleAction>;
}
/**
 * 
 * @export
 * @interface IMedarbetareQueryResult
 */
export interface IMedarbetareQueryResult {
    /**
     * 
     * @type {IResultResults}
     * @memberof IMedarbetareQueryResult
     */
    'results': IResultResults;
}
/**
 * 
 * @export
 * @interface IModuleAction
 */
export interface IModuleAction {
    /**
     * 
     * @type {string}
     * @memberof IModuleAction
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof IModuleAction
     */
    'module': string;
}
/**
 * 
 * @export
 * @interface INewAvdelningData
 */
export interface INewAvdelningData {
    /**
     * 
     * @type {string}
     * @memberof INewAvdelningData
     */
    'namn': string;
}
/**
 * 
 * @export
 * @interface INewTeamData
 */
export interface INewTeamData {
    /**
     * 
     * @type {string}
     * @memberof INewTeamData
     */
    'namn': string;
    /**
     * 
     * @type {number}
     * @memberof INewTeamData
     */
    'avdelning': number;
}
/**
 * 
 * @export
 * @interface INewlyExpiredCases
 */
export interface INewlyExpiredCases {
    /**
     * 
     * @type {string}
     * @memberof INewlyExpiredCases
     */
    'personnummer': string;
    /**
     * 
     * @type {string}
     * @memberof INewlyExpiredCases
     */
    'förnamn': string;
    /**
     * 
     * @type {string}
     * @memberof INewlyExpiredCases
     */
    'efternamn': string;
    /**
     * 
     * @type {string}
     * @memberof INewlyExpiredCases
     */
    'insatskategorityp': string;
    /**
     * 
     * @type {string}
     * @memberof INewlyExpiredCases
     */
    'till': string;
    /**
     * 
     * @type {string}
     * @memberof INewlyExpiredCases
     */
    'processid': string;
}
/**
 * 
 * @export
 * @interface IOutsideTeamStructure
 */
export interface IOutsideTeamStructure {
    /**
     * 
     * @type {string}
     * @memberof IOutsideTeamStructure
     */
    'personnummer': string;
    /**
     * 
     * @type {string}
     * @memberof IOutsideTeamStructure
     */
    'ansvarig_person': string;
    /**
     * 
     * @type {string}
     * @memberof IOutsideTeamStructure
     */
    'förnamn': string;
    /**
     * 
     * @type {string}
     * @memberof IOutsideTeamStructure
     */
    'efternamn': string;
    /**
     * 
     * @type {number}
     * @memberof IOutsideTeamStructure
     */
    'processid': number;
    /**
     * 
     * @type {string}
     * @memberof IOutsideTeamStructure
     */
    'insatskategorityp': string;
    /**
     * 
     * @type {string}
     * @memberof IOutsideTeamStructure
     */
    'till': string;
    /**
     * 
     * @type {string}
     * @memberof IOutsideTeamStructure
     */
    'upprättad': string;
    /**
     * 
     * @type {string}
     * @memberof IOutsideTeamStructure
     */
    'mark': string;
}
/**
 * 
 * @export
 * @interface IPermissionAction
 */
export interface IPermissionAction {
    /**
     * 
     * @type {string}
     * @memberof IPermissionAction
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof IPermissionAction
     */
    'permission': string;
}
/**
 * 
 * @export
 * @interface IQueryResult
 */
export interface IQueryResult {
    /**
     * 
     * @type {string}
     * @memberof IQueryResult
     */
    'från': string;
    /**
     * 
     * @type {string}
     * @memberof IQueryResult
     */
    'till': string;
    /**
     * 
     * @type {string}
     * @memberof IQueryResult
     */
    'brukare': string;
    /**
     * 
     * @type {string}
     * @memberof IQueryResult
     */
    'förnamn': string;
    /**
     * 
     * @type {string}
     * @memberof IQueryResult
     */
    'efternamn': string;
    /**
     * 
     * @type {number}
     * @memberof IQueryResult
     */
    'nivå': number;
    /**
     * 
     * @type {string}
     * @memberof IQueryResult
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof IQueryResult
     */
    'utförandegrad_snitt': number;
    /**
     * 
     * @type {number}
     * @memberof IQueryResult
     */
    'std_avvikelse': number;
}
/**
 * 
 * @export
 * @interface IRequestBody
 */
export interface IRequestBody {
    /**
     * 
     * @type {string}
     * @memberof IRequestBody
     */
    'personnummer': string;
    /**
     * 
     * @type {string}
     * @memberof IRequestBody
     */
    'new_role': string;
}
/**
 * 
 * @export
 * @interface IResult
 */
export interface IResult {
    /**
     * 
     * @type {IResultResults}
     * @memberof IResult
     */
    'results': IResultResults;
}
/**
 * 
 * @export
 * @interface IResultResults
 */
export interface IResultResults {
    /**
     * 
     * @type {Array<IQueryResult>}
     * @memberof IResultResults
     */
    'medarbetare': Array<IQueryResult>;
}
/**
 * 
 * @export
 * @interface IRole
 */
export interface IRole {
    /**
     * 
     * @type {string}
     * @memberof IRole
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IRole
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof IRole
     */
    'modules': Array<string>;
}
/**
 * 
 * @export
 * @interface ISubFlag
 */
export interface ISubFlag {
    /**
     * 
     * @type {string}
     * @memberof ISubFlag
     */
    'start': string;
    /**
     * 
     * @type {number}
     * @memberof ISubFlag
     */
    'over_36_hour_breaks': number;
    /**
     * 
     * @type {number}
     * @memberof ISubFlag
     */
    'nivå': number;
    /**
     * 
     * @type {number}
     * @memberof ISubFlag
     */
    'longest_break': number;
}
/**
 * 
 * @export
 * @interface ITeam
 */
export interface ITeam {
    /**
     * 
     * @type {number}
     * @memberof ITeam
     */
    'team_id': number;
    /**
     * 
     * @type {string}
     * @memberof ITeam
     */
    'team_namn': string;
}
/**
 * 
 * @export
 * @interface IUpdateHandlaggareData
 */
export interface IUpdateHandlaggareData {
    /**
     * 
     * @type {number}
     * @memberof IUpdateHandlaggareData
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IUpdateHandlaggareData
     */
    'namn': string;
    /**
     * 
     * @type {string}
     * @memberof IUpdateHandlaggareData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IUpdateHandlaggareData
     */
    'personnummer': string;
    /**
     * 
     * @type {boolean}
     * @memberof IUpdateHandlaggareData
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IUpdateHandlaggareData
     */
    'in_structure': boolean;
    /**
     * 
     * @type {number}
     * @memberof IUpdateHandlaggareData
     */
    'team': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof IUpdateHandlaggareData
     */
    'scope': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof IUpdateHandlaggareData
     */
    'role': string;
}
/**
 * 
 * @export
 * @interface InitializeBenchmark200Response
 */
export interface InitializeBenchmark200Response {
    /**
     * 
     * @type {string}
     * @memberof InitializeBenchmark200Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface InsertBenchmark200Response
 */
export interface InsertBenchmark200Response {
    /**
     * 
     * @type {boolean}
     * @memberof InsertBenchmark200Response
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface Me200Response
 */
export interface Me200Response {
    /**
     * 
     * @type {Array<string>}
     * @memberof Me200Response
     */
    'modules': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Me200Response
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface Municipality200Response
 */
export interface Municipality200Response {
    /**
     * 
     * @type {any}
     * @memberof Municipality200Response
     */
    'descriptions': any;
    /**
     * 
     * @type {any}
     * @memberof Municipality200Response
     */
    'config': any;
}
/**
 * 
 * @export
 * @interface OmfattandeBeslutBrukare200ResponseInner
 */
export interface OmfattandeBeslutBrukare200ResponseInner {
    /**
     * 
     * @type {any}
     * @memberof OmfattandeBeslutBrukare200ResponseInner
     */
    'timePoints': any;
    /**
     * 
     * @type {string}
     * @memberof OmfattandeBeslutBrukare200ResponseInner
     */
    'month': string;
}
/**
 * 
 * @export
 * @interface RemoveFlagsRequest
 */
export interface RemoveFlagsRequest {
    /**
     * 
     * @type {number}
     * @memberof RemoveFlagsRequest
     */
    'removedBy': number;
    /**
     * 
     * @type {string}
     * @memberof RemoveFlagsRequest
     */
    'comment': string;
    /**
     * 
     * @type {Array<IFlagData>}
     * @memberof RemoveFlagsRequest
     */
    'flags': Array<IFlagData>;
    /**
     * 
     * @type {number}
     * @memberof RemoveFlagsRequest
     */
    'c_case': number;
}
/**
 * 
 * @export
 * @interface RemovePermissionOverrideRequest
 */
export interface RemovePermissionOverrideRequest {
    /**
     * 
     * @type {number}
     * @memberof RemovePermissionOverrideRequest
     */
    'permissionId': number;
    /**
     * 
     * @type {number}
     * @memberof RemovePermissionOverrideRequest
     */
    'roleId': number;
}
/**
 * 
 * @export
 * @interface SearchTags200Response
 */
export interface SearchTags200Response {
    /**
     * 
     * @type {number}
     * @memberof SearchTags200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<SearchTags200ResponseDataInner>}
     * @memberof SearchTags200Response
     */
    'data': Array<SearchTags200ResponseDataInner>;
}
/**
 * 
 * @export
 * @interface SearchTags200ResponseDataInner
 */
export interface SearchTags200ResponseDataInner {
    /**
     * 
     * @type {any}
     * @memberof SearchTags200ResponseDataInner
     */
    'numMunicipalities': any;
    /**
     * 
     * @type {any}
     * @memberof SearchTags200ResponseDataInner
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof SearchTags200ResponseDataInner
     */
    'id': any;
}
/**
 * 
 * @export
 * @interface SetAssignableRoleRequest
 */
export interface SetAssignableRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof SetAssignableRoleRequest
     */
    'assignableRoleId': number;
    /**
     * 
     * @type {number}
     * @memberof SetAssignableRoleRequest
     */
    'roleId': number;
}
/**
 * 
 * @export
 * @interface SetLifespansRequest
 */
export interface SetLifespansRequest {
    /**
     * 
     * @type {string}
     * @memberof SetLifespansRequest
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetLifespansRequest
     */
    'access_token'?: string;
}
/**
 * 
 * @export
 * @interface Structure200Response
 */
export interface Structure200Response {
    /**
     * 
     * @type {Array<IRole>}
     * @memberof Structure200Response
     */
    'roles': Array<IRole>;
    /**
     * 
     * @type {Structure200ResponseFlat}
     * @memberof Structure200Response
     */
    'flat': Structure200ResponseFlat;
    /**
     * 
     * @type {Array<IAvdelning>}
     * @memberof Structure200Response
     */
    'tree': Array<IAvdelning>;
}
/**
 * 
 * @export
 * @interface Structure200ResponseFlat
 */
export interface Structure200ResponseFlat {
    /**
     * 
     * @type {Array<Structure200ResponseFlatAvdelningarInner>}
     * @memberof Structure200ResponseFlat
     */
    'avdelningar': Array<Structure200ResponseFlatAvdelningarInner>;
    /**
     * 
     * @type {Array<Structure200ResponseFlatTeamInner>}
     * @memberof Structure200ResponseFlat
     */
    'team': Array<Structure200ResponseFlatTeamInner>;
    /**
     * 
     * @type {Array<Structure200ResponseFlatHandlGgareInner>}
     * @memberof Structure200ResponseFlat
     */
    'handläggare': Array<Structure200ResponseFlatHandlGgareInner>;
}
/**
 * 
 * @export
 * @interface Structure200ResponseFlatAvdelningarInner
 */
export interface Structure200ResponseFlatAvdelningarInner {
    /**
     * 
     * @type {string}
     * @memberof Structure200ResponseFlatAvdelningarInner
     */
    'avdelning_namn': string;
    /**
     * 
     * @type {number}
     * @memberof Structure200ResponseFlatAvdelningarInner
     */
    'avdelning_id': number;
}
/**
 * 
 * @export
 * @interface Structure200ResponseFlatHandlGgareInner
 */
export interface Structure200ResponseFlatHandlGgareInner {
    /**
     * 
     * @type {string}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'role_name': string;
    /**
     * 
     * @type {number}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'role_id': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'scope': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'in_structure': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'personnummer': string;
    /**
     * 
     * @type {string}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'namn': string;
    /**
     * 
     * @type {number}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Structure200ResponseFlatHandlGgareInner
     */
    'team': number;
}
/**
 * 
 * @export
 * @interface Structure200ResponseFlatTeamInner
 */
export interface Structure200ResponseFlatTeamInner {
    /**
     * 
     * @type {number}
     * @memberof Structure200ResponseFlatTeamInner
     */
    'avdelning': number;
    /**
     * 
     * @type {string}
     * @memberof Structure200ResponseFlatTeamInner
     */
    'team_namn': string;
    /**
     * 
     * @type {number}
     * @memberof Structure200ResponseFlatTeamInner
     */
    'team_id': number;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TenantFunctionName = {
    HemtjnstVolumeAgeGroup: 'hemtjänstVolumeAgeGroup',
    SboVolumeAgeGroup: 'säboVolumeAgeGroup',
    SboOmvrdnadVolumeAgeGroup: 'säboOmvårdnadVolumeAgeGroup',
    SboDemensVolumeAgeGroup: 'säboDemensVolumeAgeGroup',
    SboInflowAgeGroup: 'säboInflowAgeGroup',
    SboOutflowAgeGroup: 'säboOutflowAgeGroup',
    SboStayLength: 'säboStayLength',
    SboOmvrdnadInflowAgeGroup: 'säboOmvårdnadInflowAgeGroup',
    SboOmvrdnadOutflowAgeGroup: 'säboOmvårdnadOutflowAgeGroup',
    SboDemensInflowAgeGroup: 'säboDemensInflowAgeGroup',
    SboDemensOutflowAgeGroup: 'säboDemensOutflowAgeGroup',
    SboOmvrdnadStayLength: 'säboOmvårdnadStayLength',
    SboDemensStayLength: 'säboDemensStayLength'
} as const;

export type TenantFunctionName = typeof TenantFunctionName[keyof typeof TenantFunctionName];


/**
 * 
 * @export
 * @interface UpdateRoleRequest
 */
export interface UpdateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UtfordTid200ResponseInner
 */
export interface UtfordTid200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof UtfordTid200ResponseInner
     */
    'hours': number;
    /**
     * 
     * @type {any}
     * @memberof UtfordTid200ResponseInner
     */
    'month': any;
}
/**
 * 
 * @export
 * @interface Webhook200Response
 */
export interface Webhook200Response {
    /**
     * 
     * @type {string}
     * @memberof Webhook200Response
     */
    'status': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _new: async (tenant: string, body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('_new', 'tenant', tenant)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('_new', 'body', body)
            const localVarPath = `/v1/beslutsstod/{tenant}/new`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddAuthenticationMethodRequest} addAuthenticationMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAuthenticationMethod: async (tenant: string, addAuthenticationMethodRequest: AddAuthenticationMethodRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('addAuthenticationMethod', 'tenant', tenant)
            // verify required parameter 'addAuthenticationMethodRequest' is not null or undefined
            assertParamExists('addAuthenticationMethod', 'addAuthenticationMethodRequest', addAuthenticationMethodRequest)
            const localVarPath = `/v1/tenant/{tenant}/authentication-method`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAuthenticationMethodRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddFlagsRequest} addFlagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlags: async (tenant: string, addFlagsRequest: AddFlagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('addFlags', 'tenant', tenant)
            // verify required parameter 'addFlagsRequest' is not null or undefined
            assertParamExists('addFlags', 'addFlagsRequest', addFlagsRequest)
            const localVarPath = `/v1/case/{tenant}/flags/add`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFlagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddModuleToRoleRequest} addModuleToRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addModuleToRole: async (tenant: string, addModuleToRoleRequest: AddModuleToRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('addModuleToRole', 'tenant', tenant)
            // verify required parameter 'addModuleToRoleRequest' is not null or undefined
            assertParamExists('addModuleToRole', 'addModuleToRoleRequest', addModuleToRoleRequest)
            const localVarPath = `/v1/permission/{tenant}/role/module`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addModuleToRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMunicipalitiesToTag: async (tenant: string, tagId: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('addMunicipalitiesToTag', 'tenant', tenant)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('addMunicipalitiesToTag', 'tagId', tagId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addMunicipalitiesToTag', 'requestBody', requestBody)
            const localVarPath = `/v1/municipality/{tenant}/tag/{tagId}/municipalities`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddPermissionOverrideRequest} addPermissionOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPermissionOverride: async (tenant: string, addPermissionOverrideRequest: AddPermissionOverrideRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('addPermissionOverride', 'tenant', tenant)
            // verify required parameter 'addPermissionOverrideRequest' is not null or undefined
            assertParamExists('addPermissionOverride', 'addPermissionOverrideRequest', addPermissionOverrideRequest)
            const localVarPath = `/v1/permission/{tenant}/role/permission`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPermissionOverrideRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRole: async (tenant: string, updateRoleRequest: UpdateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('addRole', 'tenant', tenant)
            // verify required parameter 'updateRoleRequest' is not null or undefined
            assertParamExists('addRole', 'updateRoleRequest', updateRoleRequest)
            const localVarPath = `/v1/permission/{tenant}/role`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all: async (tenant: string, from: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('all', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('all', 'from', from)
            const localVarPath = `/v1/audit/{tenant}/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCases: async (tenant: string, idToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('allCases', 'tenant', tenant)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('allCases', 'idToken', idToken)
            const localVarPath = `/v1/case/{tenant}/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ammountBrukareCanceledCases: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('ammountBrukareCanceledCases', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('ammountBrukareCanceledCases', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('ammountBrukareCanceledCases', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/ammountBrukareCanceledCases`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antalBrukareBeviljad: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('antalBrukareBeviljad', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('antalBrukareBeviljad', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('antalBrukareBeviljad', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/antalBrukareBeviljad`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('archive', 'tenant', tenant)
            const localVarPath = `/v1/beslutsstod/{tenant}/archive`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (tenant: string, authenticateRequest: AuthenticateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('authenticate', 'tenant', tenant)
            // verify required parameter 'authenticateRequest' is not null or undefined
            assertParamExists('authenticate', 'authenticateRequest', authenticateRequest)
            const localVarPath = `/v1/guest/{tenant}/authenticate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avgAge: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('avgAge', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('avgAge', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('avgAge', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/avgAge`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avgAgeCanceledCases: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('avgAgeCanceledCases', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('avgAgeCanceledCases', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('avgAgeCanceledCases', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/avgAgeCanceledCases`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avgAgeNewCases: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('avgAgeNewCases', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('avgAgeNewCases', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('avgAgeNewCases', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/avgAgeNewCases`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beslutsomfattningPerInterval: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('beslutsomfattningPerInterval', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('beslutsomfattningPerInterval', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('beslutsomfattningPerInterval', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/beslutsomfattningPerInterval`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestalldOmvardnad: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('bestalldOmvardnad', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('bestalldOmvardnad', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('bestalldOmvardnad', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/bestalldOmvardnad`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestalldService: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('bestalldService', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('bestalldService', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('bestalldService', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/bestalldService`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestalldTidPerBrukare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('bestalldTidPerBrukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('bestalldTidPerBrukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('bestalldTidPerBrukare', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/bestalldTidPerBrukare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bevlijadeLarmNattinsattser: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('bevlijadeLarmNattinsattser', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('bevlijadeLarmNattinsattser', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('bevlijadeLarmNattinsattser', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/bevlijadeLarmNattinsattser`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brukareBestalldTid: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('brukareBestalldTid', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('brukareBestalldTid', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('brukareBestalldTid', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/brukareBestalldTid`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brukareCount: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('brukareCount', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('brukareCount', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('brukareCount', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/brukareCount`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} namn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byName: async (tenant: string, from: string, to: string, namn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('byName', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('byName', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('byName', 'to', to)
            // verify required parameter 'namn' is not null or undefined
            assertParamExists('byName', 'namn', namn)
            const localVarPath = `/v1/utforare/{tenant}/namn/{namn}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"namn"}}`, encodeURIComponent(String(namn)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} personnummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byPersonnummer: async (tenant: string, personnummer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('byPersonnummer', 'tenant', tenant)
            // verify required parameter 'personnummer' is not null or undefined
            assertParamExists('byPersonnummer', 'personnummer', personnummer)
            const localVarPath = `/v1/brukare/{tenant}/personnummer/{personnummer}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"personnummer"}}`, encodeURIComponent(String(personnummer)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} owner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bygare: async (tenant: string, owner: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('bygare', 'tenant', tenant)
            // verify required parameter 'owner' is not null or undefined
            assertParamExists('bygare', 'owner', owner)
            const localVarPath = `/v1/case/{tenant}/owner/{owner}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"owner"}}`, encodeURIComponent(String(owner)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculate: async (tenant: string, body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('calculate', 'tenant', tenant)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('calculate', 'body', body)
            const localVarPath = `/v1/beslutsstod/{tenant}/calculate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callback: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('callback', 'tenant', tenant)
            const localVarPath = `/v1/auth/callback/{tenant}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canceledCases: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('canceledCases', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('canceledCases', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('canceledCases', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/canceledCases`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canceledCasesByBrukare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('canceledCasesByBrukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('canceledCasesByBrukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('canceledCasesByBrukare', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/canceledCasesByBrukare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllFlags: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('clearAllFlags', 'tenant', tenant)
            const localVarPath = `/v1/clear/{tenant}/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} flag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFlag: async (tenant: string, flag: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('clearFlag', 'tenant', tenant)
            // verify required parameter 'flag' is not null or undefined
            assertParamExists('clearFlag', 'flag', flag)
            const localVarPath = `/v1/clear/{tenant}/{flag}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"flag"}}`, encodeURIComponent(String(flag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICommentData} iCommentData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        close: async (tenant: string, iCommentData: ICommentData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('close', 'tenant', tenant)
            // verify required parameter 'iCommentData' is not null or undefined
            assertParamExists('close', 'iCommentData', iCommentData)
            const localVarPath = `/v1/case/{tenant}/close`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCommentData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICommentData} iCommentData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        comment: async (tenant: string, iCommentData: ICommentData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('comment', 'tenant', tenant)
            // verify required parameter 'iCommentData' is not null or undefined
            assertParamExists('comment', 'iCommentData', iCommentData)
            const localVarPath = `/v1/case/{tenant}/comment`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCommentData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} consentChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consent: async (consentChallenge: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'consentChallenge' is not null or undefined
            assertParamExists('consent', 'consentChallenge', consentChallenge)
            const localVarPath = `/v1/auth/consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (consentChallenge !== undefined) {
                localVarQueryParameter['consent_challenge'] = consentChallenge;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICreateCaseData} iCreateCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (tenant: string, iCreateCaseData: ICreateCaseData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('create', 'tenant', tenant)
            // verify required parameter 'iCreateCaseData' is not null or undefined
            assertParamExists('create', 'iCreateCaseData', iCreateCaseData)
            const localVarPath = `/v1/case/{tenant}/skapa`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCreateCaseData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateGuestUserRequest} createGuestUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGuestUser: async (tenant: string, createGuestUserRequest: CreateGuestUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createGuestUser', 'tenant', tenant)
            // verify required parameter 'createGuestUserRequest' is not null or undefined
            assertParamExists('createGuestUser', 'createGuestUserRequest', createGuestUserRequest)
            const localVarPath = `/v1/guest/{tenant}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGuestUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (tenant: string, updateRoleRequest: UpdateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('createTag', 'tenant', tenant)
            // verify required parameter 'updateRoleRequest' is not null or undefined
            assertParamExists('createTag', 'updateRoleRequest', updateRoleRequest)
            const localVarPath = `/v1/municipality/{tenant}/tag`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllBenchmarks: async (tenant: string, year: number, month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('deleteAllBenchmarks', 'tenant', tenant)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('deleteAllBenchmarks', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('deleteAllBenchmarks', 'month', month)
            const localVarPath = `/v1/benchmarking/{tenant}/insert-all-benchmarks`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestUser: async (tenant: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('deleteGuestUser', 'tenant', tenant)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteGuestUser', 'username', username)
            const localVarPath = `/v1/guest/{tenant}/{username}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('deleteRole', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRole', 'id', id)
            const localVarPath = `/v1/permission/{tenant}/roles/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: async (tenant: string, tagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('deleteTag', 'tenant', tenant)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deleteTag', 'tagId', tagId)
            const localVarPath = `/v1/municipality/{tenant}/tag/{tagId}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        descriptions: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('descriptions', 'tenant', tenant)
            const localVarPath = `/v1/beslutsstod/{tenant}/descriptions`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdf: async (tenant: string, body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('downloadPdf', 'tenant', tenant)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('downloadPdf', 'body', body)
            const localVarPath = `/v1/beslutsstod/{tenant}/pdf`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dygnsvila: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('dygnsvila', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('dygnsvila', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/dygnsvila`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dygnsvilaGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('dygnsvilaGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('dygnsvilaGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/dygnsvila_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {EditGuestUserRequest} editGuestUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGuestUser: async (tenant: string, username: string, editGuestUserRequest: EditGuestUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('editGuestUser', 'tenant', tenant)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('editGuestUser', 'username', username)
            // verify required parameter 'editGuestUserRequest' is not null or undefined
            assertParamExists('editGuestUser', 'editGuestUserRequest', editGuestUserRequest)
            const localVarPath = `/v1/guest/{tenant}/{username}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editGuestUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTag: async (tenant: string, tagId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('editTag', 'tenant', tenant)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('editTag', 'tagId', tagId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('editTag', 'name', name)
            const localVarPath = `/v1/municipality/{tenant}/tag/{tagId}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f10all: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f10all', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f10all', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f10all', 'to', to)
            const localVarPath = `/v1/results/{tenant}/10/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f15all: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f15all', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f15all', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f15all', 'to', to)
            const localVarPath = `/v1/results/{tenant}/15/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f16all: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f16all', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f16all', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f16all', 'to', to)
            const localVarPath = `/v1/results/{tenant}/16/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f17all: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f17all', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f17all', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f17all', 'to', to)
            const localVarPath = `/v1/results/{tenant}/17/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f2All: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f2All', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f2All', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f2All', 'to', to)
            const localVarPath = `/v1/results/{tenant}/2/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} brukare 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f2Brukare: async (tenant: string, from: string, to: string, brukare: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f2Brukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f2Brukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f2Brukare', 'to', to)
            // verify required parameter 'brukare' is not null or undefined
            assertParamExists('f2Brukare', 'brukare', brukare)
            const localVarPath = `/v1/results/{tenant}/2/brukare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (brukare !== undefined) {
                localVarQueryParameter['brukare'] = brukare;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f4All: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f4All', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f4All', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f4All', 'to', to)
            const localVarPath = `/v1/results/{tenant}/4/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f7: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f7', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f7', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f7', 'to', to)
            const localVarPath = `/v1/results/{tenant}/7/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} medarbetare 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f7Medarbetare: async (tenant: string, from: string, to: string, medarbetare: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f7Medarbetare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f7Medarbetare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f7Medarbetare', 'to', to)
            // verify required parameter 'medarbetare' is not null or undefined
            assertParamExists('f7Medarbetare', 'medarbetare', medarbetare)
            const localVarPath = `/v1/results/{tenant}/7/medarbetare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (medarbetare !== undefined) {
                localVarQueryParameter['medarbetare'] = medarbetare;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f8All: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f8All', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f8All', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f8All', 'to', to)
            const localVarPath = `/v1/results/{tenant}/8/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f9All: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('f9All', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('f9All', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('f9All', 'to', to)
            const localVarPath = `/v1/results/{tenant}/9/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAll: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('generateAll', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('generateAll', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/generate_all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthenticationMethods: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getAllAuthenticationMethods', 'tenant', tenant)
            const localVarPath = `/v1/tenant/{tenant}/all-authentication-methods`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrukare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getAllBrukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAllBrukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAllBrukare', 'to', to)
            const localVarPath = `/v1/brukare/{tenant}/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMedarbetare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getAllMedarbetare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAllMedarbetare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAllMedarbetare', 'to', to)
            const localVarPath = `/v1/medarbetare/{tenant}/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUtforare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getAllUtforare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAllUtforare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAllUtforare', 'to', to)
            const localVarPath = `/v1/utforare/{tenant}/all`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedAuthenticationMethods: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getAllowedAuthenticationMethods', 'tenant', tenant)
            const localVarPath = `/v1/tenant/{tenant}/allowed-authentication-methods`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverage: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getAverage', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAverage', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAverage', 'to', to)
            const localVarPath = `/v1/utforare/{tenant}/average`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {number} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageSpecific: async (tenant: string, from: string, to: string, level: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getAverageSpecific', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAverageSpecific', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAverageSpecific', 'to', to)
            // verify required parameter 'level' is not null or undefined
            assertParamExists('getAverageSpecific', 'level', level)
            const localVarPath = `/v1/utforare/{tenant}/average/{level}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"level"}}`, encodeURIComponent(String(level)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrukareOutsideStructure: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getBrukareOutsideStructure', 'tenant', tenant)
            const localVarPath = `/v1/case/{tenant}/outside_structure`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapacityInflowOutflowTrendValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getCapacityInflowOutflowTrendValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/capacity-inflow-outflow-trend/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQualityMixValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getCostQualityMixValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/cost-quality-mix/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostValues: async (tenant: string, variantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getCostValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/cost/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} frn 
         * @param {string} till 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistribution: async (tenant: string, frn: string, till: string, idToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getDistribution', 'tenant', tenant)
            // verify required parameter 'frn' is not null or undefined
            assertParamExists('getDistribution', 'frn', frn)
            // verify required parameter 'till' is not null or undefined
            assertParamExists('getDistribution', 'till', till)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('getDistribution', 'idToken', idToken)
            const localVarPath = `/v1/case/{tenant}/distribution`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (frn !== undefined) {
                localVarQueryParameter['från'] = frn;
            }

            if (till !== undefined) {
                localVarQueryParameter['till'] = till;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestUser: async (tenant: string, username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getGuestUser', 'tenant', tenant)
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getGuestUser', 'username', username)
            const localVarPath = `/v1/guest/{tenant}/{username}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestUsers: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getGuestUsers', 'tenant', tenant)
            const localVarPath = `/v1/guest/{tenant}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareHoursTrendValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareHoursTrendValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-hours-trend/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursAgeGroupValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareOrderedHoursAgeGroupValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-ordered-hours-age-group/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursIntervalComparisonValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareOrderedHoursIntervalComparisonValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-ordered-hours-interval-comparison/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursIntervalValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareOrderedHoursIntervalValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-ordered-hours-interval/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursPerPersonValues: async (tenant: string, variantId?: string, dataSource?: string, grouping?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareOrderedHoursPerPersonValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-ordered-hours-per-person/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['dataSource'] = dataSource;
            }

            if (grouping !== undefined) {
                localVarQueryParameter['grouping'] = grouping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursProportionIntervalValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareOrderedHoursProportionIntervalValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-ordered-hours-proportion-interval/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursTrendMixedValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareOrderedHoursTrendMixedValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-ordered-hours-trend-mixed/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareOrderedHoursValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-ordered-hours/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCarePerformedHoursTrendMixedValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCarePerformedHoursTrendMixedValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-performed-hours-trend-mixed/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCarePerformedHoursValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCarePerformedHoursValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-performed-hours/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetHomeCareProportionAgeGroupValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareProportionAgeGroupValues: async (tenant: string, variant?: GetHomeCareProportionAgeGroupValuesVariantEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareProportionAgeGroupValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-proportion-age-group/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variant !== undefined) {
                localVarQueryParameter['variant'] = variant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareProportionValues: async (tenant: string, variantId?: string, dataSource?: string, grouping?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareProportionValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-proportion/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['dataSource'] = dataSource;
            }

            if (grouping !== undefined) {
                localVarQueryParameter['grouping'] = grouping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareResidencyCostMixValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareResidencyCostMixValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-residency-cost-mix/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareVolumeAgeGroupTrendValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareVolumeAgeGroupTrendValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-volume-age-group-trend/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareVolumeAgeGroupValues: async (tenant: string, variantId?: string, dataSource?: string, grouping?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getHomeCareVolumeAgeGroupValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/home-care-volume-age-group/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['dataSource'] = dataSource;
            }

            if (grouping !== undefined) {
                localVarQueryParameter['grouping'] = grouping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInflowAgeGroupValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getInflowAgeGroupValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/inflow-age-group/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInflowProportionAgeGroupTrendValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getInflowProportionAgeGroupTrendValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/inflow-proportion-age-group-trend/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLifespans: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getLifespans', 'tenant', tenant)
            const localVarPath = `/v1/auth/{tenant}/lifespans`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxAuthDuration: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getMaxAuthDuration', 'tenant', tenant)
            const localVarPath = `/v1/tenant/{tenant}/max-auth-duration`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (tenant: string, idToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getMe', 'tenant', tenant)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('getMe', 'idToken', idToken)
            const localVarPath = `/v1/handlaggare/{tenant}/me`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getModules', 'tenant', tenant)
            const localVarPath = `/v1/permission/{tenant}/modules`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} frn 
         * @param {string} till 
         * @param {string} idToken 
         * @param {string} otherPersonnummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyVolume2Other: async (tenant: string, frn: string, till: string, idToken: string, otherPersonnummer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getMonthlyVolume2Other', 'tenant', tenant)
            // verify required parameter 'frn' is not null or undefined
            assertParamExists('getMonthlyVolume2Other', 'frn', frn)
            // verify required parameter 'till' is not null or undefined
            assertParamExists('getMonthlyVolume2Other', 'till', till)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('getMonthlyVolume2Other', 'idToken', idToken)
            // verify required parameter 'otherPersonnummer' is not null or undefined
            assertParamExists('getMonthlyVolume2Other', 'otherPersonnummer', otherPersonnummer)
            const localVarPath = `/v1/case/{tenant}/volume2/month/other`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (frn !== undefined) {
                localVarQueryParameter['från'] = frn;
            }

            if (till !== undefined) {
                localVarQueryParameter['till'] = till;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }

            if (otherPersonnummer !== undefined) {
                localVarQueryParameter['other_personnummer'] = otherPersonnummer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalities: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getMunicipalities', 'tenant', tenant)
            const localVarPath = `/v1/municipality/{tenant}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalityName: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getMunicipalityName', 'tenant', tenant)
            const localVarPath = `/v1/municipality/{tenant}/name`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCases: async (tenant: string, date: string, idToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getMyCases', 'tenant', tenant)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getMyCases', 'date', date)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('getMyCases', 'idToken', idToken)
            const localVarPath = `/v1/case/{tenant}/mine`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNursingDementiaProportionValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getNursingDementiaProportionValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/nursing-dementia-proportion/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetNursingDementiaTotalStayLengthValuesAggregationEnum} [aggregation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNursingDementiaTotalStayLengthValues: async (tenant: string, aggregation?: GetNursingDementiaTotalStayLengthValuesAggregationEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getNursingDementiaTotalStayLengthValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/nursing-dementia-total-stay-length/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (aggregation !== undefined) {
                localVarQueryParameter['aggregation'] = aggregation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNursingDementiaTotalTrendValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getNursingDementiaTotalTrendValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/nursing-dementia-total-trend/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} otherPersonnummer 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherCases: async (tenant: string, date: string, otherPersonnummer: string, idToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getOtherCases', 'tenant', tenant)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getOtherCases', 'date', date)
            // verify required parameter 'otherPersonnummer' is not null or undefined
            assertParamExists('getOtherCases', 'otherPersonnummer', otherPersonnummer)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('getOtherCases', 'idToken', idToken)
            const localVarPath = `/v1/case/{tenant}/other`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (otherPersonnummer !== undefined) {
                localVarQueryParameter['other_personnummer'] = otherPersonnummer;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} otherPersonnummer 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherCases2: async (tenant: string, date: string, otherPersonnummer: string, idToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getOtherCases2', 'tenant', tenant)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getOtherCases2', 'date', date)
            // verify required parameter 'otherPersonnummer' is not null or undefined
            assertParamExists('getOtherCases2', 'otherPersonnummer', otherPersonnummer)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('getOtherCases2', 'idToken', idToken)
            const localVarPath = `/v1/case/{tenant}/other2`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (otherPersonnummer !== undefined) {
                localVarQueryParameter['other_personnummer'] = otherPersonnummer;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getPermissions', 'tenant', tenant)
            const localVarPath = `/v1/permission/{tenant}/permissions`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} ansvarigPerson 
         * @param {number} dateGap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyExpired: async (tenant: string, ansvarigPerson: string, dateGap: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getRecentlyExpired', 'tenant', tenant)
            // verify required parameter 'ansvarigPerson' is not null or undefined
            assertParamExists('getRecentlyExpired', 'ansvarigPerson', ansvarigPerson)
            // verify required parameter 'dateGap' is not null or undefined
            assertParamExists('getRecentlyExpired', 'dateGap', dateGap)
            const localVarPath = `/v1/case/{tenant}/recently_expired`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ansvarigPerson !== undefined) {
                localVarQueryParameter['ansvarig_person'] = ansvarigPerson;
            }

            if (dateGap !== undefined) {
                localVarQueryParameter['date_gap'] = dateGap;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidencyCapacityAgeGroupTrendValues: async (tenant: string, variantId?: string, dataSource?: string, grouping?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getResidencyCapacityAgeGroupTrendValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/residency-capacity-age-group-trend/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['dataSource'] = dataSource;
            }

            if (grouping !== undefined) {
                localVarQueryParameter['grouping'] = grouping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidencyCapacityAgeGroupValues: async (tenant: string, variantId?: string, dataSource?: string, grouping?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getResidencyCapacityAgeGroupValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/residency-capacity-age-group/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['dataSource'] = dataSource;
            }

            if (grouping !== undefined) {
                localVarQueryParameter['grouping'] = grouping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetResidencyPopulationProportionValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidencyPopulationProportionValues: async (tenant: string, variant?: GetResidencyPopulationProportionValuesVariantEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getResidencyPopulationProportionValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/residency-population-proportion/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variant !== undefined) {
                localVarQueryParameter['variant'] = variant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetResidencyProportionAgeGroupValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidencyProportionAgeGroupValues: async (tenant: string, variant?: GetResidencyProportionAgeGroupValuesVariantEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getResidencyProportionAgeGroupValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/residency-proportion-age-group/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variant !== undefined) {
                localVarQueryParameter['variant'] = variant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getRole', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRole', 'id', id)
            const localVarPath = `/v1/permission/{tenant}/roles/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getRoles', 'tenant', tenant)
            const localVarPath = `/v1/permission/{tenant}/roles`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetStayLengthTrendValuesSpecializationEnum} specialization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStayLengthTrendValues: async (tenant: string, specialization: GetStayLengthTrendValuesSpecializationEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getStayLengthTrendValues', 'tenant', tenant)
            // verify required parameter 'specialization' is not null or undefined
            assertParamExists('getStayLengthTrendValues', 'specialization', specialization)
            const localVarPath = `/v1/benchmarking/stay-length-trend/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (specialization !== undefined) {
                localVarQueryParameter['specialization'] = specialization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedBenchmarks: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getSupportedBenchmarks', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/{tenant}/supported`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTag: async (tenant: string, tagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTag', 'tenant', tenant)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('getTag', 'tagId', tagId)
            const localVarPath = `/v1/municipality/{tenant}/tag/{tagId}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag10: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag10', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag10', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/10/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag15: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag15', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag15', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/15/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag16: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag16', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag16', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/16/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag17: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag17', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag17', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/17/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag18: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag18', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag18', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/18/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag19: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag19', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag19', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/19/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag2: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag2', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag2', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/2/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag20: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag20', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag20', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/20/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag4: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag4', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag4', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/4/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag7: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag7', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag7', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/7/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag8: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag8', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag8', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/8/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag9: async (tenant: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getTextForFlag9', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTextForFlag9', 'id', id)
            const localVarPath = `/v1/text/{tenant}/flag/9/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeAgeGroupValues: async (tenant: string, variantId?: string, dataSource?: string, grouping?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getVolumeAgeGroupValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/volume-age-group/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['dataSource'] = dataSource;
            }

            if (grouping !== undefined) {
                localVarQueryParameter['grouping'] = grouping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeInsatsMixValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getVolumeInsatsMixValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/volume-insats-mix/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeProportionAgeGroupValues: async (tenant: string, variantId?: string, dataSource?: string, grouping?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getVolumeProportionAgeGroupValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/volume-proportion-age-group/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['dataSource'] = dataSource;
            }

            if (grouping !== undefined) {
                localVarQueryParameter['grouping'] = grouping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeQualityMixValues: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getVolumeQualityMixValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/volume-quality-mix/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeValues: async (tenant: string, variantId?: string, dataSource?: string, grouping?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('getVolumeValues', 'tenant', tenant)
            const localVarPath = `/v1/benchmarking/volume/{tenant}/values`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (variantId !== undefined) {
                localVarQueryParameter['variantId'] = variantId;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['dataSource'] = dataSource;
            }

            if (grouping !== undefined) {
                localVarQueryParameter['grouping'] = grouping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GuestRequest} guestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guest: async (guestRequest: GuestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guestRequest' is not null or undefined
            assertParamExists('guest', 'guestRequest', guestRequest)
            const localVarPath = `/v1/auth/guest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hgKontinuitet: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('hgKontinuitet', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('hgKontinuitet', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/hog_kontinuitet`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hgKontinuitetGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('hgKontinuitetGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('hgKontinuitetGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/hog_kontinuitet_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hgUtfrandeGrad: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('hgUtfrandeGrad', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('hgUtfrandeGrad', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/hog_utforandegrad`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hgUtfrandeGradGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('hgUtfrandeGradGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('hgUtfrandeGradGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/hog_utforandegrad_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {TenantFunctionName} benchmark 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeBenchmark: async (tenant: string, benchmark: TenantFunctionName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('initializeBenchmark', 'tenant', tenant)
            // verify required parameter 'benchmark' is not null or undefined
            assertParamExists('initializeBenchmark', 'benchmark', benchmark)
            const localVarPath = `/v1/benchmarking/{tenant}/initialize-benchmark`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (benchmark !== undefined) {
                localVarQueryParameter['benchmark'] = benchmark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeKoladaData: async (tenant: string, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('initializeKoladaData', 'tenant', tenant)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('initializeKoladaData', 'year', year)
            const localVarPath = `/v1/benchmarking/{tenant}/initialize-kolada-data`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {TenantFunctionName} benchmark 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertBenchmark: async (tenant: string, benchmark: TenantFunctionName, year: number, month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('insertBenchmark', 'tenant', tenant)
            // verify required parameter 'benchmark' is not null or undefined
            assertParamExists('insertBenchmark', 'benchmark', benchmark)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('insertBenchmark', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('insertBenchmark', 'month', month)
            const localVarPath = `/v1/benchmarking/{tenant}/insert-benchmark`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (benchmark !== undefined) {
                localVarQueryParameter['benchmark'] = benchmark;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertMunicipalities: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('insertMunicipalities', 'tenant', tenant)
            const localVarPath = `/v1/municipality/{tenant}/insert-municipalities`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kortaRestider: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('kortaRestider', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('kortaRestider', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/korta_restider`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kortaRestiderGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('kortaRestiderGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('kortaRestiderGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/korta_restider_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        larm: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('larm', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('larm', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('larm', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/larm`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        larmHemtjanst: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('larmHemtjanst', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('larmHemtjanst', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('larmHemtjanst', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/larmHemtjanst`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        larmNotHemtjanst: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('larmNotHemtjanst', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('larmNotHemtjanst', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('larmNotHemtjanst', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/larmNotHemtjanst`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        larmuttryckningar: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('larmuttryckningar', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('larmuttryckningar', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('larmuttryckningar', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/larmuttryckningar`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPerson: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('loadPerson', 'tenant', tenant)
            const localVarPath = `/v1/beslutsstod/{tenant}/load/person`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} loginChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginChallenge: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginChallenge' is not null or undefined
            assertParamExists('login', 'loginChallenge', loginChallenge)
            const localVarPath = `/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loginChallenge !== undefined) {
                localVarQueryParameter['login_challenge'] = loginChallenge;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} logoutChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (logoutChallenge: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logoutChallenge' is not null or undefined
            assertParamExists('logout', 'logoutChallenge', logoutChallenge)
            const localVarPath = `/v1/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (logoutChallenge !== undefined) {
                localVarQueryParameter['logout_challenge'] = logoutChallenge;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mangaAvbjdaInsatserGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('mangaAvbjdaInsatserGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('mangaAvbjdaInsatserGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/manga_avbojda_insatser_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mangaAvbojdaInsatser: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('mangaAvbojdaInsatser', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('mangaAvbojdaInsatser', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/manga_avbojda_insatser`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me: async (tenant: string, idToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('me', 'tenant', tenant)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('me', 'idToken', idToken)
            const localVarPath = `/v1/permission/{tenant}/me`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mngaLarmutryckningar: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('mngaLarmutryckningar', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('mngaLarmutryckningar', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/manga_larmutryckningar`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mngaLarmutryckningarGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('mngaLarmutryckningarGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('mngaLarmutryckningarGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/manga_larmutryckningar_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        municipality: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('municipality', 'tenant', tenant)
            const localVarPath = `/v1/beslutsstod/{tenant}/municipality`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mycketAvbojdTid: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('mycketAvbojdTid', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('mycketAvbojdTid', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/mycket_avbojd_tid`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mycketAvbojdTidGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('mycketAvbojdTidGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('mycketAvbojdTidGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/mycket_avbojd_tid_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        name: async (tenant: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('name', 'tenant', tenant)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('name', 'name', name)
            const localVarPath = `/v1/medarbetare/{tenant}/name/{name}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {INewAvdelningData} iNewAvdelningData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAvdelning: async (tenant: string, iNewAvdelningData: INewAvdelningData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('newAvdelning', 'tenant', tenant)
            // verify required parameter 'iNewAvdelningData' is not null or undefined
            assertParamExists('newAvdelning', 'iNewAvdelningData', iNewAvdelningData)
            const localVarPath = `/v1/handlaggare/{tenant}/avdelning/new`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iNewAvdelningData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCases: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('newCases', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('newCases', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('newCases', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/newCases`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCasesByBrukare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('newCasesByBrukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('newCasesByBrukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('newCasesByBrukare', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/newCasesByBrukare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICreateHandlaggareData} iCreateHandlaggareData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newHandlaggare: async (tenant: string, iCreateHandlaggareData: ICreateHandlaggareData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('newHandlaggare', 'tenant', tenant)
            // verify required parameter 'iCreateHandlaggareData' is not null or undefined
            assertParamExists('newHandlaggare', 'iCreateHandlaggareData', iCreateHandlaggareData)
            const localVarPath = `/v1/handlaggare/{tenant}/new`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCreateHandlaggareData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {INewTeamData} iNewTeamData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newteam: async (tenant: string, iNewTeamData: INewTeamData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('newteam', 'tenant', tenant)
            // verify required parameter 'iNewTeamData' is not null or undefined
            assertParamExists('newteam', 'iNewTeamData', iNewTeamData)
            const localVarPath = `/v1/handlaggare/{tenant}/team/new`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iNewTeamData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nyaBrukare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('nyaBrukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('nyaBrukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('nyaBrukare', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/nyaBrukare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objektanstllning: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('objektanstllning', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('objektanstllning', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/objektanstallning`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objektanstllningGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('objektanstllningGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('objektanstllningGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/objektanstallning_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omfattandeBeslut: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('omfattandeBeslut', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('omfattandeBeslut', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('omfattandeBeslut', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/omfattandeBeslut`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omfattandeBeslutBrukare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('omfattandeBeslutBrukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('omfattandeBeslutBrukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('omfattandeBeslutBrukare', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/omfattandeBeslutBrukare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderedTime: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('orderedTime', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('orderedTime', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('orderedTime', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/orderedTime`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordinarieArbetstidOchvertid: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('ordinarieArbetstidOchvertid', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('ordinarieArbetstidOchvertid', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/ordinarie_arbetstid_och_overtid`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordinarieArbetstidOchvertidGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('ordinarieArbetstidOchvertidGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('ordinarieArbetstidOchvertidGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/ordinarie_arbetstid_och_overtid_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        person: async (tenant: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('person', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('person', 'id', id)
            const localVarPath = `/v1/beslutsstod/{tenant}/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {RegenerateKoladaDataRegeneratorEnum} regenerator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateKoladaData: async (tenant: string, year: number, regenerator: RegenerateKoladaDataRegeneratorEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('regenerateKoladaData', 'tenant', tenant)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('regenerateKoladaData', 'year', year)
            // verify required parameter 'regenerator' is not null or undefined
            assertParamExists('regenerateKoladaData', 'regenerator', regenerator)
            const localVarPath = `/v1/benchmarking/{tenant}/regenerate-kolada-data`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (regenerator !== undefined) {
                localVarQueryParameter['regenerator'] = regenerator;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RegenerateSocialstyrelsenDataTypeEnum} type 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateSocialstyrelsenData: async (tenant: string, type: RegenerateSocialstyrelsenDataTypeEnum, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('regenerateSocialstyrelsenData', 'tenant', tenant)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('regenerateSocialstyrelsenData', 'type', type)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('regenerateSocialstyrelsenData', 'file', file)
            const localVarPath = `/v1/benchmarking/{tenant}/regenerate-socialstyrelsen-data`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rehashNewDemoData: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('rehashNewDemoData', 'tenant', tenant)
            const localVarPath = `/v1/admin/{tenant}/rehash-new-demo-data`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetAssignableRoleRequest} setAssignableRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAssignableRole: async (tenant: string, setAssignableRoleRequest: SetAssignableRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('removeAssignableRole', 'tenant', tenant)
            // verify required parameter 'setAssignableRoleRequest' is not null or undefined
            assertParamExists('removeAssignableRole', 'setAssignableRoleRequest', setAssignableRoleRequest)
            const localVarPath = `/v1/permission/{tenant}/role/assignable`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAssignableRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} authenticationMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAuthenticationMethod: async (tenant: string, authenticationMethod: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('removeAuthenticationMethod', 'tenant', tenant)
            // verify required parameter 'authenticationMethod' is not null or undefined
            assertParamExists('removeAuthenticationMethod', 'authenticationMethod', authenticationMethod)
            const localVarPath = `/v1/tenant/{tenant}/authentication-method/{authenticationMethod}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"authenticationMethod"}}`, encodeURIComponent(String(authenticationMethod)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RemoveFlagsRequest} removeFlagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFlags: async (tenant: string, removeFlagsRequest: RemoveFlagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('removeFlags', 'tenant', tenant)
            // verify required parameter 'removeFlagsRequest' is not null or undefined
            assertParamExists('removeFlags', 'removeFlagsRequest', removeFlagsRequest)
            const localVarPath = `/v1/case/{tenant}/flags/remove`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeFlagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMaxAuthDuration: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('removeMaxAuthDuration', 'tenant', tenant)
            const localVarPath = `/v1/tenant/{tenant}/max-auth-duration`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddModuleToRoleRequest} addModuleToRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeModuleFromRole: async (tenant: string, addModuleToRoleRequest: AddModuleToRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('removeModuleFromRole', 'tenant', tenant)
            // verify required parameter 'addModuleToRoleRequest' is not null or undefined
            assertParamExists('removeModuleFromRole', 'addModuleToRoleRequest', addModuleToRoleRequest)
            const localVarPath = `/v1/permission/{tenant}/role/module`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addModuleToRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RemovePermissionOverrideRequest} removePermissionOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePermissionOverride: async (tenant: string, removePermissionOverrideRequest: RemovePermissionOverrideRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('removePermissionOverride', 'tenant', tenant)
            // verify required parameter 'removePermissionOverrideRequest' is not null or undefined
            assertParamExists('removePermissionOverride', 'removePermissionOverrideRequest', removePermissionOverrideRequest)
            const localVarPath = `/v1/permission/{tenant}/role/permission`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removePermissionOverrideRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePerson: async (tenant: string, body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('savePerson', 'tenant', tenant)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('savePerson', 'body', body)
            const localVarPath = `/v1/beslutsstod/{tenant}/save/person`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scbTest: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('scbTest', 'tenant', tenant)
            const localVarPath = `/v1/scb/{tenant}/test`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTags: async (tenant: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('searchTags', 'tenant', tenant)
            const localVarPath = `/v1/municipality/{tenant}/search-tags`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetAssignableRoleRequest} setAssignableRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAssignableRole: async (tenant: string, setAssignableRoleRequest: SetAssignableRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('setAssignableRole', 'tenant', tenant)
            // verify required parameter 'setAssignableRoleRequest' is not null or undefined
            assertParamExists('setAssignableRole', 'setAssignableRoleRequest', setAssignableRoleRequest)
            const localVarPath = `/v1/permission/{tenant}/role/assignable`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setAssignableRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetLifespansRequest} setLifespansRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLifespans: async (tenant: string, setLifespansRequest: SetLifespansRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('setLifespans', 'tenant', tenant)
            // verify required parameter 'setLifespansRequest' is not null or undefined
            assertParamExists('setLifespans', 'setLifespansRequest', setLifespansRequest)
            const localVarPath = `/v1/auth/{tenant}/lifespans`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLifespansRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetMaxAuthDuration200Response} getMaxAuthDuration200Response 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMaxAuthDuration: async (tenant: string, getMaxAuthDuration200Response: GetMaxAuthDuration200Response, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('setMaxAuthDuration', 'tenant', tenant)
            // verify required parameter 'getMaxAuthDuration200Response' is not null or undefined
            assertParamExists('setMaxAuthDuration', 'getMaxAuthDuration200Response', getMaxAuthDuration200Response)
            const localVarPath = `/v1/tenant/{tenant}/max-auth-duration`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMaxAuthDuration200Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        structure: async (tenant: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('structure', 'tenant', tenant)
            const localVarPath = `/v1/handlaggare/{tenant}/structure`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeOfCanceledCases: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('timeOfCanceledCases', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('timeOfCanceledCases', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('timeOfCanceledCases', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/timeOfCanceledCases`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeOfNewBrukareCases: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('timeOfNewBrukareCases', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('timeOfNewBrukareCases', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('timeOfNewBrukareCases', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/timeOfNewBrukareCases`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {IUpdateHandlaggareData} iUpdateHandlaggareData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (tenant: string, iUpdateHandlaggareData: IUpdateHandlaggareData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('update', 'tenant', tenant)
            // verify required parameter 'iUpdateHandlaggareData' is not null or undefined
            assertParamExists('update', 'iUpdateHandlaggareData', iUpdateHandlaggareData)
            const localVarPath = `/v1/handlaggare/{tenant}/update`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUpdateHandlaggareData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (tenant: string, id: number, updateRoleRequest: UpdateRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateRole', 'tenant', tenant)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRole', 'id', id)
            // verify required parameter 'updateRoleRequest' is not null or undefined
            assertParamExists('updateRole', 'updateRoleRequest', updateRoleRequest)
            const localVarPath = `/v1/permission/{tenant}/roles/{id}`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {IRequestBody} iRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPermissions: async (tenant: string, iRequestBody: IRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('updateUserPermissions', 'tenant', tenant)
            // verify required parameter 'iRequestBody' is not null or undefined
            assertParamExists('updateUserPermissions', 'iRequestBody', iRequestBody)
            const localVarPath = `/v1/permission/{tenant}/user/update/role`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utfordPerBrukare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('utfordPerBrukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('utfordPerBrukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('utfordPerBrukare', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/utfordPerBrukare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utfordTid: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('utfordTid', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('utfordTid', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('utfordTid', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/utfordTid`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utfordTidPerBrukare: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('utfordTidPerBrukare', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('utfordTidPerBrukare', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('utfordTidPerBrukare', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/utfordTidPerBrukare`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utfordaNattinsatser: async (tenant: string, from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('utfordaNattinsatser', 'tenant', tenant)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('utfordaNattinsatser', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('utfordaNattinsatser', 'to', to)
            const localVarPath = `/v1/benchmark/{tenant}/utfordaNattinsatser`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veckovila: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('veckovila', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('veckovila', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/veckovila`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veckovilaGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('veckovilaGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('veckovilaGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/veckovila_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verlappandeBesk: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('verlappandeBesk', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('verlappandeBesk', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/overlappande_besok`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verlappandeBeskGenerate: async (tenant: string, runDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenant' is not null or undefined
            assertParamExists('verlappandeBeskGenerate', 'tenant', tenant)
            // verify required parameter 'runDate' is not null or undefined
            assertParamExists('verlappandeBeskGenerate', 'runDate', runDate)
            const localVarPath = `/v1/flags/{tenant}/overlappande_besok_generate`
                .replace(`{${"tenant"}}`, encodeURIComponent(String(tenant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (runDate !== undefined) {
                localVarQueryParameter['run_date'] = runDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhook: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('webhook', 'body', body)
            const localVarPath = `/v1/auth/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _new(tenant: string, body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._new(tenant, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi._new']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddAuthenticationMethodRequest} addAuthenticationMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAuthenticationMethod(tenant: string, addAuthenticationMethodRequest: AddAuthenticationMethodRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAuthenticationMethod(tenant, addAuthenticationMethodRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.addAuthenticationMethod']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddFlagsRequest} addFlagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFlags(tenant: string, addFlagsRequest: AddFlagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFlags(tenant, addFlagsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.addFlags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddModuleToRoleRequest} addModuleToRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addModuleToRole(tenant: string, addModuleToRoleRequest: AddModuleToRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addModuleToRole(tenant, addModuleToRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.addModuleToRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMunicipalitiesToTag(tenant: string, tagId: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMunicipalitiesToTag(tenant, tagId, requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.addMunicipalitiesToTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddPermissionOverrideRequest} addPermissionOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPermissionOverride(tenant: string, addPermissionOverrideRequest: AddPermissionOverrideRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPermissionOverride(tenant, addPermissionOverrideRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.addPermissionOverride']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRole(tenant: string, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddRole200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRole(tenant, updateRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.addRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async all(tenant: string, from: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.all(tenant, from, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.all']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allCases(tenant: string, idToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allCases(tenant, idToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.allCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ammountBrukareCanceledCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AntalBrukareBeviljad200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ammountBrukareCanceledCases(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.ammountBrukareCanceledCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async antalBrukareBeviljad(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AntalBrukareBeviljad200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.antalBrukareBeviljad(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.antalBrukareBeviljad']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archive(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archive(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.archive']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(tenant: string, authenticateRequest: AuthenticateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGuestUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(tenant, authenticateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.authenticate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async avgAge(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.avgAge(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.avgAge']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async avgAgeCanceledCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.avgAgeCanceledCases(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.avgAgeCanceledCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async avgAgeNewCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.avgAgeNewCases(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.avgAgeNewCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beslutsomfattningPerInterval(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beslutsomfattningPerInterval(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.beslutsomfattningPerInterval']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bestalldOmvardnad(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AntalBrukareBeviljad200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bestalldOmvardnad(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.bestalldOmvardnad']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bestalldService(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AntalBrukareBeviljad200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bestalldService(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.bestalldService']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bestalldTidPerBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bestalldTidPerBrukare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.bestalldTidPerBrukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bevlijadeLarmNattinsattser(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bevlijadeLarmNattinsattser(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.bevlijadeLarmNattinsattser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brukareBestalldTid(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brukareBestalldTid(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.brukareBestalldTid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brukareCount(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brukareCount(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.brukareCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} namn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byName(tenant: string, from: string, to: string, namn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBaseFlagQueryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byName(tenant, from, to, namn, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.byName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} personnummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async byPersonnummer(tenant: string, personnummer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllBrukare200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.byPersonnummer(tenant, personnummer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.byPersonnummer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} owner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bygare(tenant: string, owner: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ICaseData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bygare(tenant, owner, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.bygare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculate(tenant: string, body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculate(tenant, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.calculate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callback(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callback(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.callback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canceledCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canceledCases(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.canceledCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canceledCasesByBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.canceledCasesByBrukare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.canceledCasesByBrukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearAllFlags(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearAllFlags(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.clearAllFlags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} flag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearFlag(tenant: string, flag: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearFlag(tenant, flag, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.clearFlag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICommentData} iCommentData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async close(tenant: string, iCommentData: ICommentData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.close(tenant, iCommentData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.close']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICommentData} iCommentData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async comment(tenant: string, iCommentData: ICommentData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.comment(tenant, iCommentData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.comment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} consentChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consent(consentChallenge: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consent(consentChallenge, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.consent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICreateCaseData} iCreateCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(tenant: string, iCreateCaseData: ICreateCaseData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(tenant, iCreateCaseData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateGuestUserRequest} createGuestUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGuestUser(tenant: string, createGuestUserRequest: CreateGuestUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGuestUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGuestUser(tenant, createGuestUserRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.createGuestUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(tenant: string, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(tenant, updateRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.createTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllBenchmarks(tenant: string, year: number, month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertBenchmark200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllBenchmarks(tenant, year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteAllBenchmarks']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGuestUser(tenant: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertBenchmark200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGuestUser(tenant, username, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteGuestUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTag(tenant: string, tagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(tenant, tagId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async descriptions(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.descriptions(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.descriptions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPdf(tenant: string, body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPdf(tenant, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.downloadPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dygnsvila(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dygnsvila(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.dygnsvila']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dygnsvilaGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dygnsvilaGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.dygnsvilaGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {EditGuestUserRequest} editGuestUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editGuestUser(tenant: string, username: string, editGuestUserRequest: EditGuestUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertBenchmark200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editGuestUser(tenant, username, editGuestUserRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.editGuestUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editTag(tenant: string, tagId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editTag(tenant, tagId, name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.editTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f10all(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f10all(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f10all']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f15all(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f15all(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f15all']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f16all(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f16all(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f16all']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f17all(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f17all(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f17all']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f2All(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<F2All200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f2All(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f2All']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} brukare 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f2Brukare(tenant: string, from: string, to: string, brukare: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<F2Brukare200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f2Brukare(tenant, from, to, brukare, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f2Brukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f4All(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f4All(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f4All']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f7(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IMedarbetareQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f7(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f7']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} medarbetare 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f7Medarbetare(tenant: string, from: string, to: string, medarbetare: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<F7Medarbetare200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f7Medarbetare(tenant, from, to, medarbetare, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f7Medarbetare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f8All(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBrukareQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f8All(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f8All']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f9All(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f9All(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.f9All']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAll(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAll(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.generateAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAuthenticationMethods(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuthenticationMethods(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAllAuthenticationMethods']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllBrukare200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBrukare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAllBrukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMedarbetare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllBrukare200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMedarbetare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAllMedarbetare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUtforare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllUtforare200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUtforare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAllUtforare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllowedAuthenticationMethods(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllowedAuthenticationMethods(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAllowedAuthenticationMethods']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverage(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAverageSpecific200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverage(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAverage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {number} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageSpecific(tenant: string, from: string, to: string, level: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAverageSpecific200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageSpecific(tenant, from, to, level, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAverageSpecific']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrukareOutsideStructure(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IOutsideTeamStructure>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrukareOutsideStructure(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getBrukareOutsideStructure']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapacityInflowOutflowTrendValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCapacityInflowOutflowTrendValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapacityInflowOutflowTrendValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCapacityInflowOutflowTrendValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostQualityMixValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCostQualityMixValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostQualityMixValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCostQualityMixValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCostValues(tenant: string, variantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCostValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCostValues(tenant, variantId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCostValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} frn 
         * @param {string} till 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistribution(tenant: string, frn: string, till: string, idToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDistribution(tenant, frn, till, idToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getDistribution']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuestUser(tenant: string, username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuestUsers200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestUser(tenant, username, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getGuestUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuestUsers(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetGuestUsers200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuestUsers(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getGuestUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareHoursTrendValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetHomeCareHoursTrendValues200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareHoursTrendValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareHoursTrendValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareOrderedHoursAgeGroupValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursAgeGroupValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareOrderedHoursAgeGroupValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareOrderedHoursAgeGroupValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareOrderedHoursIntervalComparisonValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursIntervalComparisonValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareOrderedHoursIntervalComparisonValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareOrderedHoursIntervalComparisonValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareOrderedHoursIntervalValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursIntervalValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareOrderedHoursIntervalValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareOrderedHoursIntervalValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareOrderedHoursPerPersonValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursPerPersonValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareOrderedHoursPerPersonValues(tenant, variantId, dataSource, grouping, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareOrderedHoursPerPersonValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareOrderedHoursProportionIntervalValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursIntervalValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareOrderedHoursProportionIntervalValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareOrderedHoursProportionIntervalValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareOrderedHoursTrendMixedValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursTrendMixedValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareOrderedHoursTrendMixedValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareOrderedHoursTrendMixedValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareOrderedHoursValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareOrderedHoursValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareOrderedHoursValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCarePerformedHoursTrendMixedValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursTrendMixedValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCarePerformedHoursTrendMixedValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCarePerformedHoursTrendMixedValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCarePerformedHoursValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCarePerformedHoursValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCarePerformedHoursValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCarePerformedHoursValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetHomeCareProportionAgeGroupValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareProportionAgeGroupValues(tenant: string, variant?: GetHomeCareProportionAgeGroupValuesVariantEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareProportionAgeGroupValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareProportionAgeGroupValues(tenant, variant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareProportionAgeGroupValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareProportionValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareOrderedHoursPerPersonValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareProportionValues(tenant, variantId, dataSource, grouping, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareProportionValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareResidencyCostMixValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCostQualityMixValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareResidencyCostMixValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareResidencyCostMixValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareVolumeAgeGroupTrendValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetHomeCareVolumeAgeGroupTrendValues200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareVolumeAgeGroupTrendValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareVolumeAgeGroupTrendValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeCareVolumeAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareVolumeAgeGroupValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeCareVolumeAgeGroupValues(tenant, variantId, dataSource, grouping, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getHomeCareVolumeAgeGroupValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInflowAgeGroupValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInflowAgeGroupValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInflowAgeGroupValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getInflowAgeGroupValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInflowProportionAgeGroupTrendValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetInflowProportionAgeGroupTrendValues200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInflowProportionAgeGroupTrendValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getInflowProportionAgeGroupTrendValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLifespans(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLifespans200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLifespans(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getLifespans']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaxAuthDuration(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMaxAuthDuration200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaxAuthDuration(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMaxAuthDuration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(tenant: string, idToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IHandlaggareWithPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(tenant, idToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMe']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModules(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetRoles200ResponseInnerAssignableRolesInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModules(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getModules']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} frn 
         * @param {string} till 
         * @param {string} idToken 
         * @param {string} otherPersonnummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonthlyVolume2Other(tenant: string, frn: string, till: string, idToken: string, otherPersonnummer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMonthlyVolume2Other200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonthlyVolume2Other(tenant, frn, till, idToken, otherPersonnummer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMonthlyVolume2Other']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMunicipalities(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMunicipalities200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMunicipalities(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMunicipalities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMunicipalityName(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMunicipalityName(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMunicipalityName']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyCases(tenant: string, date: string, idToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFormattedData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyCases(tenant, date, idToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMyCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNursingDementiaProportionValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNursingDementiaProportionValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNursingDementiaProportionValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getNursingDementiaProportionValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetNursingDementiaTotalStayLengthValuesAggregationEnum} [aggregation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNursingDementiaTotalStayLengthValues(tenant: string, aggregation?: GetNursingDementiaTotalStayLengthValuesAggregationEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNursingDementiaTotalStayLengthValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNursingDementiaTotalStayLengthValues(tenant, aggregation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getNursingDementiaTotalStayLengthValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNursingDementiaTotalTrendValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNursingDementiaTotalTrendValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNursingDementiaTotalTrendValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getNursingDementiaTotalTrendValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} otherPersonnummer 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOtherCases(tenant: string, date: string, otherPersonnummer: string, idToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFormattedData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOtherCases(tenant, date, otherPersonnummer, idToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getOtherCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} otherPersonnummer 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOtherCases2(tenant: string, date: string, otherPersonnummer: string, idToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFormattedData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOtherCases2(tenant, date, otherPersonnummer, idToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getOtherCases2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissions(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetRoles200ResponseInnerAssignableRolesInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissions(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getPermissions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} ansvarigPerson 
         * @param {number} dateGap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentlyExpired(tenant: string, ansvarigPerson: string, dateGap: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<INewlyExpiredCases>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentlyExpired(tenant, ansvarigPerson, dateGap, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getRecentlyExpired']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResidencyCapacityAgeGroupTrendValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetResidencyCapacityAgeGroupTrendValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResidencyCapacityAgeGroupTrendValues(tenant, variantId, dataSource, grouping, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getResidencyCapacityAgeGroupTrendValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResidencyCapacityAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareVolumeAgeGroupValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResidencyCapacityAgeGroupValues(tenant, variantId, dataSource, grouping, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getResidencyCapacityAgeGroupValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetResidencyPopulationProportionValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResidencyPopulationProportionValues(tenant: string, variant?: GetResidencyPopulationProportionValuesVariantEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCarePerformedHoursValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResidencyPopulationProportionValues(tenant, variant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getResidencyPopulationProportionValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetResidencyProportionAgeGroupValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResidencyProportionAgeGroupValues(tenant: string, variant?: GetResidencyProportionAgeGroupValuesVariantEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeCareProportionAgeGroupValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResidencyProportionAgeGroupValues(tenant, variant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getResidencyProportionAgeGroupValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoles200ResponseInner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRole(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetRoles200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getRoles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetStayLengthTrendValuesSpecializationEnum} specialization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStayLengthTrendValues(tenant: string, specialization: GetStayLengthTrendValuesSpecializationEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetStayLengthTrendValues200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStayLengthTrendValues(tenant, specialization, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getStayLengthTrendValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedBenchmarks(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSupportedBenchmarks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupportedBenchmarks(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getSupportedBenchmarks']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTag(tenant: string, tagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTag(tenant, tagId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag10(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag10200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag10(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag10']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag15(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag2200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag15(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag15']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag16(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag2200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag16(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag16']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag17(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag17200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag17(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag17']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag18(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag18200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag18(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag18']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag19(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag18200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag19(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag19']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag2(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag2200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag2(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag20(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag2200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag20(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag20']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag4(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag4(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag4']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag7(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag7200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag7(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag7']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag8(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag8200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag8(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag8']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextForFlag9(tenant: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTextForFlag2200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextForFlag9(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTextForFlag9']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumeAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVolumeAgeGroupValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumeAgeGroupValues(tenant, variantId, dataSource, grouping, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getVolumeAgeGroupValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumeInsatsMixValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCostQualityMixValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumeInsatsMixValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getVolumeInsatsMixValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumeProportionAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVolumeProportionAgeGroupValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumeProportionAgeGroupValues(tenant, variantId, dataSource, grouping, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getVolumeProportionAgeGroupValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumeQualityMixValues(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCostQualityMixValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumeQualityMixValues(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getVolumeQualityMixValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumeValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVolumeValues200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumeValues(tenant, variantId, dataSource, grouping, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getVolumeValues']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GuestRequest} guestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guest(guestRequest: GuestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guest(guestRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.guest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.health']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hgKontinuitet(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hgKontinuitet(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.hgKontinuitet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hgKontinuitetGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hgKontinuitetGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.hgKontinuitetGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hgUtfrandeGrad(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hgUtfrandeGrad(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.hgUtfrandeGrad']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hgUtfrandeGradGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hgUtfrandeGradGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.hgUtfrandeGradGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {TenantFunctionName} benchmark 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeBenchmark(tenant: string, benchmark: TenantFunctionName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitializeBenchmark200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeBenchmark(tenant, benchmark, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.initializeBenchmark']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeKoladaData(tenant: string, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeKoladaData(tenant, year, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.initializeKoladaData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {TenantFunctionName} benchmark 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertBenchmark(tenant: string, benchmark: TenantFunctionName, year: number, month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertBenchmark200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertBenchmark(tenant, benchmark, year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.insertBenchmark']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertMunicipalities(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsertBenchmark200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertMunicipalities(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.insertMunicipalities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kortaRestider(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kortaRestider(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.kortaRestider']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kortaRestiderGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kortaRestiderGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.kortaRestiderGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async larm(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.larm(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.larm']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async larmHemtjanst(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.larmHemtjanst(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.larmHemtjanst']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async larmNotHemtjanst(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.larmNotHemtjanst(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.larmNotHemtjanst']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async larmuttryckningar(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.larmuttryckningar(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.larmuttryckningar']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadPerson(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadPerson(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.loadPerson']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} loginChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginChallenge: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginChallenge, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.login']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} logoutChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(logoutChallenge: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(logoutChallenge, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.logout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mangaAvbjdaInsatserGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mangaAvbjdaInsatserGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.mangaAvbjdaInsatserGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mangaAvbojdaInsatser(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mangaAvbojdaInsatser(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.mangaAvbojdaInsatser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me(tenant: string, idToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Me200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me(tenant, idToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.me']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mngaLarmutryckningar(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mngaLarmutryckningar(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.mngaLarmutryckningar']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mngaLarmutryckningarGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mngaLarmutryckningarGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.mngaLarmutryckningarGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async municipality(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Municipality200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.municipality(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.municipality']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mycketAvbojdTid(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mycketAvbojdTid(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.mycketAvbojdTid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mycketAvbojdTidGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mycketAvbojdTidGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.mycketAvbojdTidGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async name(tenant: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAllBrukare200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.name(tenant, name, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.name']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {INewAvdelningData} iNewAvdelningData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newAvdelning(tenant: string, iNewAvdelningData: INewAvdelningData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newAvdelning(tenant, iNewAvdelningData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.newAvdelning']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newCases(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.newCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newCasesByBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newCasesByBrukare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.newCasesByBrukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICreateHandlaggareData} iCreateHandlaggareData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newHandlaggare(tenant: string, iCreateHandlaggareData: ICreateHandlaggareData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newHandlaggare(tenant, iCreateHandlaggareData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.newHandlaggare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {INewTeamData} iNewTeamData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newteam(tenant: string, iNewTeamData: INewTeamData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newteam(tenant, iNewTeamData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.newteam']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nyaBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nyaBrukare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.nyaBrukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objektanstllning(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objektanstllning(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.objektanstllning']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objektanstllningGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objektanstllningGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.objektanstllningGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async omfattandeBeslut(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.omfattandeBeslut(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.omfattandeBeslut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async omfattandeBeslutBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OmfattandeBeslutBrukare200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.omfattandeBeslutBrukare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.omfattandeBeslutBrukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderedTime(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderedTime(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.orderedTime']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordinarieArbetstidOchvertid(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordinarieArbetstidOchvertid(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.ordinarieArbetstidOchvertid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordinarieArbetstidOchvertidGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordinarieArbetstidOchvertidGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.ordinarieArbetstidOchvertidGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async person(tenant: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.person(tenant, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.person']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {RegenerateKoladaDataRegeneratorEnum} regenerator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateKoladaData(tenant: string, year: number, regenerator: RegenerateKoladaDataRegeneratorEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateKoladaData(tenant, year, regenerator, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.regenerateKoladaData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RegenerateSocialstyrelsenDataTypeEnum} type 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerateSocialstyrelsenData(tenant: string, type: RegenerateSocialstyrelsenDataTypeEnum, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regenerateSocialstyrelsenData(tenant, type, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.regenerateSocialstyrelsenData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rehashNewDemoData(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rehashNewDemoData(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.rehashNewDemoData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetAssignableRoleRequest} setAssignableRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAssignableRole(tenant: string, setAssignableRoleRequest: SetAssignableRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAssignableRole(tenant, setAssignableRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.removeAssignableRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} authenticationMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAuthenticationMethod(tenant: string, authenticationMethod: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAuthenticationMethod(tenant, authenticationMethod, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.removeAuthenticationMethod']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RemoveFlagsRequest} removeFlagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFlags(tenant: string, removeFlagsRequest: RemoveFlagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFlags(tenant, removeFlagsRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.removeFlags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMaxAuthDuration(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMaxAuthDuration(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.removeMaxAuthDuration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddModuleToRoleRequest} addModuleToRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeModuleFromRole(tenant: string, addModuleToRoleRequest: AddModuleToRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeModuleFromRole(tenant, addModuleToRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.removeModuleFromRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RemovePermissionOverrideRequest} removePermissionOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePermissionOverride(tenant: string, removePermissionOverrideRequest: RemovePermissionOverrideRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removePermissionOverride(tenant, removePermissionOverrideRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.removePermissionOverride']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePerson(tenant: string, body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePerson(tenant, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.savePerson']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scbTest(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scbTest(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.scbTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTags(tenant: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchTags200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTags(tenant, search, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.searchTags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetAssignableRoleRequest} setAssignableRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAssignableRole(tenant: string, setAssignableRoleRequest: SetAssignableRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAssignableRole(tenant, setAssignableRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.setAssignableRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetLifespansRequest} setLifespansRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLifespans(tenant: string, setLifespansRequest: SetLifespansRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLifespans200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLifespans(tenant, setLifespansRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.setLifespans']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetMaxAuthDuration200Response} getMaxAuthDuration200Response 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMaxAuthDuration(tenant: string, getMaxAuthDuration200Response: GetMaxAuthDuration200Response, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMaxAuthDuration(tenant, getMaxAuthDuration200Response, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.setMaxAuthDuration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async structure(tenant: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Structure200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.structure(tenant, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.structure']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timeOfCanceledCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timeOfCanceledCases(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.timeOfCanceledCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timeOfNewBrukareCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timeOfNewBrukareCases(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.timeOfNewBrukareCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {IUpdateHandlaggareData} iUpdateHandlaggareData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(tenant: string, iUpdateHandlaggareData: IUpdateHandlaggareData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(tenant, iUpdateHandlaggareData, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(tenant: string, id: number, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(tenant, id, updateRoleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {IRequestBody} iRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPermissions(tenant: string, iRequestBody: IRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPermissions(tenant, iRequestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateUserPermissions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utfordPerBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utfordPerBrukare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.utfordPerBrukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utfordTid(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UtfordTid200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utfordTid(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.utfordTid']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utfordTidPerBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utfordTidPerBrukare(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.utfordTidPerBrukare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utfordaNattinsatser(tenant: string, from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utfordaNattinsatser(tenant, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.utfordaNattinsatser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.validate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async veckovila(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.veckovila(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.veckovila']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async veckovilaGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.veckovilaGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.veckovilaGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verlappandeBesk(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verlappandeBesk(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.verlappandeBesk']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verlappandeBeskGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IFlagResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verlappandeBeskGenerate(tenant, runDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.verlappandeBeskGenerate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhook(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhook(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.webhook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _new(tenant: string, body: any, options?: any): AxiosPromise<void> {
            return localVarFp._new(tenant, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddAuthenticationMethodRequest} addAuthenticationMethodRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAuthenticationMethod(tenant: string, addAuthenticationMethodRequest: AddAuthenticationMethodRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addAuthenticationMethod(tenant, addAuthenticationMethodRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddFlagsRequest} addFlagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlags(tenant: string, addFlagsRequest: AddFlagsRequest, options?: any): AxiosPromise<any> {
            return localVarFp.addFlags(tenant, addFlagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddModuleToRoleRequest} addModuleToRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addModuleToRole(tenant: string, addModuleToRoleRequest: AddModuleToRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addModuleToRole(tenant, addModuleToRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMunicipalitiesToTag(tenant: string, tagId: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.addMunicipalitiesToTag(tenant, tagId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddPermissionOverrideRequest} addPermissionOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPermissionOverride(tenant: string, addPermissionOverrideRequest: AddPermissionOverrideRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addPermissionOverride(tenant, addPermissionOverrideRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRole(tenant: string, updateRoleRequest: UpdateRoleRequest, options?: any): AxiosPromise<AddRole200Response> {
            return localVarFp.addRole(tenant, updateRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        all(tenant: string, from: string, options?: any): AxiosPromise<any> {
            return localVarFp.all(tenant, from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCases(tenant: string, idToken: string, options?: any): AxiosPromise<any> {
            return localVarFp.allCases(tenant, idToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ammountBrukareCanceledCases(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<AntalBrukareBeviljad200ResponseInner>> {
            return localVarFp.ammountBrukareCanceledCases(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        antalBrukareBeviljad(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<AntalBrukareBeviljad200ResponseInner>> {
            return localVarFp.antalBrukareBeviljad(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(tenant: string, options?: any): AxiosPromise<Webhook200Response> {
            return localVarFp.archive(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(tenant: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<CreateGuestUser200Response> {
            return localVarFp.authenticate(tenant, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avgAge(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.avgAge(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avgAgeCanceledCases(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.avgAgeCanceledCases(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        avgAgeNewCases(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.avgAgeNewCases(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beslutsomfattningPerInterval(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.beslutsomfattningPerInterval(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestalldOmvardnad(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<AntalBrukareBeviljad200ResponseInner>> {
            return localVarFp.bestalldOmvardnad(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestalldService(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<AntalBrukareBeviljad200ResponseInner>> {
            return localVarFp.bestalldService(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bestalldTidPerBrukare(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.bestalldTidPerBrukare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bevlijadeLarmNattinsattser(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.bevlijadeLarmNattinsattser(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brukareBestalldTid(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.brukareBestalldTid(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brukareCount(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.brukareCount(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} namn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byName(tenant: string, from: string, to: string, namn: string, options?: any): AxiosPromise<Array<IBaseFlagQueryResponse>> {
            return localVarFp.byName(tenant, from, to, namn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} personnummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        byPersonnummer(tenant: string, personnummer: string, options?: any): AxiosPromise<Array<GetAllBrukare200ResponseInner>> {
            return localVarFp.byPersonnummer(tenant, personnummer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} owner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bygare(tenant: string, owner: number, options?: any): AxiosPromise<Array<ICaseData>> {
            return localVarFp.bygare(tenant, owner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculate(tenant: string, body: any, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.calculate(tenant, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callback(tenant: string, options?: any): AxiosPromise<void> {
            return localVarFp.callback(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canceledCases(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.canceledCases(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canceledCasesByBrukare(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.canceledCasesByBrukare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllFlags(tenant: string, options?: any): AxiosPromise<string> {
            return localVarFp.clearAllFlags(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} flag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFlag(tenant: string, flag: number, options?: any): AxiosPromise<string> {
            return localVarFp.clearFlag(tenant, flag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICommentData} iCommentData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        close(tenant: string, iCommentData: ICommentData, options?: any): AxiosPromise<any> {
            return localVarFp.close(tenant, iCommentData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICommentData} iCommentData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        comment(tenant: string, iCommentData: ICommentData, options?: any): AxiosPromise<any> {
            return localVarFp.comment(tenant, iCommentData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} consentChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consent(consentChallenge: string, options?: any): AxiosPromise<void> {
            return localVarFp.consent(consentChallenge, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICreateCaseData} iCreateCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(tenant: string, iCreateCaseData: ICreateCaseData, options?: any): AxiosPromise<any> {
            return localVarFp.create(tenant, iCreateCaseData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {CreateGuestUserRequest} createGuestUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGuestUser(tenant: string, createGuestUserRequest: CreateGuestUserRequest, options?: any): AxiosPromise<CreateGuestUser200Response> {
            return localVarFp.createGuestUser(tenant, createGuestUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(tenant: string, updateRoleRequest: UpdateRoleRequest, options?: any): AxiosPromise<Tag> {
            return localVarFp.createTag(tenant, updateRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllBenchmarks(tenant: string, year: number, month: number, options?: any): AxiosPromise<InsertBenchmark200Response> {
            return localVarFp.deleteAllBenchmarks(tenant, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGuestUser(tenant: string, username: string, options?: any): AxiosPromise<InsertBenchmark200Response> {
            return localVarFp.deleteGuestUser(tenant, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(tenant: string, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRole(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(tenant: string, tagId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTag(tenant, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        descriptions(tenant: string, options?: any): AxiosPromise<any> {
            return localVarFp.descriptions(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdf(tenant: string, body: any, options?: any): AxiosPromise<void> {
            return localVarFp.downloadPdf(tenant, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dygnsvila(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.dygnsvila(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dygnsvilaGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.dygnsvilaGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {EditGuestUserRequest} editGuestUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editGuestUser(tenant: string, username: string, editGuestUserRequest: EditGuestUserRequest, options?: any): AxiosPromise<InsertBenchmark200Response> {
            return localVarFp.editGuestUser(tenant, username, editGuestUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTag(tenant: string, tagId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.editTag(tenant, tagId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f10all(tenant: string, from: string, to: string, options?: any): AxiosPromise<IResult> {
            return localVarFp.f10all(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f15all(tenant: string, from: string, to: string, options?: any): AxiosPromise<IResult> {
            return localVarFp.f15all(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f16all(tenant: string, from: string, to: string, options?: any): AxiosPromise<IResult> {
            return localVarFp.f16all(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f17all(tenant: string, from: string, to: string, options?: any): AxiosPromise<IResult> {
            return localVarFp.f17all(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f2All(tenant: string, from: string, to: string, options?: any): AxiosPromise<F2All200Response> {
            return localVarFp.f2All(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} brukare 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f2Brukare(tenant: string, from: string, to: string, brukare: string, options?: any): AxiosPromise<F2Brukare200Response> {
            return localVarFp.f2Brukare(tenant, from, to, brukare, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f4All(tenant: string, from: string, to: string, options?: any): AxiosPromise<IResult> {
            return localVarFp.f4All(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f7(tenant: string, from: string, to: string, options?: any): AxiosPromise<IMedarbetareQueryResult> {
            return localVarFp.f7(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {string} medarbetare 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f7Medarbetare(tenant: string, from: string, to: string, medarbetare: string, options?: any): AxiosPromise<F7Medarbetare200Response> {
            return localVarFp.f7Medarbetare(tenant, from, to, medarbetare, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f8All(tenant: string, from: string, to: string, options?: any): AxiosPromise<IBrukareQueryResult> {
            return localVarFp.f8All(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f9All(tenant: string, from: string, to: string, options?: any): AxiosPromise<IResult> {
            return localVarFp.f9All(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAll(tenant: string, runDate: string, options?: any): AxiosPromise<Array<FlagResult>> {
            return localVarFp.generateAll(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthenticationMethods(tenant: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllAuthenticationMethods(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrukare(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<GetAllBrukare200ResponseInner>> {
            return localVarFp.getAllBrukare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMedarbetare(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<GetAllBrukare200ResponseInner>> {
            return localVarFp.getAllMedarbetare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUtforare(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<GetAllUtforare200ResponseInner>> {
            return localVarFp.getAllUtforare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedAuthenticationMethods(tenant: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllowedAuthenticationMethods(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverage(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<GetAverageSpecific200ResponseInner>> {
            return localVarFp.getAverage(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {number} level 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageSpecific(tenant: string, from: string, to: string, level: number, options?: any): AxiosPromise<Array<GetAverageSpecific200ResponseInner>> {
            return localVarFp.getAverageSpecific(tenant, from, to, level, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrukareOutsideStructure(tenant: string, options?: any): AxiosPromise<Array<IOutsideTeamStructure>> {
            return localVarFp.getBrukareOutsideStructure(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapacityInflowOutflowTrendValues(tenant: string, options?: any): AxiosPromise<GetCapacityInflowOutflowTrendValues200Response> {
            return localVarFp.getCapacityInflowOutflowTrendValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostQualityMixValues(tenant: string, options?: any): AxiosPromise<GetCostQualityMixValues200Response> {
            return localVarFp.getCostQualityMixValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCostValues(tenant: string, variantId?: string, options?: any): AxiosPromise<GetCostValues200Response> {
            return localVarFp.getCostValues(tenant, variantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} frn 
         * @param {string} till 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistribution(tenant: string, frn: string, till: string, idToken: string, options?: any): AxiosPromise<any> {
            return localVarFp.getDistribution(tenant, frn, till, idToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestUser(tenant: string, username: string, options?: any): AxiosPromise<GetGuestUsers200ResponseInner> {
            return localVarFp.getGuestUser(tenant, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuestUsers(tenant: string, options?: any): AxiosPromise<Array<GetGuestUsers200ResponseInner>> {
            return localVarFp.getGuestUsers(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareHoursTrendValues(tenant: string, options?: any): AxiosPromise<Array<GetHomeCareHoursTrendValues200ResponseInner>> {
            return localVarFp.getHomeCareHoursTrendValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursAgeGroupValues(tenant: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursAgeGroupValues200Response> {
            return localVarFp.getHomeCareOrderedHoursAgeGroupValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursIntervalComparisonValues(tenant: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursIntervalComparisonValues200Response> {
            return localVarFp.getHomeCareOrderedHoursIntervalComparisonValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursIntervalValues(tenant: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursIntervalValues200Response> {
            return localVarFp.getHomeCareOrderedHoursIntervalValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursPerPersonValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursPerPersonValues200Response> {
            return localVarFp.getHomeCareOrderedHoursPerPersonValues(tenant, variantId, dataSource, grouping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursProportionIntervalValues(tenant: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursIntervalValues200Response> {
            return localVarFp.getHomeCareOrderedHoursProportionIntervalValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursTrendMixedValues(tenant: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursTrendMixedValues200Response> {
            return localVarFp.getHomeCareOrderedHoursTrendMixedValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareOrderedHoursValues(tenant: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursValues200Response> {
            return localVarFp.getHomeCareOrderedHoursValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCarePerformedHoursTrendMixedValues(tenant: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursTrendMixedValues200Response> {
            return localVarFp.getHomeCarePerformedHoursTrendMixedValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCarePerformedHoursValues(tenant: string, options?: any): AxiosPromise<GetHomeCarePerformedHoursValues200Response> {
            return localVarFp.getHomeCarePerformedHoursValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetHomeCareProportionAgeGroupValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareProportionAgeGroupValues(tenant: string, variant?: GetHomeCareProportionAgeGroupValuesVariantEnum, options?: any): AxiosPromise<GetHomeCareProportionAgeGroupValues200Response> {
            return localVarFp.getHomeCareProportionAgeGroupValues(tenant, variant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareProportionValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: any): AxiosPromise<GetHomeCareOrderedHoursPerPersonValues200Response> {
            return localVarFp.getHomeCareProportionValues(tenant, variantId, dataSource, grouping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareResidencyCostMixValues(tenant: string, options?: any): AxiosPromise<GetCostQualityMixValues200Response> {
            return localVarFp.getHomeCareResidencyCostMixValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareVolumeAgeGroupTrendValues(tenant: string, options?: any): AxiosPromise<Array<GetHomeCareVolumeAgeGroupTrendValues200ResponseInner>> {
            return localVarFp.getHomeCareVolumeAgeGroupTrendValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeCareVolumeAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: any): AxiosPromise<GetHomeCareVolumeAgeGroupValues200Response> {
            return localVarFp.getHomeCareVolumeAgeGroupValues(tenant, variantId, dataSource, grouping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInflowAgeGroupValues(tenant: string, options?: any): AxiosPromise<GetInflowAgeGroupValues200Response> {
            return localVarFp.getInflowAgeGroupValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInflowProportionAgeGroupTrendValues(tenant: string, options?: any): AxiosPromise<Array<GetInflowProportionAgeGroupTrendValues200ResponseInner>> {
            return localVarFp.getInflowProportionAgeGroupTrendValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLifespans(tenant: string, options?: any): AxiosPromise<GetLifespans200Response> {
            return localVarFp.getLifespans(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxAuthDuration(tenant: string, options?: any): AxiosPromise<GetMaxAuthDuration200Response> {
            return localVarFp.getMaxAuthDuration(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(tenant: string, idToken: string, options?: any): AxiosPromise<IHandlaggareWithPermissions> {
            return localVarFp.getMe(tenant, idToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules(tenant: string, options?: any): AxiosPromise<Array<GetRoles200ResponseInnerAssignableRolesInner>> {
            return localVarFp.getModules(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} frn 
         * @param {string} till 
         * @param {string} idToken 
         * @param {string} otherPersonnummer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonthlyVolume2Other(tenant: string, frn: string, till: string, idToken: string, otherPersonnummer: string, options?: any): AxiosPromise<Array<GetMonthlyVolume2Other200ResponseInner>> {
            return localVarFp.getMonthlyVolume2Other(tenant, frn, till, idToken, otherPersonnummer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalities(tenant: string, options?: any): AxiosPromise<Array<GetMunicipalities200ResponseInner>> {
            return localVarFp.getMunicipalities(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMunicipalityName(tenant: string, options?: any): AxiosPromise<string> {
            return localVarFp.getMunicipalityName(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCases(tenant: string, date: string, idToken: string, options?: any): AxiosPromise<Array<IFormattedData>> {
            return localVarFp.getMyCases(tenant, date, idToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNursingDementiaProportionValues(tenant: string, options?: any): AxiosPromise<GetNursingDementiaProportionValues200Response> {
            return localVarFp.getNursingDementiaProportionValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetNursingDementiaTotalStayLengthValuesAggregationEnum} [aggregation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNursingDementiaTotalStayLengthValues(tenant: string, aggregation?: GetNursingDementiaTotalStayLengthValuesAggregationEnum, options?: any): AxiosPromise<GetNursingDementiaTotalStayLengthValues200Response> {
            return localVarFp.getNursingDementiaTotalStayLengthValues(tenant, aggregation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNursingDementiaTotalTrendValues(tenant: string, options?: any): AxiosPromise<GetNursingDementiaTotalTrendValues200Response> {
            return localVarFp.getNursingDementiaTotalTrendValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} otherPersonnummer 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherCases(tenant: string, date: string, otherPersonnummer: string, idToken: string, options?: any): AxiosPromise<Array<IFormattedData>> {
            return localVarFp.getOtherCases(tenant, date, otherPersonnummer, idToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} date 
         * @param {string} otherPersonnummer 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOtherCases2(tenant: string, date: string, otherPersonnummer: string, idToken: string, options?: any): AxiosPromise<Array<IFormattedData>> {
            return localVarFp.getOtherCases2(tenant, date, otherPersonnummer, idToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissions(tenant: string, options?: any): AxiosPromise<Array<GetRoles200ResponseInnerAssignableRolesInner>> {
            return localVarFp.getPermissions(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} ansvarigPerson 
         * @param {number} dateGap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyExpired(tenant: string, ansvarigPerson: string, dateGap: number, options?: any): AxiosPromise<Array<INewlyExpiredCases>> {
            return localVarFp.getRecentlyExpired(tenant, ansvarigPerson, dateGap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidencyCapacityAgeGroupTrendValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: any): AxiosPromise<GetResidencyCapacityAgeGroupTrendValues200Response> {
            return localVarFp.getResidencyCapacityAgeGroupTrendValues(tenant, variantId, dataSource, grouping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidencyCapacityAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: any): AxiosPromise<GetHomeCareVolumeAgeGroupValues200Response> {
            return localVarFp.getResidencyCapacityAgeGroupValues(tenant, variantId, dataSource, grouping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetResidencyPopulationProportionValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidencyPopulationProportionValues(tenant: string, variant?: GetResidencyPopulationProportionValuesVariantEnum, options?: any): AxiosPromise<GetHomeCarePerformedHoursValues200Response> {
            return localVarFp.getResidencyPopulationProportionValues(tenant, variant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetResidencyProportionAgeGroupValuesVariantEnum} [variant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidencyProportionAgeGroupValues(tenant: string, variant?: GetResidencyProportionAgeGroupValuesVariantEnum, options?: any): AxiosPromise<GetHomeCareProportionAgeGroupValues200Response> {
            return localVarFp.getResidencyProportionAgeGroupValues(tenant, variant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(tenant: string, id: number, options?: any): AxiosPromise<GetRoles200ResponseInner> {
            return localVarFp.getRole(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(tenant: string, options?: any): AxiosPromise<Array<GetRoles200ResponseInner>> {
            return localVarFp.getRoles(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetStayLengthTrendValuesSpecializationEnum} specialization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStayLengthTrendValues(tenant: string, specialization: GetStayLengthTrendValuesSpecializationEnum, options?: any): AxiosPromise<Array<GetStayLengthTrendValues200ResponseInner>> {
            return localVarFp.getStayLengthTrendValues(tenant, specialization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedBenchmarks(tenant: string, options?: any): AxiosPromise<GetSupportedBenchmarks200Response> {
            return localVarFp.getSupportedBenchmarks(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTag(tenant: string, tagId: string, options?: any): AxiosPromise<ExtendedTag> {
            return localVarFp.getTag(tenant, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag10(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag10200Response> {
            return localVarFp.getTextForFlag10(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag15(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag2200Response> {
            return localVarFp.getTextForFlag15(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag16(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag2200Response> {
            return localVarFp.getTextForFlag16(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag17(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag17200Response> {
            return localVarFp.getTextForFlag17(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag18(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag18200Response> {
            return localVarFp.getTextForFlag18(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag19(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag18200Response> {
            return localVarFp.getTextForFlag19(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag2(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag2200Response> {
            return localVarFp.getTextForFlag2(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag20(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag2200Response> {
            return localVarFp.getTextForFlag20(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag4(tenant: string, id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getTextForFlag4(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag7(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag7200Response> {
            return localVarFp.getTextForFlag7(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag8(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag8200Response> {
            return localVarFp.getTextForFlag8(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextForFlag9(tenant: string, id: number, options?: any): AxiosPromise<GetTextForFlag2200Response> {
            return localVarFp.getTextForFlag9(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: any): AxiosPromise<GetVolumeAgeGroupValues200Response> {
            return localVarFp.getVolumeAgeGroupValues(tenant, variantId, dataSource, grouping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeInsatsMixValues(tenant: string, options?: any): AxiosPromise<GetCostQualityMixValues200Response> {
            return localVarFp.getVolumeInsatsMixValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeProportionAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: any): AxiosPromise<GetVolumeProportionAgeGroupValues200Response> {
            return localVarFp.getVolumeProportionAgeGroupValues(tenant, variantId, dataSource, grouping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeQualityMixValues(tenant: string, options?: any): AxiosPromise<GetCostQualityMixValues200Response> {
            return localVarFp.getVolumeQualityMixValues(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [variantId] 
         * @param {string} [dataSource] 
         * @param {string} [grouping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumeValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: any): AxiosPromise<GetVolumeValues200Response> {
            return localVarFp.getVolumeValues(tenant, variantId, dataSource, grouping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GuestRequest} guestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guest(guestRequest: GuestRequest, options?: any): AxiosPromise<Guest200Response> {
            return localVarFp.guest(guestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hgKontinuitet(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.hgKontinuitet(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hgKontinuitetGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.hgKontinuitetGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hgUtfrandeGrad(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.hgUtfrandeGrad(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hgUtfrandeGradGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.hgUtfrandeGradGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {TenantFunctionName} benchmark 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeBenchmark(tenant: string, benchmark: TenantFunctionName, options?: any): AxiosPromise<InitializeBenchmark200Response> {
            return localVarFp.initializeBenchmark(tenant, benchmark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeKoladaData(tenant: string, year: number, options?: any): AxiosPromise<void> {
            return localVarFp.initializeKoladaData(tenant, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {TenantFunctionName} benchmark 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertBenchmark(tenant: string, benchmark: TenantFunctionName, year: number, month: number, options?: any): AxiosPromise<InsertBenchmark200Response> {
            return localVarFp.insertBenchmark(tenant, benchmark, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertMunicipalities(tenant: string, options?: any): AxiosPromise<InsertBenchmark200Response> {
            return localVarFp.insertMunicipalities(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kortaRestider(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.kortaRestider(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kortaRestiderGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.kortaRestiderGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        larm(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.larm(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        larmHemtjanst(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.larmHemtjanst(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        larmNotHemtjanst(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.larmNotHemtjanst(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        larmuttryckningar(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.larmuttryckningar(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPerson(tenant: string, options?: any): AxiosPromise<any> {
            return localVarFp.loadPerson(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} loginChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginChallenge: string, options?: any): AxiosPromise<void> {
            return localVarFp.login(loginChallenge, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} logoutChallenge 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(logoutChallenge: string, options?: any): AxiosPromise<void> {
            return localVarFp.logout(logoutChallenge, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mangaAvbjdaInsatserGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.mangaAvbjdaInsatserGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mangaAvbojdaInsatser(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.mangaAvbojdaInsatser(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(tenant: string, idToken: string, options?: any): AxiosPromise<Me200Response> {
            return localVarFp.me(tenant, idToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mngaLarmutryckningar(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.mngaLarmutryckningar(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mngaLarmutryckningarGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.mngaLarmutryckningarGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        municipality(tenant: string, options?: any): AxiosPromise<Municipality200Response> {
            return localVarFp.municipality(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mycketAvbojdTid(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.mycketAvbojdTid(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mycketAvbojdTidGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.mycketAvbojdTidGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        name(tenant: string, name: string, options?: any): AxiosPromise<Array<GetAllBrukare200ResponseInner>> {
            return localVarFp.name(tenant, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {INewAvdelningData} iNewAvdelningData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAvdelning(tenant: string, iNewAvdelningData: INewAvdelningData, options?: any): AxiosPromise<any> {
            return localVarFp.newAvdelning(tenant, iNewAvdelningData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCases(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.newCases(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCasesByBrukare(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.newCasesByBrukare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {ICreateHandlaggareData} iCreateHandlaggareData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newHandlaggare(tenant: string, iCreateHandlaggareData: ICreateHandlaggareData, options?: any): AxiosPromise<any> {
            return localVarFp.newHandlaggare(tenant, iCreateHandlaggareData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {INewTeamData} iNewTeamData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newteam(tenant: string, iNewTeamData: INewTeamData, options?: any): AxiosPromise<any> {
            return localVarFp.newteam(tenant, iNewTeamData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nyaBrukare(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.nyaBrukare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objektanstllning(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.objektanstllning(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objektanstllningGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.objektanstllningGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omfattandeBeslut(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.omfattandeBeslut(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        omfattandeBeslutBrukare(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<OmfattandeBeslutBrukare200ResponseInner>> {
            return localVarFp.omfattandeBeslutBrukare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderedTime(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.orderedTime(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordinarieArbetstidOchvertid(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.ordinarieArbetstidOchvertid(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordinarieArbetstidOchvertidGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.ordinarieArbetstidOchvertidGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        person(tenant: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.person(tenant, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} year 
         * @param {RegenerateKoladaDataRegeneratorEnum} regenerator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateKoladaData(tenant: string, year: number, regenerator: RegenerateKoladaDataRegeneratorEnum, options?: any): AxiosPromise<void> {
            return localVarFp.regenerateKoladaData(tenant, year, regenerator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RegenerateSocialstyrelsenDataTypeEnum} type 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateSocialstyrelsenData(tenant: string, type: RegenerateSocialstyrelsenDataTypeEnum, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.regenerateSocialstyrelsenData(tenant, type, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rehashNewDemoData(tenant: string, options?: any): AxiosPromise<string> {
            return localVarFp.rehashNewDemoData(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetAssignableRoleRequest} setAssignableRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAssignableRole(tenant: string, setAssignableRoleRequest: SetAssignableRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.removeAssignableRole(tenant, setAssignableRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} authenticationMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAuthenticationMethod(tenant: string, authenticationMethod: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeAuthenticationMethod(tenant, authenticationMethod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RemoveFlagsRequest} removeFlagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFlags(tenant: string, removeFlagsRequest: RemoveFlagsRequest, options?: any): AxiosPromise<any> {
            return localVarFp.removeFlags(tenant, removeFlagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMaxAuthDuration(tenant: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeMaxAuthDuration(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {AddModuleToRoleRequest} addModuleToRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeModuleFromRole(tenant: string, addModuleToRoleRequest: AddModuleToRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.removeModuleFromRole(tenant, addModuleToRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {RemovePermissionOverrideRequest} removePermissionOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePermissionOverride(tenant: string, removePermissionOverrideRequest: RemovePermissionOverrideRequest, options?: any): AxiosPromise<void> {
            return localVarFp.removePermissionOverride(tenant, removePermissionOverrideRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePerson(tenant: string, body: any, options?: any): AxiosPromise<string> {
            return localVarFp.savePerson(tenant, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scbTest(tenant: string, options?: any): AxiosPromise<string> {
            return localVarFp.scbTest(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTags(tenant: string, search?: string, options?: any): AxiosPromise<SearchTags200Response> {
            return localVarFp.searchTags(tenant, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetAssignableRoleRequest} setAssignableRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAssignableRole(tenant: string, setAssignableRoleRequest: SetAssignableRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setAssignableRole(tenant, setAssignableRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {SetLifespansRequest} setLifespansRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLifespans(tenant: string, setLifespansRequest: SetLifespansRequest, options?: any): AxiosPromise<GetLifespans200Response> {
            return localVarFp.setLifespans(tenant, setLifespansRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {GetMaxAuthDuration200Response} getMaxAuthDuration200Response 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMaxAuthDuration(tenant: string, getMaxAuthDuration200Response: GetMaxAuthDuration200Response, options?: any): AxiosPromise<void> {
            return localVarFp.setMaxAuthDuration(tenant, getMaxAuthDuration200Response, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        structure(tenant: string, options?: any): AxiosPromise<Structure200Response> {
            return localVarFp.structure(tenant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeOfCanceledCases(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.timeOfCanceledCases(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeOfNewBrukareCases(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.timeOfNewBrukareCases(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {IUpdateHandlaggareData} iUpdateHandlaggareData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(tenant: string, iUpdateHandlaggareData: IUpdateHandlaggareData, options?: any): AxiosPromise<any> {
            return localVarFp.update(tenant, iUpdateHandlaggareData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {number} id 
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(tenant: string, id: number, updateRoleRequest: UpdateRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateRole(tenant, id, updateRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {IRequestBody} iRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPermissions(tenant: string, iRequestBody: IRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserPermissions(tenant, iRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utfordPerBrukare(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.utfordPerBrukare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utfordTid(tenant: string, from: string, to: string, options?: any): AxiosPromise<Array<UtfordTid200ResponseInner>> {
            return localVarFp.utfordTid(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utfordTidPerBrukare(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.utfordTidPerBrukare(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} from 
         * @param {string} to 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utfordaNattinsatser(tenant: string, from: string, to: string, options?: any): AxiosPromise<any> {
            return localVarFp.utfordaNattinsatser(tenant, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(options?: any): AxiosPromise<void> {
            return localVarFp.validate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veckovila(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.veckovila(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        veckovilaGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.veckovilaGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verlappandeBesk(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.verlappandeBesk(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenant 
         * @param {string} runDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verlappandeBeskGenerate(tenant: string, runDate: string, options?: any): AxiosPromise<Array<IFlagResult>> {
            return localVarFp.verlappandeBeskGenerate(tenant, runDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhook(body: any, options?: any): AxiosPromise<Webhook200Response> {
            return localVarFp.webhook(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} tenant 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public _new(tenant: string, body: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration)._new(tenant, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {AddAuthenticationMethodRequest} addAuthenticationMethodRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addAuthenticationMethod(tenant: string, addAuthenticationMethodRequest: AddAuthenticationMethodRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addAuthenticationMethod(tenant, addAuthenticationMethodRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {AddFlagsRequest} addFlagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addFlags(tenant: string, addFlagsRequest: AddFlagsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addFlags(tenant, addFlagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {AddModuleToRoleRequest} addModuleToRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addModuleToRole(tenant: string, addModuleToRoleRequest: AddModuleToRoleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addModuleToRole(tenant, addModuleToRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} tagId 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addMunicipalitiesToTag(tenant: string, tagId: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addMunicipalitiesToTag(tenant, tagId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {AddPermissionOverrideRequest} addPermissionOverrideRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addPermissionOverride(tenant: string, addPermissionOverrideRequest: AddPermissionOverrideRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addPermissionOverride(tenant, addPermissionOverrideRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {UpdateRoleRequest} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addRole(tenant: string, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).addRole(tenant, updateRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public all(tenant: string, from: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).all(tenant, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public allCases(tenant: string, idToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).allCases(tenant, idToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ammountBrukareCanceledCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ammountBrukareCanceledCases(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public antalBrukareBeviljad(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).antalBrukareBeviljad(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public archive(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).archive(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authenticate(tenant: string, authenticateRequest: AuthenticateRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authenticate(tenant, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public avgAge(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).avgAge(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public avgAgeCanceledCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).avgAgeCanceledCases(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public avgAgeNewCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).avgAgeNewCases(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public beslutsomfattningPerInterval(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).beslutsomfattningPerInterval(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bestalldOmvardnad(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bestalldOmvardnad(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bestalldService(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bestalldService(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bestalldTidPerBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bestalldTidPerBrukare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bevlijadeLarmNattinsattser(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bevlijadeLarmNattinsattser(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brukareBestalldTid(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).brukareBestalldTid(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brukareCount(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).brukareCount(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {string} namn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public byName(tenant: string, from: string, to: string, namn: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).byName(tenant, from, to, namn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} personnummer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public byPersonnummer(tenant: string, personnummer: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).byPersonnummer(tenant, personnummer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} owner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bygare(tenant: string, owner: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bygare(tenant, owner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calculate(tenant: string, body: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calculate(tenant, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public callback(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).callback(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public canceledCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).canceledCases(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public canceledCasesByBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).canceledCasesByBrukare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clearAllFlags(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clearAllFlags(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} flag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clearFlag(tenant: string, flag: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clearFlag(tenant, flag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {ICommentData} iCommentData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public close(tenant: string, iCommentData: ICommentData, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).close(tenant, iCommentData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {ICommentData} iCommentData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public comment(tenant: string, iCommentData: ICommentData, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).comment(tenant, iCommentData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} consentChallenge 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public consent(consentChallenge: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).consent(consentChallenge, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {ICreateCaseData} iCreateCaseData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public create(tenant: string, iCreateCaseData: ICreateCaseData, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).create(tenant, iCreateCaseData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {CreateGuestUserRequest} createGuestUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createGuestUser(tenant: string, createGuestUserRequest: CreateGuestUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createGuestUser(tenant, createGuestUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {UpdateRoleRequest} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createTag(tenant: string, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createTag(tenant, updateRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAllBenchmarks(tenant: string, year: number, month: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteAllBenchmarks(tenant, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteGuestUser(tenant: string, username: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteGuestUser(tenant, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRole(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteRole(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteTag(tenant: string, tagId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteTag(tenant, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public descriptions(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).descriptions(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadPdf(tenant: string, body: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadPdf(tenant, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dygnsvila(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dygnsvila(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dygnsvilaGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dygnsvilaGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} username 
     * @param {EditGuestUserRequest} editGuestUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editGuestUser(tenant: string, username: string, editGuestUserRequest: EditGuestUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).editGuestUser(tenant, username, editGuestUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} tagId 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public editTag(tenant: string, tagId: string, name: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).editTag(tenant, tagId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f10all(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f10all(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f15all(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f15all(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f16all(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f16all(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f17all(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f17all(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f2All(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f2All(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {string} brukare 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f2Brukare(tenant: string, from: string, to: string, brukare: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f2Brukare(tenant, from, to, brukare, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f4All(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f4All(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f7(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f7(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {string} medarbetare 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f7Medarbetare(tenant: string, from: string, to: string, medarbetare: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f7Medarbetare(tenant, from, to, medarbetare, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f8All(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f8All(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public f9All(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).f9All(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public generateAll(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).generateAll(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllAuthenticationMethods(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllAuthenticationMethods(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllBrukare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllMedarbetare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllMedarbetare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllUtforare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllUtforare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllowedAuthenticationMethods(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllowedAuthenticationMethods(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAverage(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAverage(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {number} level 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAverageSpecific(tenant: string, from: string, to: string, level: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAverageSpecific(tenant, from, to, level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBrukareOutsideStructure(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBrukareOutsideStructure(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCapacityInflowOutflowTrendValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCapacityInflowOutflowTrendValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCostQualityMixValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCostQualityMixValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCostValues(tenant: string, variantId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCostValues(tenant, variantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} frn 
     * @param {string} till 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDistribution(tenant: string, frn: string, till: string, idToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDistribution(tenant, frn, till, idToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuestUser(tenant: string, username: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGuestUser(tenant, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuestUsers(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGuestUsers(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareHoursTrendValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareHoursTrendValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareOrderedHoursAgeGroupValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareOrderedHoursAgeGroupValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareOrderedHoursIntervalComparisonValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareOrderedHoursIntervalComparisonValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareOrderedHoursIntervalValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareOrderedHoursIntervalValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {string} [dataSource] 
     * @param {string} [grouping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareOrderedHoursPerPersonValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareOrderedHoursPerPersonValues(tenant, variantId, dataSource, grouping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareOrderedHoursProportionIntervalValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareOrderedHoursProportionIntervalValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareOrderedHoursTrendMixedValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareOrderedHoursTrendMixedValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareOrderedHoursValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareOrderedHoursValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCarePerformedHoursTrendMixedValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCarePerformedHoursTrendMixedValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCarePerformedHoursValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCarePerformedHoursValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {GetHomeCareProportionAgeGroupValuesVariantEnum} [variant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareProportionAgeGroupValues(tenant: string, variant?: GetHomeCareProportionAgeGroupValuesVariantEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareProportionAgeGroupValues(tenant, variant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {string} [dataSource] 
     * @param {string} [grouping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareProportionValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareProportionValues(tenant, variantId, dataSource, grouping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareResidencyCostMixValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareResidencyCostMixValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareVolumeAgeGroupTrendValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareVolumeAgeGroupTrendValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {string} [dataSource] 
     * @param {string} [grouping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHomeCareVolumeAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHomeCareVolumeAgeGroupValues(tenant, variantId, dataSource, grouping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInflowAgeGroupValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getInflowAgeGroupValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getInflowProportionAgeGroupTrendValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getInflowProportionAgeGroupTrendValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLifespans(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLifespans(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMaxAuthDuration(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMaxAuthDuration(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMe(tenant: string, idToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMe(tenant, idToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getModules(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getModules(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} frn 
     * @param {string} till 
     * @param {string} idToken 
     * @param {string} otherPersonnummer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMonthlyVolume2Other(tenant: string, frn: string, till: string, idToken: string, otherPersonnummer: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMonthlyVolume2Other(tenant, frn, till, idToken, otherPersonnummer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMunicipalities(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMunicipalities(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMunicipalityName(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMunicipalityName(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} date 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyCases(tenant: string, date: string, idToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMyCases(tenant, date, idToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNursingDementiaProportionValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getNursingDementiaProportionValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {GetNursingDementiaTotalStayLengthValuesAggregationEnum} [aggregation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNursingDementiaTotalStayLengthValues(tenant: string, aggregation?: GetNursingDementiaTotalStayLengthValuesAggregationEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getNursingDementiaTotalStayLengthValues(tenant, aggregation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getNursingDementiaTotalTrendValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getNursingDementiaTotalTrendValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} date 
     * @param {string} otherPersonnummer 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOtherCases(tenant: string, date: string, otherPersonnummer: string, idToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOtherCases(tenant, date, otherPersonnummer, idToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} date 
     * @param {string} otherPersonnummer 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOtherCases2(tenant: string, date: string, otherPersonnummer: string, idToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOtherCases2(tenant, date, otherPersonnummer, idToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPermissions(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPermissions(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} ansvarigPerson 
     * @param {number} dateGap 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRecentlyExpired(tenant: string, ansvarigPerson: string, dateGap: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRecentlyExpired(tenant, ansvarigPerson, dateGap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {string} [dataSource] 
     * @param {string} [grouping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getResidencyCapacityAgeGroupTrendValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getResidencyCapacityAgeGroupTrendValues(tenant, variantId, dataSource, grouping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {string} [dataSource] 
     * @param {string} [grouping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getResidencyCapacityAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getResidencyCapacityAgeGroupValues(tenant, variantId, dataSource, grouping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {GetResidencyPopulationProportionValuesVariantEnum} [variant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getResidencyPopulationProportionValues(tenant: string, variant?: GetResidencyPopulationProportionValuesVariantEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getResidencyPopulationProportionValues(tenant, variant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {GetResidencyProportionAgeGroupValuesVariantEnum} [variant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getResidencyProportionAgeGroupValues(tenant: string, variant?: GetResidencyProportionAgeGroupValuesVariantEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getResidencyProportionAgeGroupValues(tenant, variant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRole(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRole(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRoles(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRoles(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {GetStayLengthTrendValuesSpecializationEnum} specialization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStayLengthTrendValues(tenant: string, specialization: GetStayLengthTrendValuesSpecializationEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStayLengthTrendValues(tenant, specialization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSupportedBenchmarks(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSupportedBenchmarks(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTag(tenant: string, tagId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTag(tenant, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag10(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag10(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag15(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag15(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag16(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag16(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag17(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag17(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag18(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag18(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag19(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag19(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag2(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag2(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag20(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag20(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag4(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag4(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag7(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag7(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag8(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag8(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTextForFlag9(tenant: string, id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTextForFlag9(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {string} [dataSource] 
     * @param {string} [grouping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVolumeAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVolumeAgeGroupValues(tenant, variantId, dataSource, grouping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVolumeInsatsMixValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVolumeInsatsMixValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {string} [dataSource] 
     * @param {string} [grouping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVolumeProportionAgeGroupValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVolumeProportionAgeGroupValues(tenant, variantId, dataSource, grouping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVolumeQualityMixValues(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVolumeQualityMixValues(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [variantId] 
     * @param {string} [dataSource] 
     * @param {string} [grouping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVolumeValues(tenant: string, variantId?: string, dataSource?: string, grouping?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVolumeValues(tenant, variantId, dataSource, grouping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuestRequest} guestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public guest(guestRequest: GuestRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).guest(guestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public health(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hgKontinuitet(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hgKontinuitet(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hgKontinuitetGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hgKontinuitetGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hgUtfrandeGrad(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hgUtfrandeGrad(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hgUtfrandeGradGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hgUtfrandeGradGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {TenantFunctionName} benchmark 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public initializeBenchmark(tenant: string, benchmark: TenantFunctionName, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).initializeBenchmark(tenant, benchmark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public initializeKoladaData(tenant: string, year: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).initializeKoladaData(tenant, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {TenantFunctionName} benchmark 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertBenchmark(tenant: string, benchmark: TenantFunctionName, year: number, month: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).insertBenchmark(tenant, benchmark, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public insertMunicipalities(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).insertMunicipalities(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public kortaRestider(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).kortaRestider(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public kortaRestiderGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).kortaRestiderGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public larm(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).larm(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public larmHemtjanst(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).larmHemtjanst(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public larmNotHemtjanst(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).larmNotHemtjanst(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public larmuttryckningar(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).larmuttryckningar(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loadPerson(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loadPerson(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} loginChallenge 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public login(loginChallenge: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).login(loginChallenge, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} logoutChallenge 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logout(logoutChallenge: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).logout(logoutChallenge, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mangaAvbjdaInsatserGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mangaAvbjdaInsatserGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mangaAvbojdaInsatser(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mangaAvbojdaInsatser(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public me(tenant: string, idToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).me(tenant, idToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mngaLarmutryckningar(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mngaLarmutryckningar(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mngaLarmutryckningarGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mngaLarmutryckningarGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public municipality(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).municipality(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mycketAvbojdTid(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mycketAvbojdTid(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mycketAvbojdTidGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mycketAvbojdTidGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public name(tenant: string, name: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).name(tenant, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {INewAvdelningData} iNewAvdelningData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newAvdelning(tenant: string, iNewAvdelningData: INewAvdelningData, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newAvdelning(tenant, iNewAvdelningData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newCases(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newCasesByBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newCasesByBrukare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {ICreateHandlaggareData} iCreateHandlaggareData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newHandlaggare(tenant: string, iCreateHandlaggareData: ICreateHandlaggareData, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newHandlaggare(tenant, iCreateHandlaggareData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {INewTeamData} iNewTeamData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public newteam(tenant: string, iNewTeamData: INewTeamData, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).newteam(tenant, iNewTeamData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public nyaBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).nyaBrukare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public objektanstllning(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).objektanstllning(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public objektanstllningGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).objektanstllningGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public omfattandeBeslut(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).omfattandeBeslut(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public omfattandeBeslutBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).omfattandeBeslutBrukare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderedTime(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderedTime(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ordinarieArbetstidOchvertid(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ordinarieArbetstidOchvertid(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ordinarieArbetstidOchvertidGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ordinarieArbetstidOchvertidGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public person(tenant: string, id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).person(tenant, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} year 
     * @param {RegenerateKoladaDataRegeneratorEnum} regenerator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public regenerateKoladaData(tenant: string, year: number, regenerator: RegenerateKoladaDataRegeneratorEnum, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).regenerateKoladaData(tenant, year, regenerator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {RegenerateSocialstyrelsenDataTypeEnum} type 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public regenerateSocialstyrelsenData(tenant: string, type: RegenerateSocialstyrelsenDataTypeEnum, file: File, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).regenerateSocialstyrelsenData(tenant, type, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rehashNewDemoData(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rehashNewDemoData(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {SetAssignableRoleRequest} setAssignableRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeAssignableRole(tenant: string, setAssignableRoleRequest: SetAssignableRoleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeAssignableRole(tenant, setAssignableRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} authenticationMethod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeAuthenticationMethod(tenant: string, authenticationMethod: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeAuthenticationMethod(tenant, authenticationMethod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {RemoveFlagsRequest} removeFlagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeFlags(tenant: string, removeFlagsRequest: RemoveFlagsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeFlags(tenant, removeFlagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeMaxAuthDuration(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeMaxAuthDuration(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {AddModuleToRoleRequest} addModuleToRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removeModuleFromRole(tenant: string, addModuleToRoleRequest: AddModuleToRoleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removeModuleFromRole(tenant, addModuleToRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {RemovePermissionOverrideRequest} removePermissionOverrideRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public removePermissionOverride(tenant: string, removePermissionOverrideRequest: RemovePermissionOverrideRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).removePermissionOverride(tenant, removePermissionOverrideRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public savePerson(tenant: string, body: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).savePerson(tenant, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public scbTest(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).scbTest(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchTags(tenant: string, search?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).searchTags(tenant, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {SetAssignableRoleRequest} setAssignableRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setAssignableRole(tenant: string, setAssignableRoleRequest: SetAssignableRoleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setAssignableRole(tenant, setAssignableRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {SetLifespansRequest} setLifespansRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setLifespans(tenant: string, setLifespansRequest: SetLifespansRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setLifespans(tenant, setLifespansRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {GetMaxAuthDuration200Response} getMaxAuthDuration200Response 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setMaxAuthDuration(tenant: string, getMaxAuthDuration200Response: GetMaxAuthDuration200Response, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setMaxAuthDuration(tenant, getMaxAuthDuration200Response, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public structure(tenant: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).structure(tenant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public timeOfCanceledCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).timeOfCanceledCases(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public timeOfNewBrukareCases(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).timeOfNewBrukareCases(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {IUpdateHandlaggareData} iUpdateHandlaggareData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public update(tenant: string, iUpdateHandlaggareData: IUpdateHandlaggareData, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).update(tenant, iUpdateHandlaggareData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {number} id 
     * @param {UpdateRoleRequest} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateRole(tenant: string, id: number, updateRoleRequest: UpdateRoleRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateRole(tenant, id, updateRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {IRequestBody} iRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserPermissions(tenant: string, iRequestBody: IRequestBody, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserPermissions(tenant, iRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public utfordPerBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).utfordPerBrukare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public utfordTid(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).utfordTid(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public utfordTidPerBrukare(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).utfordTidPerBrukare(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} from 
     * @param {string} to 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public utfordaNattinsatser(tenant: string, from: string, to: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).utfordaNattinsatser(tenant, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public validate(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).validate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public veckovila(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).veckovila(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public veckovilaGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).veckovilaGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public verlappandeBesk(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).verlappandeBesk(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenant 
     * @param {string} runDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public verlappandeBeskGenerate(tenant: string, runDate: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).verlappandeBeskGenerate(tenant, runDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public webhook(body: any, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).webhook(body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetHomeCareProportionAgeGroupValuesVariantEnum = {
    Statdb: 'statdb',
    Socialstyrelsen: 'socialstyrelsen'
} as const;
export type GetHomeCareProportionAgeGroupValuesVariantEnum = typeof GetHomeCareProportionAgeGroupValuesVariantEnum[keyof typeof GetHomeCareProportionAgeGroupValuesVariantEnum];
/**
 * @export
 */
export const GetNursingDementiaTotalStayLengthValuesAggregationEnum = {
    Mean: 'mean',
    Median: 'median'
} as const;
export type GetNursingDementiaTotalStayLengthValuesAggregationEnum = typeof GetNursingDementiaTotalStayLengthValuesAggregationEnum[keyof typeof GetNursingDementiaTotalStayLengthValuesAggregationEnum];
/**
 * @export
 */
export const GetResidencyPopulationProportionValuesVariantEnum = {
    Statdb: 'statdb',
    Socialstyrelsen: 'socialstyrelsen'
} as const;
export type GetResidencyPopulationProportionValuesVariantEnum = typeof GetResidencyPopulationProportionValuesVariantEnum[keyof typeof GetResidencyPopulationProportionValuesVariantEnum];
/**
 * @export
 */
export const GetResidencyProportionAgeGroupValuesVariantEnum = {
    Statdb: 'statdb',
    Socialstyrelsen: 'socialstyrelsen'
} as const;
export type GetResidencyProportionAgeGroupValuesVariantEnum = typeof GetResidencyProportionAgeGroupValuesVariantEnum[keyof typeof GetResidencyProportionAgeGroupValuesVariantEnum];
/**
 * @export
 */
export const GetStayLengthTrendValuesSpecializationEnum = {
    Nursing: 'nursing',
    Dementia: 'dementia',
    Total: 'total'
} as const;
export type GetStayLengthTrendValuesSpecializationEnum = typeof GetStayLengthTrendValuesSpecializationEnum[keyof typeof GetStayLengthTrendValuesSpecializationEnum];
/**
 * @export
 */
export const RegenerateKoladaDataRegeneratorEnum = {
    Population: 'population',
    Cost: 'cost',
    AverageHours: 'average-hours',
    SboProportion: 'säbo-proportion',
    Volume: 'volume',
    SatisfactionHemtjnst: 'satisfaction-hemtjänst'
} as const;
export type RegenerateKoladaDataRegeneratorEnum = typeof RegenerateKoladaDataRegeneratorEnum[keyof typeof RegenerateKoladaDataRegeneratorEnum];
/**
 * @export
 */
export const RegenerateSocialstyrelsenDataTypeEnum = {
    Hemtjnst: 'hemtjänst',
    Sbo: 'säbo'
} as const;
export type RegenerateSocialstyrelsenDataTypeEnum = typeof RegenerateSocialstyrelsenDataTypeEnum[keyof typeof RegenerateSocialstyrelsenDataTypeEnum];


