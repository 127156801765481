import { InfoCircleOutlined, PlusOutlined, ArrowUpOutlined, CaretUpOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Input, InputNumber, List, Modal, Popover, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { ExtraTask, TimepointTask } from '../types/municipalityData'

type modalState = { state: 'closed' } | { state: 'creating' } | { state: 'editing'; title: string | number }

interface Level {
  title: string
  description: string
  standardTime: number
}

interface EditableLevelProps<T> {
  selections: string[]
  name: string
  currentTask: T
  updateTask: (task: T) => void
}

const EditableLevel = <T,>({ selections, name, currentTask, updateTask }: EditableLevelProps<T extends TimepointTask | ExtraTask ? T : never>) => {
  useEffect(() => {
    setItems(selections)
  }, [selections])

  const [items, setItems] = useState<string[]>([])
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)

  const [modalState, setModalState] = useState<modalState>({ state: 'closed' })
  const [formData, setFormData] = useState<Level>({
    title: '',
    description: '',
    standardTime: 0,
  })

  const [closingTimeout, setClosingTimeout] = useState<NodeJS.Timeout | undefined>(undefined)

  const onBlur = () => {
    setClosingTimeout(setTimeout(() => setPopoverOpen(false), 200))
  }

  const setCreating = () => {
    // We want to close the popover instantly here
    setPopoverOpen(false)
    clearTimeout(closingTimeout)

    setModalState({ state: 'creating' })
  }

  const setEditing = (title: string) => {
    // We want to close the popover instantly here
    setPopoverOpen(false)
    clearTimeout(closingTimeout)

    setModalState({ state: 'editing', title })
    setFormData({
      title: title.toString(),
      description: getSelectionDescription(title.toString()),
      standardTime: currentTask.standardTimes[title],
    })
  }

  const okButtonDisabled = (() => {
    return formData.title.length === 0 || formData.title.length === 0 || formData.standardTime === undefined
  })()

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, title: event.target.value })
  }

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, description: event.target.value })
  }

  const onStandardTimeChange = (value: number) => {
    setFormData({ ...formData, standardTime: value })
  }

  const removeItem = (title: string | number) => {
    clearTimeout(closingTimeout)

    // Copy standardTimes and selectionDescriptions and remove the item with the title as key
    const { [title]: _, ...newStandardTimes } = currentTask.standardTimes
    const { [title]: __, ...newSelectionDescriptions } = currentTask.selectionDescriptions
    const updatedSelections = currentTask.selections.filter((item) => item !== title)
    updateTask({
      ...currentTask,
      selections: updatedSelections,
      standardTimes: newStandardTimes,
      selectionDescriptions: newSelectionDescriptions,
    })

    setItems(updatedSelections)
  }
  const onModalOk = () => {
    if (modalState.state === 'creating') addNewItem()
    if (modalState.state === 'editing') editItem()
  }

  const addNewItem = () => {
    const updatedSelections = [...currentTask.selections, formData.title]
    const updatedStandardTimes = {
      ...currentTask.standardTimes,
      [formData.title]: formData.standardTime,
    }
    const updatedSelectionDescriptions = { ...currentTask.selectionDescriptions, [formData.title]: formData.description }

    updateTask({
      ...currentTask,
      selections: updatedSelections,
      standardTimes: updatedStandardTimes,
      selectionDescriptions: updatedSelectionDescriptions,
    })

    setItems(updatedSelections)
    setModalState({ state: 'closed' })
    setFormData({
      title: '',
      description: '',
      standardTime: 0,
    })
  }

  const editItem = () => {
    if (modalState.state !== 'editing') return

    const updatedStandardTimes = {
      ...currentTask.standardTimes,
      [modalState.title]: formData.standardTime,
    }
    const updatedSelectionDescriptions = { ...currentTask.selectionDescriptions, [modalState.title]: formData.description }

    updateTask({
      ...currentTask,
      standardTimes: updatedStandardTimes,
      selectionDescriptions: updatedSelectionDescriptions,
    })

    setModalState({ state: 'closed' })
    setFormData({
      title: '',
      description: '',
      standardTime: 0,
    })
  }

  const getSelectionString = (selection: string) => {
    return `${selection} (${currentTask.standardTimes[selection]} minuter)`
  }

  const getSelectionDescription = (selection: string) => {
    return currentTask.selectionDescriptions[selection]
  }

  const getModalTitle = () => {
    if (modalState.state === 'creating') return `Ny nivå: ${name}`
    if (modalState.state === 'editing') return `Redigera nivå: ${modalState.title}`
    return ''
  }

  const moveItem = (item: string, direction: 'up' | 'down') => {
    clearTimeout(closingTimeout)

    const index = currentTask.selections.indexOf(item)
    const updatedSelections = [...currentTask.selections]
    const temp = updatedSelections[index]
    if (direction === 'up') {
      updatedSelections[index] = updatedSelections[index - 1]
      updatedSelections[index - 1] = temp
    } else {
      updatedSelections[index] = updatedSelections[index + 1]
      updatedSelections[index + 1] = temp
    }
    updateTask({
      ...currentTask,
      selections: updatedSelections,
    })
    setItems(updatedSelections)
  }

  const onFocus = () => {
    clearTimeout(closingTimeout)
  }

  const popoverContent = (
    <div tabIndex={1} onFocus={onFocus}>
      <List
        size="small"
        bordered
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="text" icon={<PlusOutlined />} onClick={() => setCreating()}>
              Skapa ny nivå
            </Button>
          </div>
        }
        dataSource={items}
        renderItem={(item) => (
          <List.Item
            key={item}
            style={{
              width: '500px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button size="small" icon={<UpOutlined />} onClick={() => moveItem(item, 'up')} disabled={currentTask.selections.indexOf(item) === 0} />
                <Button size="small" icon={<DownOutlined />} onClick={() => moveItem(item, 'down')} disabled={currentTask.selections.indexOf(item) === currentTask.selections.length - 1} />
              </div>
              {getSelectionString(item)}
              <Popover overlayClassName="decision-popover" title={`Beskrivning ${item}`} content={getSelectionDescription(item)}>
                <InfoCircleOutlined />
              </Popover>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <Button size="small" onClick={() => setEditing(item)}>
                Redigera
              </Button>
              <Button danger size="small" onClick={() => removeItem(item)}>
                Ta bort
              </Button>
            </div>
          </List.Item>
        )}
      />
    </div>
  )

  return (
    <div tabIndex={1} onBlur={onBlur}>
      <Popover title={`Nivåer för '${name}'`} content={popoverContent} open={popoverOpen}>
        <Button onClick={() => setPopoverOpen(!popoverOpen)} style={{ width: '100%' }}>
          Nivåer
        </Button>
      </Popover>

      <Modal
        title={getModalTitle()}
        open={modalState.state === 'creating' || modalState.state === 'editing'}
        onCancel={() => setModalState({ state: 'closed' })}
        onOk={onModalOk}
        okButtonProps={{
          disabled: okButtonDisabled,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <p>Titel</p>
          <div>
            {modalState.state === 'editing' ? (
              <Input placeholder="Titel" value={modalState.title} onChange={onTitleChange} disabled />
            ) : (
              <Input placeholder="Titel" value={formData.title} onChange={onTitleChange} />
            )}
          </div>
          <div>
            <p>Beskrivning</p>
            <Input placeholder="Beskrivning" value={formData.description} onChange={onDescriptionChange} />
          </div>
          <div>
            <p>Standardtid</p>
            <InputNumber type="number" style={{ width: '100%' }} min={0} placeholder="Standardtid" value={formData.standardTime} onChange={(value) => onStandardTimeChange(value ?? 0)} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default EditableLevel
