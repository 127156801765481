// Antd
import { Checkbox, Typography, Select, Popover, Form, Divider } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { getmunicipality } from '../../store/municipalitySlice'
import { ExtraTask } from './types/municipalityData'
import { JSONLoadError } from './utils/jsonLoader'
const { Option } = Select
const { Text } = Typography

interface Props {
  loadingErrors?: JSONLoadError[]
}

const Other = ({ loadingErrors }: Props) => {
  const municipalityData = useSelector(getmunicipality)
  const selectionsDescription = (item: ExtraTask) => {
    return item.selections.map(
      (level) =>
        item.selectionDescriptions[level as any] && (
          <span key={level}>
            <Text strong>{level}: </Text>
            {item.selectionDescriptions[level as any]}
            <br />
          </span>
        )
    )
  }

  return (
    <>
      <Typography.Title
        style={{
          marginTop: '30px',
          marginBottom: '30px',
          display: 'flex',
          justifyContent: 'center',
        }}
        level={3}
      >
        Övriga insatser
      </Typography.Title>
      <Form.List name={'other'}>
        {() => (
          <>
            <Divider style={{ width: '100%', marginBlock: 12 }} />
            {municipalityData?.input.extraTasks.map((item, index) => (
              <>
                <div className="decision-wrapper" key={index}>
                  <div>
                    <Typography.Text>{item.name}</Typography.Text>
                  </div>
                  <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                    {() => (
                      <div>
                        {item.frequencies?.length ? (
                          <Form.Item name={[item.name, 'Frekvens']} style={{ width: '55%' }} noStyle>
                            <Select
                              bordered={false}
                              placeholder="Besök per vecka"
                              allowClear
                              style={{
                                border:
                                  loadingErrors && loadingErrors.findIndex((e) => e.taskName === item.name && e.taskType === 'other' && e.errorType === 'frequency') !== -1 ? '1px solid red' : '',
                              }}
                            >
                              {item.frequencies?.map((freq) => (
                                <Option key={freq.name} value={freq.timesPerWeek}>
                                  {freq.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                    {() => (
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Form.Item name={[item.name, 'Nivå']} noStyle>
                          <Select
                            bordered={false}
                            placeholder="Välj nivå"
                            allowClear
                            style={{
                              border: loadingErrors && loadingErrors.findIndex((e) => e.taskName === item.name && e.taskType === 'other' && e.errorType === 'selection') !== -1 ? '1px solid red' : '',
                            }}
                          >
                            {item.selections.map((item: any) => (
                              <Option value={item} key={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {municipalityData.input.formSettings.showDescription && (
                          <Popover overlayClassName="decision-popover" title={item.description} content={selectionsDescription(item)} className="ml-1">
                            <InfoCircleOutlined />
                          </Popover>
                        )}
                      </div>
                    )}
                  </Form.Item>
                  <div>
                    {item.hasOwnProperty('double') && (
                      <Form.Item name={[item.name, 'Dubbelbemanning']} valuePropName="checked" noStyle>
                        <Checkbox>Dubbelbemanning</Checkbox>
                      </Form.Item>
                    )}
                  </div>
                </div>
                <Divider style={{ width: '100%', marginBlock: 12 }} />
              </>
            ))}
          </>
        )}
      </Form.List>
    </>
  )
}

export default Other
