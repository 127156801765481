import { Button, Checkbox, Form, Input, Popconfirm, Select, Typography, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getmunicipality, setMunicipality } from '../../../store/municipalitySlice'
import { ExtraTask, Frequency, MunicipalityData } from '../types/municipalityData'
import EditableFrequencies from './editableFrequencies'
import EditableLevel from './editableLevel'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useState } from 'react'

const EditableOther = () => {
  const municipalityData = useSelector(getmunicipality) as MunicipalityData
  const [timeout, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  const data = useSelector(getmunicipality)
  const editableData = JSON.parse(JSON.stringify(data)) as MunicipalityData
  const dispatch = useDispatch()

  const updateTask = (task: ExtraTask) => {
    const index = editableData.input.extraTasks.findIndex((item) => item.name === task.name)
    if (index === -1) return
    editableData.input.extraTasks[index] = task
    dispatch(setMunicipality({ municipalityData: editableData }))
  }

  const updateName = (event: React.ChangeEvent<HTMLInputElement>, task: ExtraTask) => {
    if (timeout) clearTimeout(timeout)
    setTimeoutId(
      setTimeout(() => {
        const index = editableData.input.extraTasks.findIndex((item) => item.name === task.name)
        if (index === -1) return
        editableData.input.extraTasks[index].name = event.target.value
        dispatch(setMunicipality({ municipalityData: editableData }))
      }, 1000)
    )
  }

  const updateFrequencies = (frequencies: Frequency[], task: ExtraTask) => {
    updateTask({ ...task, frequencies })
  }

  const updateDouble = (event: CheckboxChangeEvent, task: ExtraTask) => {
    updateTask({ ...task, double: event.target.checked })
  }

  const updateShared = (event: CheckboxChangeEvent, task: ExtraTask) => {
    updateTask({ ...task, shared: event.target.checked })
  }

  const updateCalcAs = (value: string, task: ExtraTask) => {
    const newTask = JSON.parse(JSON.stringify(task)) as ExtraTask
    newTask.calcAs = value
    updateTask(newTask)
  }

  return (
    <>
      <Typography.Title
        style={{
          marginTop: '30px',
          marginBottom: '30px',
          display: 'flex',
          justifyContent: 'center',
        }}
        level={3}
      >
        Övriga insatser
      </Typography.Title>
      <Form.List name={'other'}>
        {() => (
          <>
            {municipalityData.input.extraTasks.map((task, index) => (
              <div className="grid w-full grid-cols-[1fr_1fr_1fr_1fr_max-content_max-content_max-content] gap-1.5" key={task.name}>
                <div className="editInput">
                  <Form.Item>
                    <Input onChange={(e) => updateName(e, task)} defaultValue={task.name} />
                  </Form.Item>
                </div>
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                  <div style={{ marginLeft: '8px' }}>
                    {task.frequencies && (
                      <Form.Item name={[task.name, 'Frekvens']}>
                        <EditableFrequencies frequencies={task.frequencies} updateFrequencies={(frequencies) => updateFrequencies(frequencies, task)} name={task.name} />
                      </Form.Item>
                    )}
                  </div>
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                  <Form.Item name={[task.name, 'Nivå']}>
                    <EditableLevel name={task.name} selections={task.selections} currentTask={task} updateTask={updateTask} />
                  </Form.Item>
                </Form.Item>

                <Form.Item noStyle>
                  <Form.Item>
                    <Select
                      defaultValue={task.calcAs}
                      onChange={(value) => updateCalcAs(value, task)}
                      options={[
                        { label: 'Övrig insats', value: 'extraTask' },
                        { label: 'Individuell', value: 'individual' },
                        ...municipalityData.input.timepoints.map((item) => ({ label: item, value: item })),
                      ]}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item name={[task.name, 'Dubbelbemanning']}>
                  <Checkbox checked={task.double} onChange={(e) => updateDouble(e, task)}>
                    Kan dubbelbemannas
                  </Checkbox>
                </Form.Item>

                <Form.Item name={[task.name, 'Kan delas']}>
                  <Checkbox checked={task.shared} onChange={(e) => updateShared(e, task)}>
                    Kan delas
                  </Checkbox>
                </Form.Item>

                <Popconfirm
                  title={`Är du säker på att du vill ta bort insatsen '${task.name}'?`}
                  okText="Bekräfta"
                  cancelText="Avbryt"
                  onConfirm={() => {
                    const newTasks = municipalityData.input.extraTasks.filter((item) => item.name !== task.name)
                    dispatch(setMunicipality({ municipalityData: { ...municipalityData, input: { ...municipalityData.input, extraTasks: newTasks } } }))
                  }}
                >
                  <Button type="primary" danger>
                    Ta bort
                  </Button>
                </Popconfirm>
              </div>
            ))}
            <div className="flex items-center justify-center">
              <Button
                type="primary"
                onClick={() => {
                  const getUniqueName = (name: string) => {
                    const names = municipalityData.input.extraTasks.map((item) => item.name)
                    return names.includes(name) ? `${name} (${names.filter((item) => item.includes(name)).length})` : name
                  }

                  const newTask: ExtraTask = {
                    name: getUniqueName('Ny insats'),
                    frequencies: [],
                    selections: [],
                    double: false,
                    shared: false,
                    calcAs: 'extraTask',
                    description: '',
                    standardTimes: {},
                    selectionDescriptions: {},
                    hasFrequency: true,
                  }
                  const newTasks = [...municipalityData.input.extraTasks, newTask]
                  dispatch(setMunicipality({ municipalityData: { ...municipalityData, input: { ...municipalityData.input, extraTasks: newTasks } } }))
                }}
              >
                Lägg till ny insats
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  )
}

export default EditableOther
