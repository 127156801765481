import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, List, Popover, Space } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getmunicipality, setMunicipality } from '../../../store/municipalitySlice'
import { MunicipalityData } from '../types/municipalityData'

const EditableTimepoints: React.FC = () => {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const data = useSelector(getmunicipality)
  let editableData = JSON.parse(JSON.stringify(data)) as MunicipalityData

  const [closingTimeout, setClosingTimeout] = useState<NodeJS.Timeout | undefined>(undefined)

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const addItem = () => {
    editableData.input.timepoints.push(inputValue)
    dispatch(setMunicipality({ municipalityData: editableData }))
    setInputValue('')
  }

  const deleteItem = (timepoint: string) => {
    editableData.input.timepoints = editableData.input.timepoints.filter((item) => item !== timepoint)
    dispatch(setMunicipality({ municipalityData: editableData }))
  }

  const onBlur = () => {
    setClosingTimeout(setTimeout(() => setPopoverOpen(false), 200))
  }

  const onFocus = () => {
    clearTimeout(closingTimeout)
  }

  const popoverContent = (
    <div tabIndex={1} onFocus={onFocus}>
      <List
        size="small"
        bordered
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space style={{ padding: '0 8px 4px' }}>
              <Input placeholder="Skapa ny tidpunkt" onChange={onNameChange} value={inputValue} />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem} onPointerDown={(e) => e.preventDefault()}>
                Add item
              </Button>
            </Space>
          </div>
        }
        dataSource={editableData.input.timepoints}
        renderItem={(item) => (
          <List.Item
            key={item}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>{item}</div>
            <Button onClick={() => deleteItem(item)} danger size="small" onPointerDown={(e) => e.preventDefault()}>
              Ta bort
            </Button>
          </List.Item>
        )}
      />
    </div>
  )

  return (
    <div tabIndex={1} onBlur={onBlur}>
      <Popover title="Ändra tidpunkter" content={popoverContent} open={popoverOpen}>
        <Button onClick={() => setPopoverOpen(!popoverOpen)}>Ändra tidpunkter</Button>
      </Popover>
    </div>
  )
}

export default EditableTimepoints
