import { MunicipalityData } from '../types/municipalityData'

const getFrequencyValueOther = (taskName: string, frequency: number | undefined, level: undefined | string, municipalityData: MunicipalityData) => {
  const correspondingTask = municipalityData.input.extraTasks.find((task) => task.name === taskName)

  // if there are no frequencies for this task, we assume that the level has a normalized standardTime for the month
  if (correspondingTask?.frequencies?.length === 0) {
    return level ? 1 : 0
  }

  // Backend expects frequency in "times per month" so we need to convert from "times per week"
  const weeksPerMonth = municipalityData.input.calculationOutputSettings.weeksPerMonth ?? 4

  return frequency ? frequency * weeksPerMonth : 0
}

function jsonFormatter(data: Record<string, any>, weeksOff: number, municipalityData: MunicipalityData) {
  const result = {
    weeksOff,
    shared: (data.shared as boolean) ?? false,
    timepointTasks: {} as Record<
      string,
      Record<
        string,
        {
          frequency: number
          selection: string
          double: boolean
        }
      >
    >,
    extraTasks: {} as Record<
      string,
      {
        frequency: number
        selection: string
        double: boolean
      }
    >,
    // Currently unused
    checkboxes: {},
    // Not necessary to send to backend but we want to save it in the database for loading
    weeksPerMonth: municipalityData.input.calculationOutputSettings.weeksPerMonth,
  }

  for (const keys of Object.keys(data)) {
    switch (keys) {
      case 'save':
      case 'shared':
      case 'checkboxes':
      case 'WeeksOff':
        break

      case 'other': {
        const task = data[keys]
        for (const item in task) {
          if (!task[item].Nivå) {
            continue
          }
          result.extraTasks[item] = {
            frequency: getFrequencyValueOther(item, task[item].Frekvens, task[item].Nivå, municipalityData),
            selection: task[item].Nivå ?? '-',
            double: task[item].Dubbelbemanning ?? false,
          }
        }
        break
      }

      // All other keys are timepointTasks
      default: {
        const task = data[keys]
        result.timepointTasks[keys] = {}
        for (const item in task.timepoints) {
          if (!task.timepoints[item].Nivå) {
            continue
          }

          result.timepointTasks[keys][item] = {
            frequency: task.timepoints[item].Besök ? task.timepoints[item].Besök : 0,
            selection: task.timepoints[item].Nivå ?? '-',
            double: task.timepoints[item].Dubbelbemanning,
          }
        }
      }
    }
  }
  return result
}

export default jsonFormatter
