import React from 'react'

// Antd
import { Button, Checkbox, Form, Input, Popconfirm, Typography } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { getmunicipality, setMunicipality } from '../../store/municipalitySlice'
import EditableFrequencies from './components/editableFrequencies'
import EditableLevel from './components/editableLevel'
import { Frequency, MunicipalityData, TimepointTask } from './types/municipalityData'

type Props = {
  timepoints: CheckboxValueType[]
}

const EditTimepointsTasks: React.FC<Props> = ({ timepoints }) => {
  const municipalityData = useSelector(getmunicipality) as MunicipalityData

  const data = useSelector(getmunicipality)
  const editableData = JSON.parse(JSON.stringify(data)) as MunicipalityData
  const dispatch = useDispatch()

  const updateTask = (task: TimepointTask) => {
    const index = editableData.input.timepointTasks.findIndex((item) => item.name === task.name)
    if (index === -1) return
    editableData.input.timepointTasks[index] = task
    dispatch(setMunicipality({ municipalityData: editableData }))
  }

  const updateName = (event: React.ChangeEvent<HTMLInputElement>, task: TimepointTask) => {
    const index = editableData.input.timepointTasks.findIndex((item) => item.name === task.name)
    if (index === -1) return
    editableData.input.timepointTasks[index].name = event.target.value
    dispatch(setMunicipality({ municipalityData: editableData }))
  }

  const updateFrequencies = (frequencies: Frequency[], task: TimepointTask) => {
    updateTask({ ...task, frequencies })
  }

  const updateDouble = (event: CheckboxChangeEvent, task: TimepointTask) => {
    updateTask({ ...task, double: event.target.checked })
  }

  const updateShared = (event: CheckboxChangeEvent, task: TimepointTask) => {
    updateTask({ ...task, shared: event.target.checked })
  }

  return (
    <>
      {timepoints.map((item, index) => (
        <>
          <Form.List name={[item.toString(), 'timepoints']} key={index}>
            {() => (
              <>
                <Typography.Title
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '40px',
                    marginBottom: '40px',
                  }}
                  level={3}
                >
                  {item}
                </Typography.Title>
                {municipalityData.input.timepointTasks.map((task, index) => (
                  <>
                    <div className="decision-wrapper-edit" key={index}>
                      <div>
                        <Form.Item>
                          <Input onChange={(e) => updateName(e, task)} defaultValue={task.name}></Input>
                        </Form.Item>
                      </div>
                      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                        <div style={{ marginLeft: '8px' }}>
                          {task.frequencies && (
                            <Form.Item name={[task.name, 'Frekvens']}>
                              <EditableFrequencies frequencies={task.frequencies} updateFrequencies={(frequencies) => updateFrequencies(frequencies, task)} name={task.name} />
                            </Form.Item>
                          )}
                        </div>
                      </Form.Item>

                      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                        <Form.Item name={[task.name, 'Nivå']}>
                          <EditableLevel name={task.name} selections={task.selections} currentTask={task} updateTask={updateTask} />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item name={[task.name, 'Dubbelbemanning']}>
                        <Checkbox checked={task.double} onChange={(e) => updateDouble(e, task)}>
                          Kan dubbelbemannas
                        </Checkbox>
                      </Form.Item>

                      <Form.Item name={[task.name, 'Kan delas']}>
                        <Checkbox checked={task.shared} onChange={(e) => updateShared(e, task)}>
                          Kan delas
                        </Checkbox>
                      </Form.Item>
                      <Popconfirm
                        title={`Är du säker på att du vill ta bort insatsen '${task.name}'?`}
                        okText="Bekräfta"
                        cancelText="Avbryt"
                        onConfirm={() => {
                          const newTasks = municipalityData.input.timepointTasks.filter((item) => item.name !== task.name)
                          dispatch(setMunicipality({ municipalityData: { ...municipalityData, input: { ...municipalityData.input, timepointTasks: newTasks } } }))
                        }}
                      >
                        <Button type="primary" danger>
                          Ta bort
                        </Button>
                      </Popconfirm>
                    </div>
                  </>
                ))}
                <div className="flex items-center justify-center">
                  <Button
                    type="primary"
                    onClick={() => {
                      const getUniqueName = (name: string) => {
                        const names = municipalityData.input.timepointTasks.map((item) => item.name)
                        return names.includes(name) ? `${name} (${names.filter((item) => item.includes(name)).length})` : name
                      }
                      const newTask: TimepointTask = {
                        name: getUniqueName('Ny insats'),
                        frequencies: [],
                        selections: [],
                        double: false,
                        shared: false,
                        description: '',
                        standardTimes: {},
                        selectionDescriptions: {},
                        hasFrequency: true,
                      }
                      const newTasks = [...municipalityData.input.timepointTasks, newTask]
                      dispatch(setMunicipality({ municipalityData: { ...municipalityData, input: { ...municipalityData.input, timepointTasks: newTasks } } }))
                    }}
                  >
                    Lägg till ny insats
                  </Button>
                </div>
              </>
            )}
          </Form.List>
        </>
      ))}
    </>
  )
}

export default EditTimepointsTasks
