import React from 'react'

// Antd
import { InfoCircleOutlined } from '@ant-design/icons'
import { Checkbox, Divider, Form, Popover, Select, Typography } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useSelector } from 'react-redux'
import { getmunicipality } from '../../store/municipalitySlice'
import { MunicipalityData, TimepointTask } from './types/municipalityData'
import { JSONLoadError } from './utils/jsonLoader'
const { Option } = Select
const { Text } = Typography

type Props = {
  timepoints: CheckboxValueType[]
  loadingErrors?: JSONLoadError[]
}

const TimepointsTasks: React.FC<Props> = ({ timepoints, loadingErrors }) => {
  const municipalityData = useSelector(getmunicipality) as MunicipalityData

  const selectionsDescription = (item: TimepointTask) => {
    return Object.entries(item.selectionDescriptions).map(([level, desc]) => (
      <span key={level}>
        <Text strong>{level}: </Text>
        {desc}
        <br />
      </span>
    ))
  }

  return (
    <>
      {timepoints.map((item, index) => (
        <Form.List name={[item.toString(), 'timepoints']} key={index}>
          {() => (
            <>
              <Typography.Title
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '40px',
                  marginBottom: '40px',
                }}
                level={3}
              >
                {item}
              </Typography.Title>
              <Divider style={{ width: '100%', marginBlock: 12 }} />
              {municipalityData?.input?.timepointTasks.map((item, index) => (
                <>
                  <div className="decision-wrapper" key={index}>
                    <div>
                      <Typography.Text>{item.name}</Typography.Text>
                    </div>
                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                      {() => (
                        <div style={{ width: '55%' }}>
                          <Form.Item name={[item.name, 'Besök']} noStyle>
                            <Select
                              bordered={false}
                              placeholder="Besök per vecka"
                              allowClear
                              style={{
                                border:
                                  loadingErrors && loadingErrors.findIndex((e) => e.taskName === item.name && e.taskType === 'timepoint' && e.errorType === 'frequency') !== -1 ? '1px solid red' : '',
                              }}
                            >
                              {item.frequencies?.map((item) => (
                                <Option key={item.name} value={item.timesPerWeek}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      )}
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}>
                      {() => (
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          <Form.Item name={[item.name, 'Nivå']} noStyle>
                            <Select
                              bordered={false}
                              placeholder="Välj nivå"
                              allowClear
                              style={{
                                border:
                                  loadingErrors && loadingErrors.findIndex((e) => e.taskName === item.name && e.taskType === 'timepoint' && e.errorType === 'selection') !== -1 ? '1px solid red' : '',
                              }}
                            >
                              {item.selections.map((item) => (
                                <Option value={item} key={item}>
                                  {item}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          {municipalityData.input.formSettings.showDescription && (
                            <Popover overlayClassName="decision-popover" title={item.description} content={selectionsDescription(item)} className="ml-1">
                              <InfoCircleOutlined />
                            </Popover>
                          )}
                        </div>
                      )}
                    </Form.Item>
                    <div>
                      {item.double && (
                        <Form.Item name={[item.name, 'Dubbelbemanning']} valuePropName="checked" noStyle>
                          <Checkbox>Dubbelbemanning</Checkbox>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <Divider style={{ width: '100%', marginBlock: 12 }} />
                </>
              ))}
            </>
          )}
        </Form.List>
      ))}
    </>
  )
}

export default TimepointsTasks
