import { AxiosInstance } from 'axios'
import { MunicipalityData } from '../features/beslutsstod/types/municipalityData'
import { useMutation, useQuery } from '@tanstack/react-query'
import { apiClient } from './useApi'
interface Data {
  totalExDouble: number
  hoursPerWeekPerTimepoint: Record<string, unknown>
  hoursPerWeekTimepointTasks: number
  hoursPerMonthTimepointTasks: number
  hoursPerDayTimepointTasks: number
  hoursPerMonthExtraTasks: number
  hoursPerMonthTimePointsTasksDouble: number
  totalHoursPerMonth: number
  [key: string]: number | Record<string, unknown>
}

export interface Evaluation {
  id: number
  input_state: string
  created: string
  process_id: string
  draft: boolean
  note: string | null
}

export const calculateBeslutsstod = async (api: AxiosInstance, result: any) => {
  const calculation = await api.post(
    `v1/beslutsstod/${localStorage.getItem('tenant_id')}/calculate`,
    { result },
    {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    }
  )
  return calculation.data as Data
}

export const saveBeslutsstod = async (api: AxiosInstance, result: any, pid: string, pnr: string, draft: boolean, note: string) => {
  const calculation = await api.post(
    `v1/beslutsstod/${localStorage.getItem('tenant_id')}/save/person`,
    { result, pid, pnr, draft, note: note ?? undefined },
    {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    }
  )
  return calculation.data
}

export const editBeslutsstod = async (api: AxiosInstance, config: any, description: string) => {
  const calculation = await api.post(
    `v1/beslutsstod/${localStorage.getItem('tenant_id')}/new`,
    { config, description },
    {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      },
    }
  )
  return calculation.data
}

export interface Description {
  created: string
  description: string
}

interface MunicipalityDataResponse {
  config: MunicipalityData
  descriptions: Description[]
}

export const getMunicipalityData = async (api: AxiosInstance) => {
  const res = await api.get<MunicipalityDataResponse>(`v1/beslutsstod/${localStorage.getItem('tenant_id')}/municipality`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return res.data
}

export const archiveBeslutsstod = async (api: AxiosInstance, id: number) => {
  const calculation = await api.get(`v1/beslutsstod/${localStorage.getItem('tenant_id')}/archive`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      id: id,
    },
  })
  return calculation.data
}

export const loadBeslutsstodById = async (api: AxiosInstance, id: string) => {
  const beslutsstod = await api.get<Evaluation>(`v1/beslutsstod/${localStorage.getItem('tenant_id')}/${id}`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return beslutsstod.data
}

export const loadPersonsBeslutsstod = async (api: AxiosInstance, pid: string, prn: string) => {
  const person = await api.get<Evaluation[]>(`v1/beslutsstod/${localStorage.getItem('tenant_id')}/load/person`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
      ssn: prn,
      pid: pid,
    },
  })
  return person.data
}

export const loadDescriptions = async (api: AxiosInstance) => {
  const person = await api.get(`v1/beslutsstod/${localStorage.getItem('tenant_id')}/descriptions`, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('hydra_access_token'),
    },
  })
  return person.data
}

export const useDownloadPdf = () =>
  useMutation(['beslutsstodPdf'], async (data: any) => await apiClient.downloadPdf(localStorage.getItem('tenant_id') ?? '', { result: data }, { responseType: 'arraybuffer' }).then((res) => res.data))
